var getApiUrl = process.env.VUE_APP_API_URL;
var cdnUrl = process.env.VUE_APP_CDN_URL;
var goLLZ = process.env.VUE_APP_GO_LLZ;
var avaterPreview = process.env.VUE_APP_AVATAR_PREVIEW; //头像预览
var fileServer = process.env.VUE_APP_FILE_SERVER; //文件中心 上传头像用
var shareUrl = process.env.VUE_APP_SHARE_URL; //h5
var goJMZB = process.env.VUE_APP_GO_JMZB;
var upJmdyh = process.env.VUE_APP_UP_JMDYH; // 订阅号后台跳转域名
var Jmdyh = process.env.VUE_APP_JMDYH; // 订阅号跳转域名
var fmUrl = process.env.VUE_APP_FMURL;
var cdnPic = process.env.VUE_APP_CDN_URL_PIC ;
var fmCookieName = process.env.VUE_APP_FMTOOKEN;
export default {
  getApiUrl,
  cdnUrl,
  cdnPic,
  goLLZ,
  fmCookieName,
  avaterPreview,
  upJmdyh,
  goJMZB,
  Jmdyh,
  shareUrl,
  fmUrl,
  fileServer,
};
