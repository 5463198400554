<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {},
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      const bodyElement = document.body;
      bodyElement.classList.remove(oldVal);
      bodyElement.classList.add(newVal);
      bodyElement.setAttribute("data-theme", newVal);
    },
  },
  mounted() {
    const bodyElement = document.body;
    bodyElement.setAttribute("data-theme", this.currentTheme);
    bodyElement.classList.add("body-theme");
    if (this.currentTheme == "light") {
      bodyElement.classList.add("light");
      bodyElement.classList.remove("dark");
    } else {
      bodyElement.classList.remove("light");
      bodyElement.classList.add("dark");
    }

    //修改fmPath在这将store中路由名称替换成需要修改的路由
    //  if (this.$store.state.fmPath == "fmp") {
    this.$store.commit("setAttr", {
      name: "fmPath",
      val: "drive",
    });
    // }
  },
  methods: {
    checkDarkMode() {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
      } else {
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  @include useTheme {
    // background: getVar("bg-color");
    color: getVar("text-color2-2c2c2c");
  }
  .app-container {
    @include useTheme {
      background: getVar("bg-color");
    }
  }
}
</style>
