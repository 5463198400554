<template>
  <div class="app-container">
    <div
      class="app-box"
      @mousemove="selectItems"
      @mouseup.prevent="endSelection"
    >
      <NavTop></NavTop>
      <div class="app-bottom" id="app-view">
        <NavLeft></NavLeft>
        <div class="app-bottom-right" id="app-bottom-right">
          <router-view></router-view>
        </div>
      </div>
      <UploadDownloadBox
        ref="UploadDownloadBox"
        v-show="$store.state.showUpDownBox > 0"
      />
      <div
        class="move-check-file-box"
        v-show="$store.state.showFlag == 2"
        :style="{
          left: moveBox.left + 'px',
          top: moveBox.top + 'px',
        }"
      >
        <el-badge :value="$store.state.check_str.count" class="item">
          <img :src="$store.state.check_str.icon" />
        </el-badge>
        <span class="name"> {{ $store.state.check_str.name }}</span>
        <span class="desc">等文件</span>
      </div>
    </div>
  </div>
</template>

<script>
import NavLeft from "@/components/NavLeft";
import NavTop from "@/components/NavTop";
import UploadDownloadBox from "@/components/UploadDownloadBox";

export default {
  components: {
    NavLeft,
    NavTop,
    UploadDownloadBox,
  },
  data() {
    return {
      textarea2: "",
      moveBox: {
        left: 0,
        top: 0,
      },
      checkStr: "hhhhh",
      showFlag: 2,
    };
  },
  created() {
    window.addEventListener("mouseup", this.endSelection);
  },
  mounted() {
    let appRightWidth =
      document.getElementById("app-bottom-right")?.clientWidth;
    this.$store.commit("setAttr", {
      name: "screenWidth",
      val: document.body.clientWidth,
    });
    this.$store.commit("setAttr", {
      name: "appRightWidth",
      val: appRightWidth,
    });
    window.onresize = () => {
      return (() => {
        this.$store.commit("setAttr", {
          name: "screenWidth",
          val: document.body.clientWidth,
        });
        this.$store.commit("setAttr", {
          name: "appRightWidth",
          val: document.getElementById("app-bottom-right")?.clientWidth,
        });
      })();
    };
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.endSelection);
  },
  methods: {
    selectItems(event, index) {
      const currentX = event.clientX;
      const currentY = event.clientY;
      this.moveBox = {
        left: currentX,
        top: currentY,
      };
    },
    endSelection(event) {
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
      this.$store.commit("endSelectFunVuex");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  width: 100%;

  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .app-box {
    width: 100%;
    height: 100vh;
    min-width: 980px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .app-bottom {
    width: 100%;
    position: relative;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 56px);
    display: flex;

    .app-bottom-right {
      width: 100%;
      height: calc(100vh - 56px);
      overflow: hidden;
    }
  }
  .move-check-file-box {
    position: absolute;
    z-index: 1000;
    pointer-events: none; /* 防止选择框影响鼠标事件 */
    width: 328px;
    height: 52px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    align-items: center;
    @include useTheme {
      border: getVar("border-color-e5e5e5");
      background: getVar("text-color3-ffffff");
    }
    .item {
      width: 28px;
      height: 28px;
      margin: 0 12px 0 16px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .name {
      width: 198px;
      height: 22px;
      line-height: 22px;
      @include multiline(1);
      @include useTheme {
        color: getVar("text-color2-2c2c2c");
      }
    }
    .desc {
      line-height: 22px;
      margin-right: 16px;
      margin-left: auto;
      @include useTheme {
        color: getVar("text-color1-bdbdbe");
      }
    }
  }
}
</style>
