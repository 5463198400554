<template>
  <div class="text-center img-div">
    <div
      :class="isSigned ? null : 'no-sign'"
      class="img-div relative inline-block"
    >
      <img :src="signData.logo" width="50" alt="" />
    </div>
    <div style="margin-top: 13px">
      <div v-if="!isSigned" class="sign-button" @click="handleSign">
        <div class="sign-button-text">去完成超级签到</div>
      </div>
      <div v-else class="sign-to-app">已完成超级签到</div>
    </div>
    <div class="sign-progress">
      <div class="sign-tip">
        {{ signData.finish_all_sign_get_text
        }}<span class="sign-tip-light">{{ signData.super_award_text }}</span>
      </div>
      <div class="sign-checks flex-between">
        <div
          v-for="item in signProgress"
          :key="item.key"
          class="a-sign-check img-div"
        >
          <div class="sign-check-icon">
            <img
              :src="signStatus[item.key] != '0' ? checked : uncheck"
              alt=""
            />
            <div class="sign-line"></div>
          </div>
          <div class="sign-check-label">{{ item.label }}</div>
        </div>
        <div class="a-sign-check img-div">
          <div class="img-div">
            <img :src="signGift" width="36" alt="" />
          </div>
          <div class="sign-check-label">
            <div class="sign-check-button">
              <img
                :src="
                  $store.state.theme == 'light'
                    ? $utils.getPng('sign-super-text')
                    : $utils.getPng('sign-super-text_dark')
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { welfareText, userSignProgress } from "@/utils/apiList/welfare";
export default {
  name: "webSign",
  data() {
    return {
      isSigned: "",
      welfaredata: {},
      signData: {
        logo: this.$utils.getPng("sign-logo"),
        point: 5,
        finish_all_sign_get_text: "完成多端打卡签到可获得",
        super_award_text: "「超级签到奖励」",
      },
      signProgress: [
        { label: "移动端", key: "is_APP_sign" },
        { label: "网页端", key: "is_WEB_sign" },
        { label: "桌面端", key: "is_Client_sign" },
      ],
      signStatus: {
        is_APP_sign: 0,
        is_Client_sign: 0,
        is_Super_sign: 0,
        is_WEB_sign: 0,
      },
      checked: this.$utils.getPng("qiandao_fu2"),
      uncheck: this.$utils.getPng("qiandao_fu"),
      signGift: this.$utils.getPng("jiangli_fu"),
    };
  },
  mounted() {
    this.signInRewardBar();
    this.welfareText();
  },
  methods: {
    handleSign() {
      this.$emit("onSign");
    },
    async welfareText() {
      try {
        let res = await welfareText();
        if (res.status == "1") {
          this.signData = {
            ...res.data,
            logo: this.$utils.getPng("sign-logo"),
          };
        }
      } catch (e) {}
    },
    // 签到进度
    async signInRewardBar() {
      try {
        let res = await userSignProgress({ edition: 1 });
        if (res.status == "1") {
          this.isSigned = res.data.is_Super_sign != 0;
          this.signStatus = res.data;
        }
      } catch (e) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.img-div {
  font-size: 0;
  line-height: 0;
}
.sign-button {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 16px;
  padding: 3px;
  background-color: rgba(255, 145, 60, 0.3);
  border-radius: 22px;
  cursor: pointer;
}
.sign-button-text {
  padding: 6px 20px;
  background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
  border-radius: 22px;
}
.sign-tip {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  @include useTheme {
    color: getVar("text-color1-8a8b92");
  }
  .sign-tip-light {
    @include useTheme {
      color: getVar("text-color2-2c2c2c");
    }
  }
}
.sign-progress {
  width: 478px;
  margin-top: 12px;
  border-radius: 16px;
  padding: 16px 20px 36px;
  box-shadow: 0px 7px 21px 1px rgba(198, 198, 198, 0.1);
  @include useTheme {
    background-color: getVar("text-color2-ffffff");
  }
  @media screen and (max-width: 1440px) {
    width: 340px;
  }
}
.flex-between {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-checks {
  position: relative;
  margin-top: 14px;
  padding: 0 5px;
}
.a-sign-check + .a-sign-check {
  margin-left: 51px;
}
.a-sign-check {
  position: relative;
  padding: 0 5px;
}
.sign-check-icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
  }
}
.sign-line {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 4px;
  @include useTheme {
    background-color: getVar("text-color1-fdf2e3");
  }
}
.sign-check-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;
  white-space: nowrap;
}
.sign-check-button {
  width: 74px;
  height: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.no-sign {
  img {
    opacity: 0.4;
  }
}
.sign-to-app {
  padding: 9px 0;
  font-size: 12px;
  font-weight: 500;
  @include useTheme {
    color: getVar("text-color2-2c2c2c");
  }
  line-height: 16px;
}
.sign-point {
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateY(-50%);
  font-size: 28px;
  font-weight: 500;
  color: #ff7538;
  line-height: 28px;
  display: flex;
}
</style>
