<template>
    <button class="fm-button" :class="[`fm-button-${type}`,`fm-button-${size}`,{
             'is-plain':plain,'is-round':round,'is-disabled':disabled,'is-dashed':dashed,
             'is-text':text,'is-icon':icon!=='','is-circle':circle,'icon-btn':!$slots.default
           }]"  @click="handleClick">
           <svg-btn v-if="icon" :icon-class="`${icon}`" />
           <!-- 如果没传入文本插槽，则不显示span内容 -->
            <span v-if="$slots.default"><slot></slot></span>
    </button>
</template>

<script>

export default {
    name: 'FmButton',
     // 此时对props进行校验，值接收string类型的type值
    props: {
        type:{
            type: String,
            // 设置默认值：如果不传值，那么使用default
            default: 'primary'
        },
        plain: {
            type: Boolean,
            default: false
        },
        round:{
            type: Boolean,
            default: false
        },
        size:{
            type:String,
            default:'default'
        },
        disabled:{
            type: Boolean,
            default: false
        },
        dashed:{
            type: Boolean,
            default: false
        },
        text:{
            type: Boolean,
            default: false
        },
        icon:{
            type:String,
            default: ''
        },
        circle:{
            type:Boolean,
            default: false
        }
    },
    methods:{
        handleClick (e) {
            this.$emit('click', e)
        }
    }
}
</script>   
   
<style lang="scss">
    @import "./index.scss";
</style>