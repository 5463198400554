<template>
  <el-dialog
    width="828px"
    :append-to-body="true"
    :close-on-click-modal="false"
    top="0vh"
    custom-class="payBox"
    :before-close="handleClosePayDia"
    :visible.sync="isShow"
  >
    <div
      class="payContent"
      :style="
        $store.state.theme == 'light'
          ? 'backgroundImage:url(' + $utils.getPng('web4/payNewBg') + ')'
          : 'backgroundImage:url(' + $utils.getPng('web4/payNewBg_dark') + ')'
      "
    >
      <div class="d-header">
        <div class="paytit">
          {{ paytit == "连续包月" ? "月度会员" : paytit }}
          <fm-button
            @click="handleClosePayDia"
            type="info"
            size="mini"
            text
            icon="icon-model-close"
          ></fm-button>
        </div>
      </div>
      <div class="content">
        <div class="oneTop">
          <div
            class="one"
            v-for="(item, index) in memberInfo[memberAct]"
            :key="index"
          >
            <svg-btn v-if="index == 2" class="imgIntr" icon-class="vip_33" />
            <img
              v-if="index != 2"
              class="imgIntr"
              :src="$utils.getPng(`web4/vip_${index + 1}`)"
              alt=""
            />
            <div class="name">{{ item }}</div>
          </div>
        </div>
        <div class="payRight">
          <div class="one">
            <div class="payName">支付方式</div>
            <div class="left" style="margin-top: 4px">
              <div
                class="tabs"
                :class="{
                  curTabs: payModeId == item.pid,
                  curTabs2: payModeId == item.pid && item.pid == 'wxpay',
                }"
                v-for="(item, index) in payData"
                :key="index"
                @click="setTab(item)"
              >
                <img :src="item.icon" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="one">
            <div class="payName">扫码支付</div>
            <div class="payAll">
              <div class="topBox">
                <div class="codebox" v-loading="ewmloading">
                  <img
                    class="ewm"
                    :src="$utils.getPng('ewm')"
                    alt=""
                    v-if="isadmin != ''"
                  />
                  <div id="qrcode2"></div>
                  <div
                    class="mb1"
                    :style="
                      $store.state.theme == 'light'
                        ? 'background: rgba(0, 0, 0, 0.8);'
                        : 'background: rgba(0, 0, 0, 0.9);'
                    "
                    v-if="!isChecked"
                  >
                    请勾选协议后<br />再支付
                  </div>
                  <div
                    class="mb1"
                    style="padding:20px"
                    :style="
                      $store.state.theme == 'light'
                        ? 'background: rgba(0, 0, 0, 0.8);'
                        : 'background: rgba(0, 0, 0, 0.9);'
                    "
                    v-if="isadmin != ''"
                  >
                    {{ isadmin }}
                  </div>
                  <div
                    class="mb1 mb2 pointer"
                    :style="
                      $store.state.theme == 'light'
                        ? 'background: rgba(0, 0, 0, 0.8);'
                        : 'background: rgba(0, 0, 0, 0.9);'
                    "
                    v-if="order_status == 2 && isChecked"
                    @click="refresh"
                  >
                    <img class="imgdb" :src="$utils.getPng('iconsx')" alt="" />
                    请点击刷新
                  </div>
                </div>
                <div class="VipC_price-time price-content">
                  <div class="countDown">
                    <span class="name">特惠倒计时</span>

                    <span class="timea">
                      <span class="time">{{ time.hours }}</span>
                    </span>

                    <span class="sign">:</span>
                    <span class="timea">
                      <span class="time">{{ time.min }}</span>
                    </span>
                    <span class="sign">:</span>
                    <span class="timea">
                      <span class="time">{{ time.second }}</span>
                    </span>
                  </div>
                  <div class="price">
                    <span class="lable">实付:</span>
                    <span class="unit">¥</span>
                    <span class="num">
                      {{
                        !isUseCoupon
                          ? showBankList.real_price
                          : getPrice(showBankList.real_price)
                      }}</span
                    >
                    <div class="couponPrice price-btn" v-if="isUseCoupon">
                      已享受{{ parseInt(couponDiscount * 100) / 10 }}折优惠
                    </div>
                    <!-- 月度会员单月不展示已优惠多少 -->
                    <div v-else>
                      <div
                        v-if="showBankList.pay_id != '1037'"
                        class="couponPrice price-btn"
                      >
                        已优惠<span>{{
                          !isUseCoupon
                            ? parseInt(showBankList.web_original_price) -
                              parseInt(showBankList.real_price)
                            : getPrice(showBankList.real_price)
                        }}</span
                        >元！
                      </div>
                    </div>
                  </div>
                  <div v-if="showBankList.pay_id == '1038'">
                    <div class="discount">
                      次月续费{{ showBankList.sub_price }}元
                    </div>
                  </div>
                  <div class="agreement">
                    <div class="btn-svg">
                      <svg-btn
                        @click="checkArchives"
                        :icon-class="
                          isChecked
                            ? 'icon-file-check'
                            : 'icon-file-uncheck-' + $store.state.theme
                        "
                      >
                      </svg-btn>
                    </div>
                    <span style="width: 219px"
                      >阅读并同意<a
                        :href="`${$glb.fmUrl}/askdetail/89`"
                        target="_blank"
                        >《飞猫盘会员服务协议》</a
                      >
                      <font v-if="payMeth === 'cycle'">以及</font
                      ><a
                        v-if="payModeId === 'cycle'"
                        class="sf3"
                        :href="`${$glb.fmUrl}/askdetail/95`"
                        target="_blank"
                        >《飞猫盘自动续费服务协议》</a
                      >
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="payModeId === 'alipay' || payModeId === 'huabei'"
                class="formPay"
                @click="formPay"
              >
                手机不在身边？点此支付宝网页支付
              </div>
              <div
                class="tocoupon"
                v-if="payModeId === 'alipay' || payModeId === 'wxpay'"
              >
                <span class="coupon" @click="useyh()">使用代金券或优惠码</span>
                <!-- <img class="next" :src="$utils.getPng('web4/icon_ne')" alt="" /> -->
                <svg-btn
                  style="width: 12px; height: 12px"
                  icon-class="icon_nextyh"
                />
              </div>
              <div class="swiper">
                <div class="slide1"></div>
                <div class="slide2"></div>
                <SuperSlide
                  ref="SuperSlide"
                  :payDialog="isShow"
                  :superSlideData="superSlideData"
                ></SuperSlide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import SuperSlide from "@/components/Member/SuperSlide.vue";
import qrcode from "qrcode";
import { generatePaySignAPI, getOrderStateAPI } from "@/utils/apiList/vip.js";
export default {
  props: {
    isShow: Boolean,
    payData: {
      type: Array,
      default: [],
    },
    showBankInfo: {
      type: Array,
      default: [],
    },
    allMember: {
      type: Array,
      default: [],
    },
    superSlideData: {
      type: Array,
      default: [],
    },
  },
  watch: {
    isShow: function (newVal) {
      if (newVal) {
        this.showBankList = this.showBankInfo;
      } else {
      }
    },
  },

  components: {
    SuperSlide,
  },
  data() {
    return {
      memberInfo: [
        [
          "高达300次/天下载额度",
          "极致加速",
          "会员专属去广告体验",
          "6项在线云功能",
        ],
        [
          "高达100次/天下载额度",
          "极致加速",
          "会员专属去广告体验",
          "6项在线云功能",
        ],
        [
          "高达30次/天下载额度",
          "极致加速",
          "会员专属去广告体验",
          "6项在线云功能",
        ],
        [
          "高达15次/天下载额度",
          "会员加速",
          "会员专属去广告体验",
          "6项在线云功能",
        ],
      ],
      showBankList: [],
      memberAct: 0,
      isChecked: true,
      commentData2: [],
      time: {
        hours: "24",
        min: "00",
        second: "00",
      },

      paytit: "不限时长会员",
      isadmin: "",
      isUseCoupon: false,
      payModeId: "alipay",
      ewmloading: false,
      orderInfo: {},
      couponDiscount: 1,
      payTimer: null,
      order_status: 0,
      couponWY: "",
      coupndataLast: {},
      currentRequestId: 0,
    };
  },
  mounted() {
    this.startTime(); // 24小时倒计时
    // setTimeout(() => {
    //   this.$emit("openSuccess");
    // }, 5000);
  },
  methods: {
    getPrice(price) {
      return (price * this.couponDiscount).toFixed(2);
    },
    //勾选协议
    checkArchives() {
      this.isChecked = !this.isChecked;
      if (this.isout && this.isChecked) {
        this.refresh();
      }
    },

    setTab: _.debounce(
      async function (item) {
        //点击正在定位的卡片返回 不生成订单 但是过期还是生成新订单
        if (this.payModeId == item.pid) {
          if (this.order_status == 2) {
            this.refresh();
          }
          return;
        }
        this.payModeId = item.pid;
        //点击的是最后的卡片 月度会员或者连续包月
        if (
          this.showBankList.pay_id == "1038" ||
          this.showBankList.pay_id == "1037"
        ) {
          if (item.pid == "cycle") {
            this.showBankList = this.allMember.find((item1) => {
              return item1.pay_id == "1038";
            }); //当支付的方式选中的是连续包月 showBankList 是1038
          } else {
            this.showBankList = this.allMember.find((item1) => {
              return item1.pay_id == "1037";
            }); //当支付的方式选中的是不是连续包月 showBankList 是1037
          }
        }
        if (item.pid == "cycle" || item.pid == "huabei") {
          //花呗和连续包月不可使用优惠券
          this.coupndataLast = {};
          this.$emit("clearCoupon");
        }
        this.$parent.showBankInfo = this.showBankList; //同步到父组件选中的信息
        document.getElementById("qrcode2").innerHTML = "";
        await this.clearQRCode();
        this.newPay(
          this.showBankList.pay_id,
          this.payModeId,
          this.coupndataLast
        );
      },
      150,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    startTime() {
      setInterval(() => {
        this.time = this.countDown();
      }, 1000);
    },
    //  获取当前时间的24小时倒计时
    countDown() {
      let now = new Date(); // 获取当前时间
      let hour = now.getHours(); // 时
      let min = now.getMinutes(); // 分
      let sec = now.getSeconds(); // 秒
      let result = ""; // 返回结果
      let h = 24 - hour; // 一天中剩下的时间(小时)
      if (min > 0 || sec > 0) {
        h -= 1;
      }
      let m = 60 - min; // 一天中剩下的时间(分钟)
      if (sec > 0) {
        m -= 1;
      }
      if (m == 60) {
        m = 0;
      }
      let s = 60 - sec; // 一天中剩下的时间(秒)
      if (s == 60) {
        s = 0;
      }

      h = h.toString();
      m = m.toString();
      s = s.toString();
      if (h.length == 1) {
        // 补0
        h = "0" + h;
      }
      if (m.length == 1) {
        // 补0
        m = "0" + m;
      }
      if (s.length == 1)
        // 补0
        s = "0" + s;
      result = { hours: h, min: m, second: s };
      return result;
    },
    handleClosePayDia() {
      clearInterval(this.payTimer);
      this.$emit("handleClosePayDia");
    },
    formPay() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      this.newPay(
        this.showBankList.pay_id,
        this.payModeId,
        this.coupndataLast,
        "wap"
      );
    },
    useyh() {
      this.$parent.showDiscountCode = true;
    },
    refresh() {
      this.order_status = 0;
      this.newPay(this.showBankList.pay_id, this.payModeId);
    },
    newPay(pay_id, pay_type, coupndata = {}, scene = "") {
      this.$parent.animation = "";
      if (coupndata.discount) {
        this.isUseCoupon = true;
        this.couponDiscount = coupndata.discount;
      } else {
        this.isUseCoupon = false;
        this.couponDiscount = 1;
      }
      this.coupndataLast = coupndata; //将本次带的优惠码存储在coupndataLast,切换套餐时需要保留优惠只有在切换套餐至连续包月和花呗时清空coupndataLast

      const requestId = Date.now();
      this.currentRequestId = requestId; //接口返回时间不一致差别较大 获取当前请求时间 一致时再生成二维码（防止月度会员微信支付接口返回需要594ms 连续包月300ms 快速切换时 切换到连续包月后 但是微信支付接口反应慢会覆盖连续包月的二维码）

      if (scene) {
        generatePaySignAPI({
          pay_id: pay_id,
          pay_type: pay_type,
          coupon: coupndata.coupon ? coupndata.coupon : "",
          scene,
        })
          .then((res) => {
            if (res.status == 1) {
              const newWindow = window.open("", "_blank");
              newWindow.document.write(res.data.shtml);
            } else if (res.status == 50013) {
              this.$toast(res.msg,2)
            }
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      this.ewmloading = true;
      generatePaySignAPI({
        pay_id: pay_id,
        pay_type: pay_type,
        coupon: coupndata.coupon ? coupndata.coupon : "",
      })
        .then((res) => {
          if (res.status == 1) {
            this.orderInfo = res.data;
            if (this.currentRequestId == requestId) {
              this.createCode(this.orderInfo);
            }
          } else if (res.status == 50013) {
            this.isadmin = res.msg;
            this.ewmloading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async clearQRCode() {
      return new Promise((resolve) => {
        document.getElementById("qrcode2").innerHTML = "";
        document.getElementById("qrcode2").textContent = "";
        resolve();
      });
    },
    //生成支付二维码
    async createCode(code) {
      // 1.创建二维码对象。qrcode(二维码类型（1~40，输入 0 以自动检测）,容错级别（L、M、Q、H）)

      await this.clearQRCode(); // Asynchronous clear
      // createImgTag(cellSize, margin, alt); cellSize 像素宽度,margin补白像素宽度
      this.$nextTick(() => {
        var qr = qrcode(0, "L");
        // 2.添加二维码信息。
        qr.addData(code.qrcode);
        // 3.生成二维码对象（并不显示）。
        qr.make();
        document.getElementById("qrcode2").innerHTML = qr.createImgTag(4, 0);
        this.ewmloading = false;
        clearInterval(this.payTimer);
        this.order_status = 0;
        this.payTimer = setInterval(() => {
          this.checkOrder(code);
        }, 4000);
      });
    },

    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
    checkOrder: _.debounce(
      function (code) {
        //除了 wxpay 全部是 alipay
        let order = {
          order_id: code.out_trade_no,
          pay_type: code.pay_type,
          pay_id: this.showBankList.pay_id,
        };
        getOrderStateAPI(order)
          .then((res) => {
            this.order_status = res.status;
            if (res.status == 2) {
              clearInterval(this.payTimer);
            }
            if (res.status == 1) {
              this.$emit("openSuccess", res.data.pay_toast);
              clearInterval(this.payTimer);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
