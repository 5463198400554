function numToString(number){
  let char = "";
  let array = [];
  // Switch ASCII
  let numToStringAction = function(nnum) {
      let num = nnum - 1;
      let a = parseInt(num / 26);
      let b = num % 26;
      array.push(b);
      if (a > 0) {
          numToStringAction(a);
      }
  }
  numToStringAction(number);
  array = array.reverse();
  // Return excel letter: such => C / AA / BBA
  for (let i = 0; i < array.length; i++) {
      char += String.fromCharCode(64 + parseInt(array[i] + 1));
  }
  return char;
}

function empty() {}

function copyText(item) {
if (!item) {
  return;
}
// 创建输入框元素
let oInput = document.createElement("textarea");
// 将想要复制的值
oInput.value = item;
// 页面底部追加输入框
document.body.appendChild(oInput);
// 选中输入框
oInput.select();
// 执行浏览器复制命令
document.execCommand("Copy");
// 复制后移除输入框
oInput.remove();
}

// function renderImage(ctx, medias, sheet, offset) {
//   if (sheet && sheet._media.length) {
//     sheet._media.forEach(function(media) {
//       var imageId = media.imageId, range = media.range, type = media.type;
//       if (type === "image") {
//         var position = calcPosition(sheet, range, offset);
//         drawImage(ctx, imageId, medias[imageId], position);
//       }
//     });
//   }
// }

export default class ExcelCanvas {
constructor(sheets = [], id, otherData) {
  this.parentId = id || 'excelCanvasBox'
  this.gridColWidth = []
  this.gridRowHeight = []
  this.styles = []
  this.domId = 'myExcelCanvas'
  this.parentDom = null
  this.canvasGridDom = null
  this.colors = {
    bg: 'rgb(242, 244, 247)',
    line: '#adadad',
    text: '#2c2c2c'
  }
  this.canvas = null
  this.ctx = null
  this.config = {
    min_row: 50,
    min_col: 30,
    row: 50,
    col: 30,
    headerHeight: 24,
    leftWidth: 32,
    colWidth: 80,
    rowHeight: 30,
    fontSize: 14,
    headerSize: 14
  }
  this.allColWidth = this.config.col * this.config.colWidth
  this.allRowHeight = this.config.row * this.config.rowHeight
  this.config.font = this.config.fontSize + 'px "Microsoft YaHei", sans-serif'
  this.isInitResize = false
  this.isInitKeyDown = false
  this.scroll = {
    top: 0,
    left: 0,
    leftDom: null,
    topDom: null,
    yb: 1,
    xb: 1,
    leftBox: null,
    sheets: null
  }
  this.resizeControl = {
    row: false,
    col: false,
    isDown: false,
    _start_x: 0,
    _start_y: 0,
    xi: 0,
    yi: 0
  }
  this.grid = {
    offsetTop: 84,
    _left: 0,
    _top: 0,
    y_start: 0,
    y_end: 0,
    x_start: 0,
    x_end: 0,
    header: null,
    side: null,
    content: null,
    visibleLabel: null,
    visibleVal: null,
    bgCharbar: null
  }
  this.active = {
    show: false,
    hDom: null,
    lDom: null,
    row_start: 0,
    row_end: 0,
    col_start: 0,
    col_end: 0,
    activeCell: null,
    activeCellTag: null,
    activeCell_row: 0,
    activeCell_col: 0,
    headerDown: false,
    sideDown: false,
    contentDown: false,
    clickX: 0,
    clickY: 0
  }
  this.DPR = 1
  this.canvasDPR = {
    oldWidth: 0,
    oldHeight: 0
  }
  this.touch = {
    start: {
      x: 0,
      y: 0
    }
  }
  this.imageMap = {}
  this.timer = null
  this.initOptions(sheets, otherData)
  this.initCanvas()
}

initOptions(sheets = [], otherData = {}) {
  this.sheets = sheets
  this.otherData = otherData
  if (this.sheets.length > 0) {
    this.selectSheet(0)
  }
}

selectSheet(i) {
  this.act_sheet = i
  this.data = this.sheets[i].data || []
  this.merges = this.sheets[i].merges || {}
  this.styles = this.sheets[i].styles || []
  this.gridRowHeight = []
  this.gridColWidth = []
  let _min_row = this.data.length
  let _min_col = 0
  this.data.forEach(v => {
    if (v) {
      _min_col = Math.max(_min_col, v.length)
    }
  })

  this.config.min_row = _min_row
  this.config.min_col = _min_col

  this.mergeMap = {}
  this.mergesStart = {}
  for (let imageMapKey in this.imageMap) {
    this.imageMap[imageMapKey].image = null;
  }
  this.imageMap = {}

  this.getMergeMap()

  this.grid._top = 0
  this.grid._left = 0
  this.scroll.left = 0
  this.scroll.top = 0

  this.active.activeCell_row = 0
  this.active.activeCell_col = 0
  this.renderSheetsMenu()
}

setDataMap() {
  if (this.config.row !== this.gridRowHeight.length) {
    let sheet_row_height = this.sheets[this.act_sheet].gridRowHeight
    let _gridRowHeight = []
    let count = 0
    for (let i = 0; i <= this.config.row; i++) {
      let __rh = sheet_row_height[i] || 0
      let __h = Math.max(__rh, this.config.rowHeight)
      _gridRowHeight.push({
        h: __h,
        t: count
      })
      count += __h
    }
    this.gridRowHeight = _gridRowHeight
  }
  if (this.config.col !== this.gridColWidth.length) {
    let sheet_col_width = this.sheets[this.act_sheet].gridColWidth
    let count = 0
    let _gridColWidth = []

    for (let i = 0; i <= this.config.col; i++) {
      let __cw = sheet_col_width[i] || 0
      let __w = Math.max(__cw, this.config.colWidth)
      _gridColWidth.push({
        w: __w,
        l: count
      })
      count += __w
    }
    this.gridColWidth = _gridColWidth
  }
  this.setColWidth()
  this.setRowHeight()
}
setColWidth(params) {
  if (params) {
    this.gridColWidth[params.col].w = params.width
    this.allColWidth += params.added
    for (let i = params.col + 1; i < this.gridColWidth.length; i++) {
      this.gridColWidth[i].l += params.added
    }
  } else {
    let _all_width = 0
    for (let i = 0; i < this.config.col; i++) {
      _all_width += this.gridColWidth[i].w
    }
    this.allColWidth = _all_width
  }
  this.scroll.xb = this.scroll.leftDom.parentElement.clientWidth / this.allColWidth
}
setRowHeight(params) {
  if (params) {
    this.gridRowHeight[params.row].h = params.height
    this.allRowHeight += params.added
    for (let i = params.row + 1; i < this.gridRowHeight.length; i++) {
      this.gridRowHeight[i].t += params.added
    }
  } else {
    let _all_height = 0
    for (let i = 0; i < this.config.row; i++) {
      _all_height += this.gridRowHeight[i].h
    }
    this.allRowHeight = _all_height
  }
  this.scroll.yb = this.scroll.topDom.parentElement.clientHeight / this.allRowHeight
}

initCanvas() {
  this.initDomBox()
  this.canvas = document.getElementById(this.domId)
  if (!this.canvas) return;
  this.ctx = this.canvas.getContext('2d')

  this.startDraw()
  this.renderSheetsMenu()
  this.initResize()
  this.initKeyDown()
}

initDomBox() {
  this.parentDom = document.getElementById(this.parentId)
  if (!this.parentDom) return;
  this.parentDom.innerHTML = `<div class="excel-canvas-box">
          <div class="excel-col-val-show">
              <div class="excel-col-val-show-content">
                  <div class="excel-col-val-show-input-box">
                      <input id="visibleLabel" class="excel-col-val-show-input-box-label" type="text" readonly />
                  </div>
                  <div class="excel-col-val-show-input-box2">
                      <input id="visibleVal" class="excel-col-val-show-input-box-value" type="text" readonly />
                  </div>
              </div>
          </div>

          <div id="canvas-grid" class="excel-canvas-grid">
              <div id="bg-charbar" class="bg-charbar" style="width: ${this.config.leftWidth}px;height: ${this.config.headerHeight}px;background-color: ${this.colors.bg};"></div>
              <div id="canvas-grid-header" class="excel-canvas-grid-header" style="height: ${this.config.headerHeight + 1}px;left: ${this.config.leftWidth}px;">
                  <div id="h-active" class="header-active">
                      <div class="header-active-line"></div>
                  </div>
              </div>
              <div id="canvas-grid-left" class="excel-canvas-grid-left" style="width: ${this.config.leftWidth + 1}px;top: ${this.config.headerHeight}px;">
                  <div id="l-active" class="left-active" style="">
                      <div class="left-active-line"></div>
                  </div>
              </div>
              <div id="canvas-grid-content" class="excel-canvas-grid-content" style="left: ${this.config.leftWidth}px;top: ${this.config.headerHeight}px;">
                  <div id="active-cell" class="active-cell"></div>
                  <div id="active-cell-tag-box" class="active-cell-box"></div>
              </div>
              <div id="canvasDiv" style="flex-grow: 1">
                  <canvas id="${this.domId}"></canvas>
              </div>
          </div>

          <div id="scroll-div-y" class="excel-canvas-scroll-y" style="padding-top: ${this.config.headerHeight}px;">
              <div id="scroll-control-y-box" class="excel-canvas-scroll-y-div">
                  <div id="scroll-y-control" class="excel-canvas-scroll-y-control"></div>
              </div>
          </div>
          <div id="scroll-div-x" class="excel-canvas-scroll-x">
              <div id="scroll-control-x-box" class="scroll-control-x-box" style="left: ${this.config.leftWidth}px;">
                  <div id="sheets-box" class="sheets-div"></div>
                  <div class="excel-canvas-scroll-x-div">
                      <div id="scroll-x-control" class="excel-canvas-scroll-x-control"></div>
                      <div class="sheet-divider"></div>
                  </div>
              </div>
          </div>
      </div>`
  this.canvasGridDom = document.getElementById('canvas-grid')
  this.scroll.leftDom = document.getElementById('scroll-x-control')
  this.scroll.topDom = document.getElementById('scroll-y-control')
  this.scroll.leftBox = document.getElementById('scroll-control-x-box')
  this.scroll.sheets = document.getElementById('sheets-box')

  this.grid.header = document.getElementById('canvas-grid-header')
  this.grid.side = document.getElementById('canvas-grid-left')
  this.grid.content = document.getElementById('canvas-grid-content')
  this.grid.visibleLabel = document.getElementById('visibleLabel')
  this.grid.visibleVal = document.getElementById('visibleVal')
  this.grid.bgCharbar = document.getElementById('bg-charbar')

  this.active.hDom = document.getElementById('h-active')
  this.active.lDom = document.getElementById('l-active')
  this.active.activeCell = document.getElementById('active-cell')
  this.active.activeCellTag = document.getElementById('active-cell-tag-box')

  this.canvasGridDom.addEventListener('mousemove', this.gridMouseHover, false)
  this.canvasGridDom.addEventListener('mousedown', this.gridMouseDown, false)
  this.canvasGridDom.addEventListener('mouseup', this.gridMouseUp, false)

  this.canvasGridDom.addEventListener('touchmove', this.gridTouchMove, false)
  this.canvasGridDom.addEventListener('touchstart', this.gridTouchStart, false)
  this.canvasGridDom.addEventListener('touchend', this.gridTouchEnd, false)

  this.canvasGridDom.oncontextmenu = function () {
    return false;
  }

  this.scroll.leftDom.onmousedown = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.button !== 0) {
      return
    }
    let _start_x = e.clientX
    document.onmousemove = (e2) => {
      e2.preventDefault()
      let _move_x = e2.clientX - _start_x
      _start_x = e2.clientX
      this.scroll.left += _move_x

      this.validateX()

      this.drawTable()
      this.setActive()
    }

    document.onmouseup = () => {
      document.onmousemove = null
      document.onmouseup = null
    }
  }

  this.scroll.leftDom.ontouchstart = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    this.touch.start.x = touche.clientX
  }
  this.scroll.leftDom.ontouchmove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    let _move_x = touche.clientX - this.touch.start.x
    this.touch.start.x = touche.clientX
    if (Math.abs(_move_x) > 1.2) {
      this.scroll.left += _move_x
      this.validateX()

      this.drawTable()
      this.setActive()
    }
  }

  this.scroll.sheets.onclick = (e) => {
    if (e.target.classList.contains('a-sheet')) {
      let _index = e.target.getAttribute('data-index')
      _index = Number(_index)
      if (_index >= 0 && this.act_sheet !== _index) {
        this.selectSheet(_index)

        this.startDraw()
        this.setActive()
      }
    }
  }

  this.scroll.topDom.ontouchstart = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    this.touch.start.y = touche.clientY
  }
  this.scroll.topDom.ontouchmove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    let _move_y = touche.clientY - this.touch.start.y
    this.touch.start.y = touche.clientY
    if (Math.abs(_move_y) > 1.2) {
      this.scroll.top += _move_y
      this.validateY()

      this.drawTable()
      this.setActive()
    }
  }

  this.scroll.topDom.onmousedown = (e) => {
    if (e.button !== 0) {
      return
    }
    let _start_y = e.clientY
    document.onmousemove = (e2) => {
      e2.preventDefault()

      let _move_y = e2.clientY - _start_y
      _start_y = e2.clientY
      this.scroll.top += _move_y

      this.validateY()

      this.drawTable()
      this.setActive()
    }

    document.onmouseup = () => {
      document.onmousemove = null
      document.onmouseup = null
    }
  }

  if (document.addEventListener) {
    document.addEventListener('DOMMouseScroll', this.scrollFunc,false)
  }
  window.addEventListener('mousewheel', this.scrollFunc)

  this.getGridTop()
}

getGridTop() {
  let ot = this.canvasGridDom.offsetTop
  let viewBody = document.getElementById('preview-center')
  if (viewBody) {
    ot += viewBody.offsetTop
  }
  this.grid.offsetTop = ot
}
// getZ(num){
//   var rounded;
//   rounded = (0.5 + num) | 0;
//   // A double bitwise not.
//   rounded = ~~ (0.5 + num);
//   // Finally, a left bitwise shift.
//   rounded = (0.5 + num) << 0;
//
//   return rounded;
// }
getRowCol(ox, oy, type) {
  let result = {
    row: null,
    col: null
  }

  let _cha = type === 'resize' ? 2 : 0
  let _y_top = this.grid._top + oy
  for (let i = 0; i < this.gridRowHeight.length; i++) {
    _y_top -= this.gridRowHeight[i].h
    if (_y_top <= _cha) {
      result.row = i
      break
    }
  }

  if (!result.row && result.row !== 0) {
    result.row = this.gridRowHeight.length - 1
  }

  let _x_top = this.grid._left + ox
  for (let i = 0; i < this.gridColWidth.length; i++) {
    _x_top -= this.gridColWidth[i].w
    if (_x_top <= _cha) {
      result.col = i
      break
    }
  }

  if (!result.col && result.col !== 0) {
    result.col = this.gridColWidth.length - 1
  }

  return result
}

  gridTouchMove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    let _move_x = this.touch.start.x - touche.clientX
    this.touch.start.x = touche.clientX
    let _move_y = this.touch.start.y - touche.clientY
    this.touch.start.y = touche.clientY

    let drawFlag = false
    if (Math.abs(_move_x) > 1.2) {
      drawFlag = true
      this.scroll.left += _move_x
    }
    if (Math.abs(_move_y) > 1.2) {
      drawFlag = true
      this.scroll.top += _move_y
    }

    if (drawFlag) {
      this.validateX()
      this.validateY()

      this.drawTable()
      this.setActive()
    }
  }
  gridTouchStart = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let touche = e.targetTouches[0]
    touche.button = 0
    this.touch.start.x = touche.clientX
    this.touch.start.y = touche.clientY
    this.gridMouseDown(touche)
  }
  gridTouchEnd = () => {
    this.gridMouseUp()
  }

gridMouseHover = (e) => {
  e.preventDefault()
  let ox = e.clientX
  let oy = e.clientY - this.grid.offsetTop

  if (oy <= this.config.headerHeight && ox <= this.config.leftWidth) {
    return
  }

  let _ox = this.grid._left + ox - this.config.leftWidth
  let x_bool = false
  for (let i = this.grid.x_start; i < this.grid.x_end; i++) {
    if (this.gridColWidth[i].l === 0) {
      continue
    }
    if (_ox <= this.gridColWidth[i].l + 2 && _ox >= this.gridColWidth[i].l - 2) {
      x_bool = true
      break
    }
  }
  if (x_bool && oy <= this.config.headerHeight) {
    this.grid.header.className = 'excel-canvas-grid-header cursor-e-resize'
    this.resizeControl.row = true
  } else {
    this.grid.header.className = 'excel-canvas-grid-header'
    if (!this.resizeControl.isDown) {
      this.resizeControl.row = false
    }
  }

  let _oy = oy + this.grid._top - this.config.headerHeight
  let y_bool = false
  for (let i = this.grid.y_start; i < this.grid.y_end; i++) {
    if (this.gridRowHeight[i].t === 0) {
      continue
    }
    if (_oy <= this.gridRowHeight[i].t + 2 && _oy >= this.gridRowHeight[i].t - 2) {
      y_bool = true
      break
    }
  }

  if (y_bool && ox <= this.config.leftWidth) {
    this.grid.side.className = 'excel-canvas-grid-left cursor-n-resize'
    this.resizeControl.col = true
  } else {
    this.grid.side.className = 'excel-canvas-grid-left'
    if (!this.resizeControl.isDown) {
      this.resizeControl.col = false
    }
  }

  if (this.resizeControl.isDown) {
    if (this.resizeControl.row) {
      let editDiv = document.getElementById('grid-edit-e-resize')
      if (editDiv) {
        let _move_x = ox - this.resizeControl._start_x
        this.resizeControl._start_x = ox

        let _width = parseFloat(editDiv.style.width) + _move_x
        if (_width < 5 || _width > 1000) {
          return;
        }
        editDiv.style.width = _width + 'px'
      }
    } else if (this.resizeControl.col) {
      let editDiv = document.getElementById('grid-edit-n-resize')
      if (editDiv) {
        let _move_y = oy - this.resizeControl._start_y
        this.resizeControl._start_y = oy

        let _height = parseFloat(editDiv.style.height) + _move_y
        if (_height < this.config.rowHeight || _height > 600 + this.config.rowHeight) {
          return;
        }
        editDiv.style.height = _height + 'px'
      }
    }
  } else {
    let _res = this.getRowCol(ox - this.config.leftWidth, oy - this.config.headerHeight)
    if (this.active.contentDown) {
      this.active.row_end = _res.row
      this.active.col_end = _res.col
      this.setActive()

      if (this.timer) {
        if (Math.abs(this.active.clickX - e.clientX) > 1 || Math.abs(this.active.clickY - e.clientY) > 1) {
          clearTimeout(this.timer)
          this.timer = null
        }
      }
    } else if (this.active.headerDown) {
      this.active.col_end = _res.col
      this.setActive()
    } else if (this.active.sideDown) {
      this.active.row_end = _res.row
      this.setActive()
    }
    // else {
    //   if (!this.active.headerDown && !this.active.sideDown && !this.active.contentDown) {
    //     let _style = this.getCellStyle(_res)
    //     if (_style.link) {
    //       this.canvasGridDom.style.cursor = 'pointer'
    //     } else {
    //       this.canvasGridDom.style.cursor = 'default'
    //     }
    //   }
    // }
  }
}

gridMouseDown = (e) => {
  if (e.button !== 0) {
    return
  }
  let _cx = e.clientX
  let _cy = e.clientY - this.grid.offsetTop
  let _ox = _cx - this.config.leftWidth
  let _oy = _cy - this.config.headerHeight
  if (this.resizeControl.row) {
    this.resizeControl._start_x = _cx

    let editDiv = document.createElement('div')
    editDiv.id = 'grid-edit-e-resize'
    editDiv.className = 'grid-edit-e-resize'
    let _res = this.getRowCol(_ox, _oy, 'resize')
    this.resizeControl.xi = _res.col
    editDiv.style.left = this.gridColWidth[_res.col].l - this.grid._left + 'px'
    editDiv.style.width = this.gridColWidth[_res.col].w + 'px'
    this.grid.content.appendChild(editDiv)
    this.resizeControl.isDown = true
  } else if (this.resizeControl.col) {
    this.resizeControl._start_y = _cy

    let editDiv = document.createElement('div')
    editDiv.id = 'grid-edit-n-resize'
    editDiv.className = 'grid-edit-n-resize'
    let _res = this.getRowCol(_ox, _oy, 'resize')
    this.resizeControl.yi = _res.row
    editDiv.style.top = this.gridRowHeight[_res.row].t - this.grid._top + 'px'
    editDiv.style.height = this.gridRowHeight[_res.row].h + 'px'
    this.grid.content.appendChild(editDiv)
    this.resizeControl.isDown = true
  } else {
    let _res = this.getRowCol(_ox, _oy)
    if (_cx > this.config.leftWidth && _cy < this.config.headerHeight) {
      // header
      this.active.headerDown = true
      this.active.col_start = _res.col
      this.active.col_end = _res.col
      this.active.row_start = 0
      this.active.row_end = this.config.row
    } else if (_cx <= this.config.leftWidth && _cy >= this.config.headerHeight) {
      // side
      this.active.sideDown = true
      this.active.col_start = 0
      this.active.col_end = this.config.col
      this.active.row_start = _res.row
      this.active.row_end = _res.row
    } else if (_cx > this.config.leftWidth && _cy > this.config.headerHeight) {
      // content
      this.active.contentDown = true
      this.active.col_start = _res.col
      this.active.col_end = _res.col
      this.active.row_start = _res.row
      this.active.row_end = _res.row
      // let _style = this.getCellStyle(_res)
      // if (_style.link) {
      //   if (!this.timer) {
      //     this.active.clickX = e.clientX
      //     this.active.clickY = e.clientY
      //     this.timer = setTimeout(() => {
      //       window.open(_style.link)
      //       this.timer = null
      //       this.active.contentDown = false
      //     }, 500)
      //   }
      // }
    } else if (_cx < this.config.leftWidth && _cy < this.config.headerHeight) {
      // select all
      this.active.col_start = 0
      this.active.col_end = this.config.col
      this.active.row_start = 0
      this.active.row_end = this.config.row
    }

    this.active.activeCell_row = this.active.row_start
    this.active.activeCell_col = this.active.col_start

    this.active.show = true
    this.setActive()
  }
}

getCellStyle(_res) {
  let _prop = _res.row + ':' + _res.col
  let _mer_end = this.mergeMap[_prop]
  if (_mer_end) {
    let mer_start = this.merges[_mer_end]
    _res.row = mer_start.r
    _res.col = mer_start.c
  }
  let _styles = this.sheets[this.act_sheet].styles || []
  let _style_arr = _styles[_res.row] || []
  return _style_arr[_res.col] || {}
}

gridMouseUp = () => {
  this.active.contentDown = false
  this.active.sideDown = false
  this.active.headerDown = false
  if (this.resizeControl.isDown && (this.resizeControl.row || this.resizeControl.col)) {
    let _id = this.resizeControl.row ? 'grid-edit-e-resize' : this.resizeControl.col ? 'grid-edit-n-resize' : ''
    let editDiv = document.getElementById(_id)
    if (editDiv) {
      let editWidth = Math.ceil(parseFloat(editDiv.style.width))
      let editHeight = Math.ceil(parseFloat(editDiv.style.height))

      if (this.resizeControl.row) {
        let added = editWidth - this.gridColWidth[this.resizeControl.xi].w
        this.setColWidth({
          col: this.resizeControl.xi,
          width: editWidth,
          added
        })
      } else if (this.resizeControl.col) {
        let added = editHeight - this.gridRowHeight[this.resizeControl.yi].h
        this.setRowHeight({
          row: this.resizeControl.yi,
          height: editHeight,
          added
        })
      }
      editDiv.parentElement.removeChild(editDiv)

      this.drawTable()
      this.setActive()
    }
  }

  this.resizeControl.isDown = false
  this.resizeControl.col = false
  this.resizeControl.row = false
}

setActive() {
  if (!this.active.show) {
    return
  }

  let _row_start = this.active.activeCell_row
  let _col_start = this.active.activeCell_col

  let merge_end = this.mergeMap[_row_start + ':' + _col_start]
  if (merge_end) {
    let merge_start = this.merges[merge_end]
    _row_start = merge_start.r
    _col_start = merge_start.c
  }

  this.grid.visibleLabel.value = '' + numToString(_col_start + 1) + (_row_start + 1)
  let _r_v = this.data[_row_start] || []
  this.grid.visibleVal.value = '' + (_r_v[_col_start] || '')

  this.active.hDom.style.display = 'block'
  this.active.hDom.style.height = this.config.headerHeight + 'px'

  this.active.lDom.style.width = this.config.leftWidth + 'px'
  this.active.lDom.style.display = 'block'

  this.active.activeCell.style.display = 'block'
  this.active.activeCellTag.style.display = 'block'

  this.activeMove()
}

activeMove() {
  let _row_start = this.active.row_start
  let _row_end = this.active.row_end
  let _col_start = this.active.col_start
  let _col_end = this.active.col_end

  let s_height = this.gridRowHeight[_row_start].h + 3
  let s_width = this.gridColWidth[_col_start].w + 3

  let f_start = _row_start
  let f_end = _row_end
  let flag_l = 0
  let flag_t = 0
  if (_row_start > _row_end) {
    flag_t++
    f_start = _row_end
    f_end = _row_start
  }

  let f_start_2 = _col_start
  let f_end_2 = _col_end
  if (_col_start > _col_end) {
    flag_l++
    f_start_2 = _col_end
    f_end_2 = _col_start
  }

  let y_length = f_end - f_start + 1
  let x_length = f_end_2 - f_start_2 + 1

  let col_start = f_start_2
  let col_end = f_end_2
  let row_start = f_start
  let row_end = f_end
  for (let j = 0; j < y_length; j++) {
    for (let i = 0; i < x_length; i++) {
      let ___r = f_start + j
      let ___c = f_start_2 + i
      let e_prop = ___r + ':' + ___c
      let mergeEnd = this.mergeMap[e_prop]
      if (mergeEnd) {
        let mergeStart = this.merges[mergeEnd]
        col_start = Math.min(mergeStart.c, col_start)
        row_start = Math.min(mergeStart.r, row_start)
        row_end = Math.max(mergeStart.er, row_end)
        col_end = Math.max(mergeStart.ec, col_end)

        if (___r === this.active.row_start && ___c === this.active.col_start) {
          let __w = 0
          let __h = 0

          let _y_for = mergeStart.er - mergeStart.r + 1
          let _x_for = mergeStart.ec - mergeStart.c + 1

          for (let k = 0; k < _x_for; k++) {
            __w += this.gridColWidth[mergeStart.c + k].w
          }
          for (let k = 0; k < _y_for; k++) {
            __h += this.gridRowHeight[mergeStart.r + k].h
          }

          s_height = __h + 3
          s_width = __w + 3
        }
      }
    }
  }

  let border_left = 0
  let border_top = 0
  let border_right = 0
  let border_bottom = 0

  let _top = this.gridRowHeight[row_start].t
  let _height = 0

  f_start = this.active.activeCell_row
  f_end = f_start
  f_start_2 = this.active.activeCell_col
  f_end_2 = f_start_2

  let merge_end = this.mergeMap[f_start + ':' + f_start_2]
  if (merge_end) {
    let merge_start = this.merges[merge_end]
    if (merge_start) {
      f_start = merge_start.r
      f_end = merge_start.er
      f_start_2 = merge_start.c
      f_end_2 = merge_start.ec
    }
  }

  for (let i = row_start; i <= row_end; i++) {
    _height += this.gridRowHeight[i].h

    if (i < f_start) {
      border_top += this.gridRowHeight[i].h
    } else if (i > f_end) {
      border_bottom += this.gridRowHeight[i].h
    }
  }

  let _left = this.gridColWidth[col_start].l
  let _width = 0
  for (let i = col_start; i <= col_end; i++) {
    _width += this.gridColWidth[i].w

    if (i < f_start_2) {
      border_left += this.gridColWidth[i].w
    } else if (i > f_end_2) {
      border_right += this.gridColWidth[i].w
    }
  }

  this.active.hDom.style.left = _left - this.grid._left + 'px'
  this.active.hDom.style.width = _width + 1 + 'px'

  this.active.lDom.style.top = _top - this.grid._top + 'px'
  this.active.lDom.style.height = _height + 'px'

  this.active.activeCell.style.left = _left - this.grid._left + 3 + 'px'
  this.active.activeCell.style.top = _top - this.grid._top + 3 + 'px'
  this.active.activeCell.style.width = _width - 5 + 'px'
  this.active.activeCell.style.height = _height - 6 + 'px'

  this.active.activeCellTag.style.left = _left - this.grid._left - 3 + _width + 'px'
  this.active.activeCellTag.style.top = _top - this.grid._top - 3 + _height + 'px'

  if (border_top > 0) border_top -= 3
  if (border_right > 0) border_right -= 3
  if (border_bottom > 0) border_bottom -= 3
  if (border_left > 0) border_left -= 3
  this.active.activeCell.style.borderWidth = `${border_top}px ${border_right}px ${border_bottom}px ${border_left}px`
}

scrollFunc = (event) => {
  let _e = event || window.event
  let _count = 100
  if (_e.wheelDelta > 0 || _e.detail < 0) {   //滚轮向上滑动
    if (_e.shiftKey) {
      this.scroll.left -= _count * this.scroll.xb
    } else {
      this.scroll.top -= _count * this.scroll.yb
    }
  } else {   //滚轮向下滑动
    if (_e.shiftKey) {
      this.scroll.left += _count * this.scroll.xb
    } else {
      this.scroll.top += _count * this.scroll.yb
    }
  }

  this.validateY()
  this.validateX()

  this.drawTable()
  this.setActive()
}

validateX() {
  if (this.scroll.left < 0) {
    this.scroll.left = 0
  } else if (this.scroll.left + parseFloat(this.scroll.leftDom.style.width) > this.scroll.leftDom.parentElement.clientWidth) {
    this.scroll.left = this.scroll.leftDom.parentElement.clientWidth - parseFloat(this.scroll.leftDom.style.width)
  }
}
validateY() {
  if (this.scroll.top < 0) {
    this.scroll.top = 0
  } else if (this.scroll.top + parseFloat(this.scroll.topDom.style.height) > this.scroll.topDom.parentElement.clientHeight) {
    this.scroll.top = this.scroll.topDom.parentElement.clientHeight - parseFloat(this.scroll.topDom.style.height)
  }
}

startDraw() {
  if (this.parentDom) {
    this.canvas.width = this.parentDom.clientWidth
    this.canvas.height = this.parentDom.clientHeight
  }

  this.setConfig()
  this.drawTable()
}

setConfig() {
  this.DPR = window.devicePixelRatio || 1;
  let _dpr = this.DPR
  if (_dpr < 1) {
    _dpr = 2 - _dpr
  }
  this.canvasDPR.oldWidth = this.canvas.width
  this.canvasDPR.oldHeight = this.canvas.height
  this.canvas.width = this.canvasDPR.oldWidth * _dpr
  this.canvas.height = this.canvasDPR.oldHeight * _dpr
  this.canvas.style.width = this.canvasDPR.oldWidth + "px"
  this.canvas.style.height = this.canvasDPR.oldHeight + "px"

  this.ctx.scale(_dpr, _dpr)
  let _row = Math.ceil(this.canvas.height / this.config.rowHeight)
  let _col = Math.ceil(this.canvas.width / this.config.colWidth)

  this.config.row = Math.max(this.config.row, _row) + 1
  this.config.col = Math.max(this.config.col, _col) + 1

  if (this.config.row < this.config.min_row) {
    this.config.row = this.config.min_row + Math.floor(this.config.row / 2)
  }
  if (this.config.col < this.config.min_col) {
    this.config.col = this.config.min_col + Math.floor(this.config.col / 2)
  }

  this.setDataMap()
}

drawTable() {
  this.ctx.clearRect(0, 0, this.config.leftWidth + this.allColWidth, this.config.headerHeight + this.allRowHeight)

  this.ctx.textBaseline = 'bottom'
  this.drawRect({
    x: 0,
    y: 0,
    width: this.canvas.width,
    height: this.canvas.height,
    isFill: true,
    bgColor: '#ffffff'
  })

  this.drawContent()

  this.ctx.fillStyle = this.colors.bg
  this.drawRect({
    x: 0,
    y: 0,
    width: this.canvas.width,
    height: this.config.headerHeight,
    isFill: true
  })
  this.drawRect({
    x: 0,
    y: 0,
    width: this.config.leftWidth,
    height: this.canvas.height,
    isFill: true
  })
  this.ctx.save()
  this.ctx.fillStyle = this.colors.text
  this.ctx.font = this.config.headerSize + 'px "Microsoft YaHei", sans-serif'
  this.ctx.textAlign = 'center'
  this.ctx.translate(0.5, 0.5)
  this.drawLeft()
  this.drawHeader()
  this.ctx.restore()
}

drawRect(_params) {
  let { x, y, width, height, isClear, isFill, bgColor } = _params
  if (isClear) { //为true表示绘制清除画布的矩形区域,那么传入的isFill, bgColor值可以为任意值
    this.ctx.clearRect(x, y, width, height);
  } else { //false
    if (bgColor) {
      this.ctx.fillStyle = bgColor;
    }
    if (isFill) { //为true，则绘制填充矩形
      this.ctx.fillRect(x, y, width, height);
    } else { //为false,则绘制边框矩形
      this.ctx.strokeRect(x, y, width, height);
    }
  }
}
getStartEnd() {
  let result = {
    y_start: 0,
    y_end: 0,
    x_start: 0,
    x_end: 0
  }
  let _y_top = this.grid._top
  for (let i = 0; i < this.gridRowHeight.length; i++) {
    _y_top -= this.gridRowHeight[i].h
    if (_y_top <= 0) {
      result.y_start = i
      break
    }
  }

  _y_top = Math.abs(_y_top)
  for (let i = result.y_start + 1; i < this.gridRowHeight.length; i++) {
    _y_top += this.gridRowHeight[i].h
    if (_y_top >= this.canvasGridDom.clientHeight - this.config.headerHeight) {
      result.y_end = i + 1
      break
    }
  }

  if (result.y_end === 0) {
    result.y_end = this.config.row
  } else if (result.y_end > this.config.row) {
    result.y_end = this.config.row
  }

  let _x_left = this.grid._left
  for (let i = 0; i < this.gridColWidth.length; i++) {
    _x_left -= this.gridColWidth[i].w
    if (_x_left <= 0) {
      result.x_start = i
      break
    }
  }

  _x_left = Math.abs(_x_left)
  for (let i = result.x_start + 1; i < this.gridColWidth.length; i++) {
    _x_left += this.gridColWidth[i].w
    if (_x_left >= this.canvasGridDom.clientWidth - this.config.leftWidth) {
      result.x_end = i + 1
      break
    }
  }

  if (result.x_end === 0) {
    result.x_end = this.config.col
  } else if (result.x_end > this.config.col) {
    result.x_end = this.config.col
  }

  return result
}
drawHeader() {
  for (let i = this.grid.x_start; i < this.grid.x_end; i++) {
    let _x = this.config.leftWidth + this.gridColWidth[i].l + this.gridColWidth[i].w / 2 - this.grid._left
    let _y = this.config.headerHeight / 2 + this.config.fontSize / 2 + 2
    this.ctx.fillText(numToString(i + 1), _x, _y);

    let line_x = this.config.leftWidth + this.gridColWidth[i].l - this.grid._left
    let gnt = this.ctx.createLinearGradient(line_x, 0, line_x, this.config.headerHeight)
    gnt.addColorStop(0, '#ffffff')
    gnt.addColorStop(1, this.colors.line)
    this.drawLine({
      startX: line_x,
      startY: 0,
      endX: line_x,
      endY: this.config.headerHeight,
      bgColor: gnt
    })
  }

  this.drawLine({
    startX: this.config.leftWidth,
    startY: this.config.headerHeight,
    endX: this.config.leftWidth,
    endY: this.canvas.height
  })
}

setScrollPos() {
  let cWidth = this.scroll.leftDom.parentElement.clientWidth
  let cHeight = this.scroll.topDom.parentElement.clientHeight

  let _canvas_height = this.canvasGridDom.clientHeight - this.config.headerHeight
  let _canvas_width = this.canvasGridDom.clientWidth - this.config.leftWidth

  let _height = Math.max(this.scroll.yb * _canvas_height, 30)
  this.scroll.topDom.style.height = _height + 'px'
  let _width = Math.max(this.scroll.xb * _canvas_width, 30)
  this.scroll.leftDom.style.width = _width + 'px'

  let _left_bi = this.scroll.left / (cWidth - _width)
  let _top_bi = this.scroll.top / (cHeight - _height)
  if (_left_bi > 1) _left_bi = 1
  if (_top_bi > 1) _top_bi = 1
  this.grid._left = _left_bi * (this.allColWidth - _canvas_width)
  this.grid._top = _top_bi * (this.allRowHeight - _canvas_height)

  let _res = this.getStartEnd()
  this.grid.y_start = _res.y_start
  this.grid.y_end = _res.y_end
  this.grid.x_start = _res.x_start
  this.grid.x_end = _res.x_end

  let _length = (String(this.grid.y_end).length - 2)
  this.config.leftWidth = _length * 12 + 32
  this.grid.header.style.left = this.config.leftWidth + 'px'
  this.grid.bgCharbar.style.width = this.config.leftWidth + 'px'
  this.grid.content.style.left = this.config.leftWidth + 'px'
  this.grid.side.style.width = this.config.leftWidth + 1 + 'px'

  this.scroll.xb = cWidth / this.allColWidth

  this.scroll.topDom.style.marginTop = this.scroll.top + 'px'
  this.scroll.leftDom.style.marginLeft = this.scroll.left + 'px'
  this.scroll.leftBox.style.left = this.config.leftWidth + 'px'
}

drawLeft() {
  for (let i = this.grid.y_start; i < this.grid.y_end; i++) {
    let _x = this.config.leftWidth / 2
    let _y = this.config.headerHeight + this.gridRowHeight[i].t + this.gridRowHeight[i].h / 2 + this.config.fontSize / 2 - this.grid._top + 2
    this.ctx.fillText((i + 1 + ''), _x, _y);

    let line_y = this.config.headerHeight + this.gridRowHeight[i].t - this.grid._top
    let gnt = this.ctx.createLinearGradient(0, line_y, this.config.leftWidth, line_y)
    gnt.addColorStop(0, '#ffffff')
    gnt.addColorStop(1, this.colors.line)
    this.drawLine({
      startX: 0,
      startY: line_y,
      endX: this.config.leftWidth,
      endY: line_y,
      bgColor: gnt
    })
  }

  this.drawLine({
    startX: this.config.leftWidth,
    startY: this.config.headerHeight,
    endX: this.canvas.width,
    endY: this.config.headerHeight
  })
}

getTextWidth(txt, width, align) {
  let aban = Math.ceil(txt.length / 2)

  let __txt = txt.substring(0, aban)
  if (align === 'right') {
    __txt = txt.substring(aban, txt.length)
  }

  if (this.ctx.measureText(__txt).width > width) {
    if (align === 'right') {
      for (let i = aban; i >= 0; i--) {
        let _text = __txt.substring(1, __txt.length)
        if (this.ctx.measureText(_text).width < width) {
          return _text
        } else {
          __txt = _text
        }
      }
    } else {
      for (let i = aban; i >= 0; i--) {
        let _text = __txt.substring(0, __txt.length - 1)
        if (this.ctx.measureText(_text).width < width) {
          return __txt
        } else {
          __txt = _text
        }
      }
    }
  } else {
    if (align === 'right') {
      for (let i = aban; i >= 0; i--) {
        let _text = __txt + txt[i]
        if (this.ctx.measureText(_text).width > width) {
          return __txt
        } else {
          __txt = _text
        }
      }
    } else {
      for (let i = aban; i < txt.length; i++) {
        let _text = __txt + txt[i]
        if (this.ctx.measureText(_text).width > width) {
          return __txt
        } else {
          __txt = _text
        }
      }
    }
  }
}
// getNextVal(i, j, t_width) {
//   let tj = j
//   let result = this.gridColWidth[tj].w
//   let tw = t_width - result
//   tj++
//   while (tw > 0) {
//     if (this.data[i][tj]) {
//       break
//     }
//     if (this.gridColWidth[tj]) {
//       tw -= this.gridColWidth[tj].w
//       if (tw > 0) {
//         result += this.gridColWidth[tj].w
//       }
//       tj++
//     }
//   }
//   return result
// }

drawContent() {
  this.setScrollPos()
  let drawImages = {}
  this.ctx.lineWidth = 0.5
  this.ctx.strokeStyle = this.colors.line
  this.ctx.font = this.config.font
  this.ctx.fillStyle = '#FFFFFF'
  this.ctx.translate(0.5, 0.5)
  for (let j = this.grid.x_start; j < this.grid.x_end; j++) {
    let line_x = this.config.leftWidth + this.gridColWidth[j].l - this.grid._left
    this.drawLine({
      startX: line_x,
      startY: this.config.headerHeight,
      endX: line_x,
      endY: this.canvas.height
    })
  }
  for (let i = this.grid.y_start; i < this.grid.y_end; i++) {
    let _v1 = this.data[i] || []

    let line_y = this.config.headerHeight + this.gridRowHeight[i].t - this.grid._top
    this.drawLine({
      startX: this.config.leftWidth,
      startY: line_y,
      endX: this.canvas.width,
      endY: line_y
    })

    if (_v1.length > 0) {
      for (let j = this.grid.x_start; j < this.grid.x_end; j++) {
        let _prop = i + ':' + j
        let merge_start, mr = i, mc = j
        let __merge = this.mergeMap[_prop]
        let _styles = this.sheets[this.act_sheet].styles || []
        let _style_row = _styles[i] || []
        let _style_col = _style_row[j]
        if (_style_col && _style_col.media) {
          drawImages[_style_col.media] = { i, j }
        }

        if (__merge) {
          let __mer = { ...this.merges[__merge] }
          if (__mer.ec > this.grid.x_end - 1) {
            __mer.ec = this.grid.x_end - 1
          }
          if (__mer.er > this.grid.y_end - 1) {
            __mer.er = this.grid.y_end - 1
          }
          if (i === __mer.er && j === __mer.ec) {
            merge_start = __mer
            mr = __mer.r
            mc = __mer.c
          } else {
            continue
          }
        }

        this.ctx.save();
        this.ctx.beginPath();
        let _style = {
          color: this.colors.text,
          size: this.config.fontSize,
          al_v: 'top',
          ...this.getCellStyle({
            row: mr,
            col: mc
          })
        }
        let _v2 = this.data[mr][mc] || ''
        let dy = this.config.headerHeight + this.gridRowHeight[i].t - this.grid._top + 0.5
        let dx = this.config.leftWidth + this.gridColWidth[j].l - this.grid._left + 0.5
        let height = this.gridRowHeight[i].h
        let width = this.gridColWidth[j].w
        let _x = dx + 2
        let _y = dy + 2

        if (merge_start) {
          width = 0
          height = 0
          let x_length = j - merge_start.c + 1
          let y_length = i - merge_start.r + 1
          for (let k = 0; k < x_length; k++) {
            let _gc = this.gridColWidth[j - k]
            if (_gc) {
              width += _gc.w
            }
          }
          for (let k = 0; k < y_length; k++) {
            let _gc = this.gridRowHeight[i - k]
            if (_gc) {
              height += _gc.h
            }
          }
          dx -= (width - this.gridColWidth[j].w)
          dy -= (height - this.gridRowHeight[i].h)

          _x = dx + 2
          _y = dy + 2
        }
        width = Math.floor(width - 1.5)
        height = Math.floor(height - 1.5)

        if (_style.bgcolor) {
          this.drawRect({
            bgColor: _style.bgcolor,
            x: dx - 1,
            y: dy - 1,
            width: width + 2,
            height: height + 2,
            isFill: true
          })
        } else {
          this.drawRect({
            x: dx,
            y: dy,
            width,
            height,
            isFill: true
          })
        }
        if (_style.border) {
          for (let borderKey in _style.border) {
            let _vl = _style.border[borderKey]
            let l_width = 0.5
            if (_vl[0] === 'medium') {
              l_width = 1.5
            } else if (_vl[0] === 'thick') {
              l_width = 3
            }
            if (borderKey === 'top') {
              this.drawLine({
                lineWidth: l_width,
                startX: dx - 0.5,
                startY: dy - 0.5,
                endX: dx + width + 1,
                endY: dy - 0.5,
                bgColor: _vl[1]
              })
            } else if (borderKey === 'right') {
              this.drawLine({
                lineWidth: l_width,
                startX: dx + width + 1,
                startY: dy - 0.5,
                endX: dx + width + 1,
                endY: dy + height + 1,
                bgColor: _vl[1]
              })
            } else if (borderKey === 'bottom') {
              this.drawLine({
                lineWidth: l_width,
                startX: dx - 0.5,
                startY: dy + height + 1,
                endX: dx + width + 1,
                endY: dy + height + 1,
                bgColor: _vl[1]
              })
            } else if (borderKey === 'left') {
              this.drawLine({
                lineWidth: l_width,
                startX: dx - 0.5,
                startY: dy - 0.5,
                endX: dx - 0.5,
                endY: dy + height + 1,
                bgColor: _vl[1]
              })
            }
          }
        }

        let ___y = 2

        if (_style.font) {
          this.ctx.font = _style.font
        }

        if (_style.al_h) {
          this.ctx.textAlign = _style.al_h
          if (_style.al_h === 'center') {
            _x += Math.floor(width / 2)
          } else if (_style.al_h === 'right') {
            _x += width - 2
          }
        }
        if (_style.al_v) {
          this.ctx.textBaseline = _style.al_v
          if (_style.al_v === 'middle') {
            _y += Math.floor(height / 2)
            ___y = Math.floor(_style.size / 2) + 2
          } else if (_style.al_v === 'bottom')  {
            _y += height - 2
          }
        }
        if ((_v2 && !this.mergeMap[_prop]) || merge_start) {
          this.ctx.fillStyle = _style.color
          this.drawContentText({
            _t: _v2 + '',
            _x,
            _y,
            width,
            height,
            _style,
            ___y
          })
        }
        this.ctx.restore()
      }
    }
  }
  this.ctx.translate(-0.5, -0.5)

  this.drawImage(drawImages)
}

drawContentText(_params) {
  let { _t, _x, _y, width, _style, height, ___y } = _params
  let ntxts = [];
  let _txt_arr = "".concat(_t).split("\n");

  for (let i = 0; i < _txt_arr.length; i++) {
    let it = _txt_arr[i]
    let txtWidth = this.ctx.measureText(it).width;
    if (_style.textwrap && txtWidth > width) {
      let textLine = {
        w: 0,
        len: 0,
        start: 0
      };
      for (let i = 0; i < it.length; i += 1) {
        if (textLine.w >= width) {
          ntxts.push(it.substr(textLine.start, textLine.len));
          textLine = {
            w: 0,
            len: 0,
            start: i
          };
        }
        textLine.len += 1;
        textLine.w += this.ctx.measureText(it[i]).width + 1;
      }
      if (textLine.len > 0) {
        ntxts.push(it.substr(textLine.start, textLine.len));
      }
    } else {
      if (txtWidth > width) {
        let _txt = this.getTextWidth(it, width, _style.al_h) || ''
        ntxts.push(_txt);
      } else {
        ntxts.push(it);
      }
    }
  }
  let _line_h = _style.size + Math.ceil(_style.size / 5);
  let _render_txt = ntxts
  if (_style.textwrap) {
    let total_h = _line_h
    if (ntxts.length > 1) {
      _render_txt = []
      total_h = 0
      for (let i = 0; i < ntxts.length; i++) {
        _render_txt.push(ntxts[i])
        if (total_h + _line_h > height) {
          break
        }
        total_h += _line_h
      }
    }

    if (_style.al_v === 'middle' && _render_txt.length > 1) {
      let __h = Math.min(height / 2, total_h / 2)
      _y -= Math.floor(__h) - Math.ceil(_style.size / 2)
    }
  }

  for (let i = 0; i < _render_txt.length; i++) {
    let txt = _render_txt[i]
    let txtWidth = this.ctx.measureText(txt).width
    this.ctx.fillText(txt, _x, _y);
    let __x = 0
    if (_style.al_h === 'center') {
      __x = -Math.floor(txtWidth / 2)
    } else if (_style.al_h === 'right') {
      __x = -txtWidth
    }
    if (_style.strike) {
      this.drawLine({
        lineWidth: 1,
        startX: _x + __x,
        startY: _y + Math.floor(___y  / 2),
        endX: _x + txtWidth + __x,
        endY: _y + Math.floor(___y  / 2),
        bgColor: _style.color
      })
    }
    if (_style.underline || _style.link) {
      this.drawLine({
        lineWidth: 1,
        startX: _x + __x,
        startY: _y + ___y,
        endX: _x + txtWidth + __x,
        endY: _y + ___y,
        bgColor: _style.color
      })
    }
    _y += _line_h;
  }
}

drawLine(_params) {
  let { startX, startY, endX, endY, lineWidth, bgColor } = _params
  this.ctx.beginPath();
  if (lineWidth) {
    this.ctx.lineWidth = lineWidth;
  }
  if (bgColor) {
    this.ctx.strokeStyle = bgColor;
  }
  this.ctx.moveTo(startX, startY);
  this.ctx.lineTo(endX, endY);
  this.ctx.stroke();
  this.ctx.closePath();
}

drawImage(m) {
  let keysArr = Object.keys(m)
  if (keysArr.length > 0) {
    for (let mKey in m) {
      let media = this.sheets[this.act_sheet].media
      let index = mKey
      let _media = media[index]
      let _x = this.config.leftWidth + this.gridColWidth[_media.range.c].l - this.grid._left + 1
      let _y = this.config.headerHeight + this.gridRowHeight[_media.range.r].t - this.grid._top + 1
      let rect = this.getMWidthHeight({
        c: _media.range.c,
        ec: _media.range.ec,
        r: _media.range.r,
        er: _media.range.er
      })
      if (!this.imageMap[index]) {
        let imgData = this.otherData.media[_media.id]
        let image = new Image()
        image.onload = () => {
          let bi = image.width / image.height
          rect.width = Math.floor(rect.height * bi)

          this.ctx.drawImage(image, _x, _y, rect.width, rect.height)
          this.imageMap[index] = {
            image,
            ...rect
          }
        }
        image.src = imgData.buffer
      } else {
        let _image = this.imageMap[index]
        this.ctx.drawImage(_image.image, _x, _y, _image.width, _image.height)
      }
    }
  }
}

getMWidthHeight(params) {
  let { ec, c, er, r } = params
  let result = {
    width: 0,
    height: 0
  }
  let x_length = ec - c + 1
  let y_length = er - r + 1
  for (let k = 0; k < x_length; k++) {
    let _gc = this.gridColWidth[c + k]
    if (_gc) {
      result.width += _gc.w
    }
  }
  for (let k = 0; k < y_length; k++) {
    let _gc = this.gridRowHeight[r + k]
    if (_gc) {
      result.height += _gc.h
    }
  }
  return result
}

wResize = () => {
  this.config.row = 50
  this.config.col = 30
  this.getGridTop()
  this.startDraw()
  this.setActive()
}

getPaste = (e) => {
  if (!this.active.show) {
    return
  }

  const paste = (e.clipboardData || window.clipboardData).getData('text');
  e.preventDefault();

  let _row = paste.split('\n')
  let { _row_start, _row_end, _col_start, _col_end } = this.setActiveStart()
  let _length = Math.min(_row_end - _row_start, _row.length - 1)
  for (let i = 0; i <= _length; i++) {
    let _col = _row[i] || ''
    _col = _col.replace(/\\r$/, '')
    let split_col = _col.split('\t')
    let col_length = Math.min(_col_end - _col_start, split_col.length - 1)
    for (let j = 0; j <= col_length; j++) {
      this.data[_row_start + i][_col_start + j] = split_col[j] || ''
    }
  }

  this.drawTable()
  this.setActive()
}

setActiveStart() {
  let _row_start = this.active.row_start
  let _row_end = this.active.row_end
  let _col_start = this.active.col_start
  let _col_end = this.active.col_end
  if (_row_start > _row_end) {
    _row_start = this.active.row_end
    _row_end = this.active.row_start
  }
  if (_col_start > _col_end) {
    _col_start = this.active.col_end
    _col_end = this.active.col_start
  }
  return {
    _row_start,
    _row_end,
    _col_start,
    _col_end
  }
}

renderSheetsMenu() {
  if (this.scroll.sheets) {
    let _html = '<div class="sheet-menus">'
    for (let i = 0; i < this.sheets.length; i++) {
      _html += `<div class="${ this.act_sheet === i ? 'a-sheet act' : 'a-sheet' }" data-index="${i}">${ this.sheets[i].name }</div>`
    }
    _html += '</div>'
    this.scroll.sheets.innerHTML = _html
  }
}

onKeydown = (e) => {
  if (e.ctrlKey) {
    if (e.key === 'c') {
      if (this.active.show) {
        let txt = ''
        let { _row_start, _row_end, _col_start, _col_end } = this.setActiveStart()

        _row_end = Math.min(_row_end, this.config.min_row)
        _col_end = Math.min(_col_end, this.config.min_col)

        for (let i = _row_start; i <= _row_end; i++) {
          let _data_row = this.data[i] || []
          for (let j = _col_start; j <= _col_end; j++) {
            let val = _data_row[j] || ''
            let merge_end = this.mergeMap[i + ':' + j]
            if (merge_end) {
              let merge_start = this.merges[merge_end]
              let _r_v = this.data[merge_start.r] || []
              val = _r_v[merge_start.c] || ''
            }

            txt += val + '\t'
          }
          txt += '\n'
        }
        copyText(txt)
      }
    } else if (e.key === 'v') {
      if (this.active.show) {

      }
    }
  }
}

initResize() {
  if (!this.isInitResize) {
    this.isInitResize = true
    window.addEventListener('resize', this.wResize)
  }
}

removeResize() {
  if (this.isInitResize) {
    this.isInitResize = false
    window.removeEventListener('resize', this.wResize)
  }
}

initKeyDown() {
  if (!this.isInitKeyDown) {
    this.isInitKeyDown = true
    window.addEventListener('keydown', this.onKeydown)
    this.grid.content.addEventListener('paste', this.getPaste)
  }
}
removeKeyDown() {
  if (this.isInitKeyDown) {
    this.isInitKeyDown = false
    window.removeEventListener('keydown', this.onKeydown)
    this.grid.content.removeEventListener('paste', this.getPaste)
  }
}

reload(sheets = [], otherData = {}) {
  this.initOptions(sheets, otherData)

  this.startDraw()
}

getMergeMap() {
  if (this.merges) {
    let theMerge = Object.assign({}, this.merges)
    for (let mergesKey in theMerge) {
      let val = theMerge[mergesKey]
      let _end = mergesKey.split(':')
      let er = Number(_end[0])
      let ec = Number(_end[1])
      let y_length = er - val.r + 1
      let x_length = ec - val.c + 1

      this.mergesStart[val.r + ':' + val.c] = {
        er: _end[0],
        ec: _end[1]
      }
      for (let j = 0; j < y_length; j++) {
        for (let i = 0; i < x_length; i++) {
          let prop = (er - j) + ':' + (ec - i)
          this.mergeMap[prop] = mergesKey
        }
      }
    }
  }
}

destroy() {
  this.canvasGridDom.removeEventListener('mousemove', this.gridMouseHover)
  this.canvasGridDom.removeEventListener('mousedown', this.gridMouseDown)
  this.canvasGridDom.removeEventListener('mouseup', this.gridMouseUp)
  this.canvasGridDom.removeEventListener('touchmove', this.gridTouchMove)
  this.canvasGridDom.removeEventListener('touchstart', this.gridTouchStart)
  this.canvasGridDom.removeEventListener('touchend', this.gridTouchEnd)
  document.removeEventListener('DOMMouseScroll', this.scrollFunc)
  window.removeEventListener('mousewheel', this.scrollFunc)
  this.removeResize()
  this.removeKeyDown()

  this.parentDom.innerHTML = ''
  this.data = []
  this.parentDom = null
  this.canvasGridDom = null
  this.canvas = null
  this.ctx = null
  this.scroll.leftDom = null
  this.scroll.topDom = null
  this.scroll.leftBox = null
  this.scroll.sheets = null
  this.grid.header = null
  this.grid.side = null
  this.grid.content = null
  this.grid.visibleLabel = null
  this.grid.visibleVal = null
  this.active.hDom = null
  this.active.lDom = null
  this.active.activeCell = null
}
}
