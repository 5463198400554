var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navTop"},[_c('div',{staticClass:"navl",on:{"click":_vm.goDrive}},[_c('img',{staticClass:"logo",attrs:{"draggable":true,"src":_vm.$utils.getPng('web4/icon_loog10'),"alt":""}}),_c('svg-btn',{staticClass:"logo1",attrs:{"icon-class":"logo-fm"}})],1),_c('div',{staticClass:"navr"},[(_vm.myCookieValue)?_c('div',{staticClass:"userInfoBox"},[_c('div',{staticClass:"fuliBox"},[(_vm.$store.state.userInfo.is_pass == 0)?_c('div',{staticClass:"needSetPswVisible"},[_c('div',{staticClass:"set-psw-box",style:(`backgroundImage:url(${
              _vm.$store.state.theme == 'light'
                ? _vm.$utils.getPng('set-password')
                : _vm.$utils.getPng('web4/icon-equir-n')
            })`),on:{"click":_vm.handleClickPsw}},[_c('div',{staticClass:"set-psw-img"},[_c('img',{attrs:{"src":_vm.$utils.getPng('set-password-icon'),"alt":""}})]),_c('div',{staticClass:"set-psw-text"},[_c('RaceNotice',{attrs:{"msg":"请尽快设置密码，避免盗号风险！"}})],1)])]):_vm._e()]),_c('HoverPop',[_c('div',{staticClass:"userBox",style:(_vm.$store.state.userInfo.is_pass == 0 ? 'margin-left:0' : '')},[_c('div',{staticClass:"imgBox"},[_c('img',{staticClass:"avater",attrs:{"src":_vm.$store.state.userInfo.logo,"alt":""}}),(_vm.$store.state.userInfo.frameUrl)?_c('img',{staticClass:"icon-header",attrs:{"src":_vm.$store.state.userInfo.frameUrl,"alt":""}}):_vm._e()]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$store.state.userInfo.nickname))]),_c('svg-btn',{staticClass:"iconOpen",attrs:{"icon-class":"icon-open"}})],1),_c('div',{staticClass:"userOperateBox",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"userOBTop",style:(`backgroundImage:url(${
              _vm.$store.state.theme == 'light'
                ? _vm.$utils.getPng('web4/icon-bg')
                : _vm.$utils.getPng('web4/icon-bg-n')
            })`)},[_c('div',{staticClass:"avaterBox"},[_c('img',{staticClass:"avater",attrs:{"src":_vm.$store.state.userInfo.logo,"alt":""}}),(_vm.$store.state.userInfo.frameUrl)?_c('img',{staticClass:"icon-header",attrs:{"src":_vm.$store.state.userInfo.frameUrl,"alt":""}}):_vm._e()]),_c('div',{staticClass:"userRight"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$store.state.userInfo.nickname))]),_c('div',{staticClass:"welfare"},[(_vm.$store.state.userInfo.web_tag?.length > 0)?_c('div',{staticClass:"boxa"},[_c('img',{staticStyle:{"width":"auto","height":"16px","flex-grow":"0"},attrs:{"src":_vm.$store.state.userInfo.web_tag[0].indexOf(
                        'icon-dcvip-light'
                      ) > 0
                        ? _vm.$utils.getPng(
                            `web4/icon-dcvip-${_vm.$store.state.theme}`
                          )
                        : _vm.$store.state.userInfo.web_tag[0],"alt":""}})]):_c('div',{staticClass:"boxa pointer"},[_c('img',{staticClass:"fuli",attrs:{"src":_vm.$utils.getPng('web4/icon-fulipoint'),"alt":""}}),_c('div',{staticClass:"tit1",on:{"click":function($event){return _vm.routeTo('/welfare')}}},[_vm._v(" "+_vm._s(_vm.$store.state.userInfo.point)+"福利点·福利社 ")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"loginOut pointer",on:{"click":function($event){return _vm.loginOut()}}},[_vm._v("退出")])])])]),_c('div',{staticClass:"userOBdown"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.userInfo.is_vip == 0),expression:"$store.state.userInfo.is_vip == 0"}],staticClass:"icon-vip1 pointer",attrs:{"src":_vm.$utils.getPng('web4/icon-vip1'),"alt":""},on:{"click":function($event){return _vm.toVip()}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$store.state.userInfo.show_banner == 1 &&
                _vm.$store.state.userInfo.is_vip == 1
              ),expression:"\n                $store.state.userInfo.show_banner == 1 &&\n                $store.state.userInfo.is_vip == 1\n              "}],staticClass:"icon-vip1 pointer",attrs:{"src":_vm.$utils.getPng('web4/icon-vip2'),"alt":""},on:{"click":function($event){return _vm.toVip()}}}),_c('div',{staticClass:"operateTable"},_vm._l((_vm.table),function(item,index){return _c('div',{key:index,staticClass:"item pointer",on:{"click":function($event){return _vm.tableClick(item)}}},[_c('img',{staticClass:"itemIcon",attrs:{"src":_vm.$utils.getPng(
                      `web4/icon-seting-${_vm.$store.state.theme}-${item.icon}`
                    ),"alt":""}}),_c('div',{staticClass:"itemTit"},[_vm._v(_vm._s(item.tit))])])}),0)])])]),_c('div',{staticClass:"general",on:{"click":function($event){_vm.isShowuserNews = true}}},[_c('el-badge',{attrs:{"max":99,"value":_vm.$store.state.userMsgNub,"hidden":_vm.$store.state.userMsgNub == 0}},[_c('svg-btn',{staticClass:"logo1",attrs:{"icon-class":"icon-general"}})],1)],1)],1):_c('div',{staticClass:"loginbtn"},[_c('fm-button',{staticStyle:{"margin":"0 16px 0 24px"},attrs:{"type":"primary","round":"","size":"medium"},on:{"click":function($event){return _vm.toLogin(0)}}},[_vm._v("登 录")]),_c('fm-button',{attrs:{"type":"info","round":"","size":"medium"},on:{"click":function($event){return _vm.toLogin(1)}}},[_vm._v("注 册")]),_c('span',{staticClass:"divide"})],1),_c('HoverPop',[_c('div',{staticClass:"nav-openvip",on:{"click":function($event){return _vm.openVip()}}},[_c('span',{style:(`background-image:url(${_vm.$utils.getPng(
            'web4/openVipBtn-' + _vm.$store.state.theme
          )})`)},[_vm._v("开通会员")])])])],1),_c('changePassDia',{attrs:{"type":"0","isShow":_vm.changePassFlag},on:{"handleClose":function($event){_vm.changePassFlag = false}}}),_c('BindPhone',{key:"navTopBind",ref:"bindPhoneCom",attrs:{"isShow":_vm.isShowBindPhone,"type":_vm.bindType,"time":_vm.time},on:{"getPhoneCode":_vm.getPhoneCode,"bindPhone":_vm.bindPhone,"confirmNameAuth":_vm.realNameAuth,"handleClose":function($event){_vm.isShowBindPhone = false}}}),_c('userNews',{attrs:{"isShow":_vm.isShowuserNews},on:{"handleClose":function($event){_vm.isShowuserNews = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }