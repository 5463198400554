<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/UploadDownloadBox/index.vue
 * @Description: 上传下载解压框
-->

<template>
  <div class="UploadDownloadBox">
    <div class="big" id="fmUpload" v-show="$store.state.showUpDownBox == 1">
      <div class="top">
        <div class="leftNav">
          <div
            class="item pointer"
            :class="[{ itemAct: item.id == $store.state.navAct }]"
            v-for="item in navList"
            :key="item.id"
            @click="setTab(item.id)"
          >
            <div class="name">{{ item.name }}</div>
            <div class="num" v-show="item.num != ''">{{ item.num }}</div>
          </div>
        </div>
        <div class="right-nav-icon">
          <fm-button @click="toUp()" text type="info" size="mini" icon="icon-up-close"></fm-button>

          <fm-button style="margin-left: 8px" @click="handleCloseUp()" text type="info" size="mini" icon="icon-close"></fm-button>
        </div>
      </div>
      <!-- 上传 -->
      <div class="uploadBox" v-show="$store.state.navAct == 0">
        <div id="fmUploadBtn"></div>
        <input id="selectFolder" type="file" name="folder" webkitdirectory hidden @change="onSelectFolder"/>
        <div v-if="uploadFiles.length > 0" class="upLoadDownContent">
          <div class="upLoadDownList">
            <div class="item item-one" v-for="(item, index) in uploadFiles" :key="item.id+index">
              <div class="item-info">
                <img
                  class="imgleft"
                  :src="$utils.fileIcon(item.getExtension())"
                />
                <div class="rightInfo">
                  <div class="fileName">
                    {{ item.name }}
                  </div>
                  <div class="fileInfo">
                    <div class="stateBox">
                      <div class="state1" v-if="item.fileStatus == 0">
                        <span>排队中</span>
                      </div>
                      <div class="state1" v-if="item.fileStatus == 1">
                        <span style="margin-right: 22px;">
                          {{
                            item.fileStatus == 4
                              ? $utils.bytesToSize(item.size)
                              : $utils.bytesToSize(item.sizeUploaded())
                          }} / {{ $utils.bytesToSize(item.size) }}
                        </span>
                        <span style="margin-right: 20px;">{{ $utils.convertSecondsToHHMMSS(item.timeRemaining()) }}</span>
                        <span style="color: #F67942;">{{ $utils.bytesToSize(item.currentSpeed)+`/s`}}</span>
                      </div>
                      <div class="state1" v-if="item.fileStatus == 2">
                        暂停上传
                      </div>
                      <div class="state1 state2" v-if="item.fileStatus == 3">
                        上传失败
                      </div>
                      <div class="state1" v-if="item.fileStatus == 4">
                        <span class="toleft">已上传</span>
                        <span class="">至&nbsp;&nbsp;&nbsp;</span>
                        <span class="UploadFloderName pointer"  @click="queryParentFolders(item,1)">{{
                          item.folderName
                        }}</span>
                      </div>
                      <div class="state1" v-if="item.fileStatus == 10">
                        获取文件信息中
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 正在上传操作按钮 -->
                <div class="operateBtn">
                  <div
                    class="btnItem pointer"
                    v-if="item.fileStatus == 1"
                    @click="pauseFile(item)"
                  >
                    <el-tooltip effect="dark" content="暂停" placement="top">
                      <svg-btn icon-class="icon-cloud-suspend" />
                    </el-tooltip>
                  </div>
                  <div
                    class="btnItem pointer"
                    v-if="item.fileStatus == 2"
                    @click="playFile(item)"
                  >
                    <el-tooltip effect="dark" content="开始" placement="top">
                      <svg-btn icon-class="icon-cloud-start" />
                    </el-tooltip>
                  </div>
                  <div
                    class="btnItem pointer"
                    v-if="item.fileStatus == 3 && !item.failTxt"
                    @click="retryFile(item)"
                  >
                    <el-tooltip effect="dark" content="重试" placement="top">
                      <svg-btn
                        style="width: 16px; height: 16px"
                        icon-class="icon-refresh"
                      />
                    </el-tooltip>
                  </div>
                  <div
                    class="btnItem pointer"
                    v-show="item.fileStatus == 4"
                    @click="queryParentFolders(item,1)"
                  >
                    <el-tooltip
                      effect="dark"
                      content="查看所在目录"
                      placement="top"
                    >
                      <svg-btn icon-class="icon-openfolder" />
                    </el-tooltip>
                  </div>
                  <div
                      class="btnItem pointer"
                      v-if="item.fileStatus == 4"
                      @click="delUp(item.id)"
                  >
                    <el-tooltip
                        effect="dark"
                        content="删除"
                        placement="top"
                    >
                      <svg-btn icon-class="icon-del" />
                    </el-tooltip>
                  </div>
                  <div
                    class="btnItem pointer"
                    v-show="item.fileStatus != 4"
                    @click="showCancelFun('doColseFile', item.id)"
                  >
                    <el-tooltip effect="dark" content="取消" placement="top">
                      <svg-btn icon-class="icon-stop" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div
                v-if="item.fileStatus == 2 || item.fileStatus == 1"
                class="bg-progress"
                :style="'width:' + item.progress() * 100 + '%'"
              ></div>
              <div
                v-if="item.fileStatus == 2 || item.fileStatus == 1"
                class="line"
                :style="'width:' + item.progress() * 100 + '%'"
              ></div>
            </div>
          </div>
        </div>
        <div v-else class="noTask">
          暂无上传任务
        </div>
        <div class="top2">
          <svg-btn icon-class="icon-tips-circle-fill" class="icon"></svg-btn>
          <div class="top2Tit">
            严禁上传非法内容
            <span class="text pointer" @click="goAskdetail(1)"
              >《对于违规账号的处置方式》</span
            >
            <span class="text pointer" @click="goAskdetail(2)"
              >《飞猫盘内容审核机制公示》</span
            >
            <span class="text pointer" @click="goAskdetail(3)"
              >《免责协议》</span
            >
          </div>
        </div>
        <div class="downBtnBox">
          <div class="down1">
            <div class="downTit">设置文件目录：</div>
            <div
              class="floder"
              @click="getFolderMoveList({ id: 0, name: '全部文件' })"
            >
              {{ $store.state.UploadFloder.name }}
            </div>
          </div>
          <div class="down2">
            <fm-button
              type="primary"
              icon="icon-cloud-add"
              round
              size="medium"
              @click="selectfile"
              >添加文件</fm-button
            >
            <div class="rightBtn">
              <fm-button
                v-show="showAllStart"
                :disabled="allStartDis"
                @click="startUpload()"
                type="info"
                size="medium"
                plain
                icon="icon-cloud-start"
                >全部开始</fm-button
              >
              <fm-button
                v-show="showAllStop"
                @click="pauseUpload()"
                type="info"
                size="medium"
                plain
                icon="icon-cloud-suspend"
                >全部暂停</fm-button
              >
              <fm-button
                  :disabled="uping.length == 0"
                  type="info"
                  size="medium"
                  plain
                  icon="icon-stop"
                  @click="uping.length == 0 ? '' : showCancelFun('cancelUpload', '')"
              >全部取消</fm-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 下载 -->
      <div class="downloadBox" v-show="$store.state.navAct == 1">
        <div class="top2">
          <img class="icon" :src="$utils.getPng('web4/icon-up')" alt="" />
          <div class="tit">
            {{ $store.state.userInfo.is_vip == 1
        ?  "会员极速下载通道"
        : "普通下载通道"}}
            <span v-show="downloading.length > 0"
              >· {{ $store.state.userInfo.is_vip == 0 ? "" : "极速" }}下载中 {{
                allSpeed
              }}M/s</span
            >
          </div>
          <div
            class="vipBtn"
            @click="toVip()"
            v-show="$store.state.userInfo.is_vip == 0"
          >
            立即提速
          </div>
        </div>
        <div class="top3" v-show="$store.state.userInfo.is_vip != '1'">
          <img class="icon" :src="$utils.getPng('web4/icon-pc')" alt="" />
          <div class="tit">全新PC端，大文件下载更稳定</div>
          <div class="vipBtn" @click="toApp()">前往体验</div>
        </div>

        <div
          class="upLoadDownList"
          :class="{ upLoadDownList1: $store.state.userInfo.is_vip != '1' }"
          style="padding-bottom: 16px"
        >
        <div class="list-default" v-show="downloadData.length == 0">
            <img :src="$utils.getPng('web4/file_default')" />
            <span>暂无下载记录</span>
          </div>
          <div
            class="item item-one"
            v-for="(item) in downloadData"
            :key="item.currentDownId"
            v-show="!item.iscomplete"
          >
            <div class="item-info">
              <img class="imgleft" :src="$utils.fileIcon(item.type)" />
              <div class="rightInfo">
                <div class="fileName">
                  {{ item.name }}
                </div>
                <div class="fileInfo">
                  <div class="stateBox">
                    <div class="state1" v-show="item.fileStatus == 1">
                      <span class="size" style="margin-right: 22px;">
                        {{ $utils.bytesToSize(item.currentSize) }} /
                        {{ item.fileSize }}
                      </span>
                      <span style="margin-right: 20px;">{{ formatTime(item.surplusSecond) }}</span>
                      <span style="color: #F67942;">{{ item.dfcurrentSpeed }}M/s</span>
                    </div>
                    <div class="state1" v-show="item.fileStatus == 0">
                      排队中
                    </div>
                    <div class="state1 state3" v-show="item.fileStatus == 2">
                      下载成功
                    </div>
                    <div class="state1 state2" v-show="item.fileStatus == 3">
                      下载失败
                    </div>
                  </div>
                </div>
              </div>
              <!-- 下载 操作按钮 -->
              <div class="operateBtn">
                <div
                    class="btnItem pointer"
                    v-if="item.fileStatus == 3"
                    @click="retryDownload(item)"
                  >
                    <el-tooltip effect="dark" content="重试" placement="top">
                      <svg-btn
                        icon-class="icon-restart"
                      />
                    </el-tooltip>
                </div>
                <div
                  class="btnItem pointer"
                  v-show="item.fileStatus == 1 || item.fileStatus == 0 || item.fileStatus == 3"
                  @click="showCancelFun('cancelDownload', item)"
                >
                  <el-tooltip effect="dark" content="取消" placement="top">
                    <svg-btn icon-class="icon-stop" />
                  </el-tooltip>
                </div>
                <div class="btnItem pointer" v-if="item.fileStatus == 2"
                    @click="delDownFile(item)"
                  >
                    <el-tooltip
                        effect="dark"
                        content="删除"
                        placement="top"
                    >
                      <svg-btn icon-class="icon-del" />
                    </el-tooltip>
                </div>
              </div>
            </div>
            <div
              v-if="item.fileStatus == 1"
              class="bg-progress"
              :style="'width:' + item.downloadProgress + '%'"
            ></div>
            <div
              v-if="item.fileStatus == 1"
              class="line"
              :style="'width:' + item.downloadProgress + '%'"
            ></div>
          </div>
        </div>
        <div class="titB">有任务进行时请勿刷新网页</div>
      </div>
      <!-- 解压 -->
      <div class="pressBox" v-show="$store.state.navAct == 2">
        <div class="upLoadDownList">
          <div class="item item-one" v-for="(item, index) in pressList" :key="index">
            <div class="item-info">
              <img class="imgleft" :src="item.ext_icon" />
              <div class="rightInfo">
                <div class="fileName">
                  {{ item.name }}
                </div>
                <div class="fileInfo">
                  <div class="line1" v-show="item.type == 1">
                    正在解压（{{ item.progress }}% ）至&nbsp;&nbsp;&nbsp;<span class="saveAs">{{ item.saveAs }}</span>
                  </div>
                  <div class="line2" v-show="item.type == 2 && item.fail_num != '-1'">
                    已解压到&nbsp;&nbsp;&nbsp;<span class="pointer saveAs" @click="queryParentFolders(item,2)">{{ item.saveAs }}</span>
                    <div class="fail" v-show="item.fail_num != 0">
                      {{ item.extraLabel }}
                    </div>
                  </div>
                  <div class="line3" v-show="item.type == 2 && item.fail_num == '-1'">
                    解压失败
                  </div>
                  <div class="line3" v-show="item.type == '-1'">排队中</div>
                </div>
              </div>
              <!-- 解压 操作按钮 -->
              <div class="operateBtn">
                <div
                  class="btnItem pointer"
                  v-show="item.type == 2"
                  @click="queryParentFolders(item,2)"
                >
                  <el-tooltip
                    effect="dark"
                    content="查看所在目录"
                    placement="top"
                  >
                    <svg-btn icon-class="icon-openfolder" />
                  </el-tooltip>
                </div>
                <div
                  class="btnItem pointer"
                  @click="showCancelFun('cancelUpTask', item.id)"
                >
                  <el-tooltip effect="dark" content="取消" placement="top">
                    <svg-btn icon-class="icon-stop" />
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div
              v-if="item.type == 1"
              class="bg-progress"
              :style="'width:' + item.progress + '%'"
            ></div>
            <div
              v-if="item.type == 1"
              class="line"
              :style="'width:' + item.progress + '%'"
            ></div>
          </div>
          <div class="list-default" v-show="pressList.length == 0">
            <img :src="$utils.getPng('web4/file_default')" />
            <span>暂无解压记录</span>
          </div>
        </div>
        <div class="p-bottom">
          <div class="titA">{{ tips }}</div>
          <fm-button
            :disabled="pressList.length < 1"
            icon="icon-stop"
            size="medium"
            plain
            type="info"
            @click="
              pressList.length < 1 ? '' : showCancelFun('clearUpList', '-1')
            "
            >全部清空</fm-button
          >
        </div>
      </div>
    </div>
    <div class="small" v-show="$store.state.showUpDownBox == 2">
      <div
        class="item pointer"
        :class="[{ itemAct: item.id == $store.state.navAct }]"
        v-for="item in navList"
        :key="item.id"
        @click="openDialog(item.id)"
      >
        <div class="name">{{ item.name }}</div>
        <div class="num" v-show="item.num != ''">{{ item.num }}</div>
      </div>
      <img
        class="icon-up"
        :src="$utils.getPng('web4/icon-up')"
        alt=""
        v-show="downloading.length > 0 && $store.state.userInfo.is_vip == 1"
      />
      <div
        class="upName"
        v-show="downloading.length > 0 && $store.state.userInfo.is_vip == 1"
      >
        会员极速下载中
      </div>
      <div class="up">
         <fm-button @click="toUp()" text type="info" size="mini" icon="icon-mem-up"></fm-button>
        <fm-button @click="handleCloseUp()" text type="info" size="mini" icon="icon-close"></fm-button>
      </div>
    </div>
    <FileDirDialog
      @changeNav="changeNav"
      @getFolder="getFolderMoveList"
      @handleClose="isShowFileDir = false"
      @doFileDir="confirmFloder"
      type="1"
      :isShow="isShowFileDir"
      :list="folderList"
      :moveNav="moveNav"
    ></FileDirDialog>
    <cancelUploadDialog
      ref="cancelUpload"
      :isShow="cancelUploadFlag"
      @handleClose="cancelUploadClose"
      @confirm="cancelUploadConfirm"
    ></cancelUploadDialog>
    <BindPhone
        :isShow="isShowBindPhone"
        :type="bindType"
        :time="time2"
        @getPhoneCode="getPhoneCode"
        @bindPhone="bindPhone"
        @confirmNameAuth="realNameAuth"
        @handleClose="isShowBindPhone = false"
    ></BindPhone>
  </div>
</template>

<script>
import FileDirDialog from "@/components/Dialog/FileDirDialog";
import cancelUploadDialog from "@/components/Cloud/CancelUpload.vue";
import OneUpload from "../../utils/upload/newUpload";
import TheSpeed from "../../utils/upload/speedometer";
import BindPhone from "@/components/Cloud/BindPhone";
import {
  queryProgressTask,
  getDirectUploadLink,
  folderMoveList,
  blackRegexListAPI,
  unZipLogDelete,
  queryParentFolders,
  modiFolder,
  downloadFile
} from "@/utils/apiList/cloud";
import {bindPhoneAPI, bindPhoneSendCode, realNameAuth} from "@/utils/apiList/account";

let self = null;
export default {
  data() {
    return {
      timerA: null,
      time2: 60,
      bindType: 1,
      isShowBindPhone: false,
      isShow: true,
      navList: [
        { id: 0, name: "上传", num: "", isShow: true },
        { id: 1, name: "下载", num: "", isShow: true },
        { id: 2, name: "解压", num: "", isShow: true },
      ],
      navAct: 0,
      value: 50,
      //解压
      tips: "",
      time: "", //解压时间
      pressList: [],
      progressTaskTimer: null,
      //上传
      folderList: [],
      pfolderid: 0,
      uploader: null,
      uploadFiles: [],
      fmAllStart: false,
      isToast: 0,
      moveNav: [], // 移动文件弹窗的顶部文件夹路径数据
      upFolder: [], // 移动文件夹列表
      showboxBread: false,
      data: {
        id: 0,
        name: "全部文件",
      },
      md5Flag: 0, //是否已计算完MD5 0 未开始  1开始  2结束
      //// 0等待上传，1上传中，2暂停上传,3上传失败 ，4上传成功 10获取文件信息
      ctype: 1, //1全部取消 2单个取消
      showCancelBox: false,
      colseFileId: "",
      task_id: "",
      fileInfo: {},
      fmInvalid: [], //飞猫盘不允许格式
      taskList: [], //任务list
      task_count: 0,
      isShowFileDir: false,
      //======
      downloadData: [],
      downTitle: "普通下载通道",
      regex: "",
      isNewUpload: 1,
      Max_DOWN_NUM: 5,
      cancelUploadFlag: false,
      cancelID: "",
      cancelFun: "",
      isUp: 0,
      phone_item: {
        phone: "",
        pcode: "",
      },
      downItem:{},
        upClicked: false
    };
  },
  components: {
    FileDirDialog,
    cancelUploadDialog,
    BindPhone
  },
  // fileStatus 0等待上传，1上传中，2暂停上传,3上传失败 ，4上传成功 5 取消上传
  created() {
    if (this.$utils.getCookie(this.$glb.fmCookieName) !== null) {
      // if(this.$store.state.regex==''){//上传白名单
        this.blackRegexList();
      // }
      if(this.$store.state.polling!='0'){//解压进度查询
        this.queryProgressTask();
        this.$store.commit("setAttr", {//有解压任务时 保留弹框状态
          name: "showUpDownBox",
          val: this.$store.state.showUpDownBox,
        });
      }else{
        this.$store.commit("setAttr", {//无解压任务时 关闭
          name: "showUpDownBox",
          val: 0,
        });
      }
      // 是否是会员
      this.$store.state.userInfo.is_vip == 1
        ? (this.downTitle = "会员极速下载通道")
        : (this.downTitle = "普通下载通道");
    }
  },
  computed: {
    isNewUpload() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_type) || 0;
    },
    part_size() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_part_size) || 2 * 1024 * 1024;
    },
    uping() {
      var arr = this.uploadFiles.filter(
        // 0等待上传，1上传中，2暂停上传,3上传失败 ，4上传成功 5 取消上传
        (item) => item.fileStatus ==0 || item.fileStatus == 1 || item.fileStatus == 2 ||item.fileStatus == 10
      );
      return arr;
    },
    allSpeed() {
      var sum = 0;
      for (var i = this.downloadData.length - 1; i >= 0; i--) {
        let _obj = this.downloadData[i];
        if (
          _obj.theSpeed &&
          !_obj.iscomplete &&
          !_obj.isAbort &&
          !_obj.isFail
        ) {
          sum += Number(this.downloadData[i].dfcurrentSpeed);
        }
      }
      return sum > 0 ? sum.toFixed(2) : "0.00";
    },
    downloading() {
      var arr = this.downloadData.filter((item) => item.fileStatus == 1 || item.fileStatus == 0);
      return arr;
    },
    //全部开始：当任务列表无“正在上传”任务状态时展示该操作，点击后逻辑与“开始”相同
    showAllStart() {
      let uploadFiles = this.uploadFiles;
      if (uploadFiles.length === 0) {
        return true;
      }
      for (let i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i].fileStatus == 1 ||uploadFiles[i].fileStatus == 10) {
          return false;
        }
      }
      return true;
    },
    //全部开始的禁用状态的判断
    allStartDis() {
      let uploadFiles = this.uploadFiles;
      if (uploadFiles.length === 0) {
        return true;
      }
      for (let i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i].fileStatus == 2 || uploadFiles[i].fileStatus == 1 || uploadFiles[i].fileStatus == 10) {
          return false;
        }
      }
      return true;
    },
    //只要存在一个开始，展示全部暂停
    showAllStop() {
      let uploadFiles = this.uploadFiles;
      if (uploadFiles.length === 0) {
        return false;
      }
      // 检查是否存在fileStatus等于1的情况，如果有则返回true
      for (let i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i].fileStatus === 1 || uploadFiles[i].fileStatus == 10) {
          return true;
        }
      }
      // 所有fileStatus都不等于1，返回false
      return false;
    },
    downingNum() {
      var arr = this.downloadData.filter((item) => item.fileStatus == 1);
      return arr.length;
    },
    //正在解压和排队中的
    decompressionNum(){
      var arr = this.pressList.filter((item) => item.type == 1 || item.type == -1);
      return arr;
    }
  },
  watch: {
    uping: {
      handler(newV) {
        if (newV) {
          this.navList[0].num = newV.length;
          if(newV.length==0){
          }
        }
      },
    },
    downloading: {
      handler(newV) {
        if (newV) {
          this.navList[1].num = newV.length;
        }
      },
    },
    decompressionNum:{
       handler(newV) {
        if (newV) {
          this.navList[2].num = newV.length;
        }
      },
    },
    downingNum(newV) {
      const downloadingTasks = this.downloadData.filter(
        (task) => task.fileStatus === 0
      );
      const downloadingCount = this.downloadData.filter(
        (task) => task.fileStatus === 1
      ).length;
      const remainingSlots = this.Max_DOWN_NUM - newV; // 剩余可下载数量
      if (remainingSlots > 0) {
        const tasksToStart = downloadingTasks.slice(0, remainingSlots);
        tasksToStart.forEach((task) => {
          this.startDownload(task);
        });
      }
    }
  },
  mounted() {
    this.initUpload();
    this.$store.commit("setAttr", {
      name: "getProgressTaskcallback",
      val: this.getProgressTask,
    });
    this.$store.commit("setAttr", {
      name: "selectfilecallback",
      val: this.selectfile,
    });
    this.$store.commit("setAttr", {
      name: "getDownloadFile",
      val: this.fileDown,
    });
    this.$store.commit("setAttr", {
      name: "dragUpload",
      val: this.toDragUpload,
    });
    self = this;
    // 监听浏览器前进回退

    window.addEventListener("popstate", this.handleCancelUpload);
    // 监听标签页关闭和刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  beforeDestroy() {
    clearTimeout(this.progressTaskTimer);
    this.progressTaskTimer = null;
  },
  methods: {
    setTab(id){
      this.$store.commit("setAttr", {
        name: "navAct",
        val: id,
      });
      if(id==2){
        this.queryProgressTask()
      }
    },
    formatTime(input) {
      if (typeof input === "string") {
        const matches = input.match(/(\d+)小时(\d+)分钟(\d+)秒/);
        if (matches) {
          let hours = parseInt(matches[1]).toString().padStart(2, "0");
          let minutes = parseInt(matches[2]).toString().padStart(2, "0");
          let seconds = parseInt(matches[3]).toString().padStart(2, "0");
          return `${hours}:${minutes}:${seconds}`;
        } else {
          return "";
        }
      } else {
        return "00:00:00";
      }
    },
    toUp() {
      this.$store.commit("setAttr", {
        name: "shutdown",
        val: true,
      });
      this.$store.commit("setAttr", {
        name: "showUpDownBox",
        val: this.$store.state.showUpDownBox == 1 ? 2 : 1,
      });
    },
    closeUp() {
      this.$store.commit("setAttr", {
        name: "showUpDownBox",
        val: 0,
      });
    },
    //从列表预览 解压
    getProgressTask(){
      this.$store.commit("setAttr", {
        name: "shutdown",
        val: false,
      });
      this.queryProgressTask()
    },
    //获取解压进度
    queryProgressTask() {
      clearTimeout(this.progressTaskTimer);
      var ShanghaiTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Shanghai",
      });
      var ShanghaiTimeInMs = new Date(ShanghaiTime).getTime();
      this.time = ShanghaiTimeInMs;
      queryProgressTask({ time: this.time }).then((res) => {
        if (res.status == "1") {
          this.pressList = res.data.list || [];
          this.tips = res.data.tips;
          this.$store.commit("setAttr", {
            name: "polling",
            val: res.data.polling,
          });
          if (res.data.polling == "1") {
            if(!this.$store.state.shutdown){
              this.$store.commit("setAttr", {
                name: "showUpDownBox",
                val: 1,
              });
            }
            this.progressTaskTimer = setTimeout(() => {
              this.queryProgressTask();
            }, 5000);
          }
        } else {
          clearTimeout(this.progressTaskTimer);
        }
      });
    },
    //跳转飞猫盘
    goFolder(folders, id) {
      if(this.$router.currentRoute.name=='drive'){
        this.$router.push({
          path: this.$store.state.fmPath,
        });
        this.$nextTick(()=>{
          this.$store.commit('closePreviewPublicVuex')
          this.$store.commit("getfiles", {
            name: "query",
            value: {
              folder_id: id,
              folders: folders,
            },
          });
        })
      }else{
        this.$router.push({
          name:'drive',
          params:{folder_id: id,folders: folders}
        });
      } 
    },
    // 查询文件夹
    queryParentFolders(item,type) {
      let params={
        folder_id: type==1 ? item.folderid : item.folder_id,
        type:type,
        task_id:type==1 ? item.task_id : item.id,
      }
      queryParentFolders(params).then((res) => {
        if (res.status == "1") {
          this.goFolder(res.data.folders, res.data.folder_id);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    delUp(id) {
      for (let j = 0, len = this.uploadFiles.length; j < len; j++) {
        let item = this.uploadFiles[j]
        if (item.id === id) {
          item.fileStatus = 5;
          this.uploadFiles.splice(j, 1);
          break;
        }
      }
    },
    //取消 解压
    cancelUpTask(item) {
      this.clearUpList(item.id);
    },
    clearUpList() {
      unZipLogDelete({ task_id: this.cancelID }).then((res) => {
        if (res.status == "1") {
          this.queryProgressTask();
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //==========================
    //上传
    hexToBinaryString(hex) {
      let bytes = [],
        length = hex.length,
        x;
      for (x = 0; x < length - 1; x += 2) {
        bytes.push(parseInt(hex.substr(x, 2), 16));
      }
      return String.fromCharCode.apply(String, bytes);
    },
    handleCancelUpload() {
      if (this.isNewUpload === 1) {
        this.isUp = 0
        for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
          let _file = this.uploadFiles[i];
          if (_file.fileStatus === 1 || _file.fileStatus === 2) {
            _file.cancel();
          }
          this.uploadFiles.splice(i, 1);
        }
      }
    },
    uploadset(opt) {
      this.fileInfo[opt.name] = opt.value;
    },
    selectfile() {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return false
      }
      document.getElementById("fmUploadBtn").click();
    },
    // 单个文件操作：上传
    playFile(_file) {
        if (this.upClicked) return;
        this.upClicked = true
        setTimeout(() => {
            this.upClicked = false
        }, 300)
      if (this.isNewUpload === 1) {
        if (this.isUp <= 2) {
          this.isUp += 1
          if (!_file.prdMd5) {
            _file.fileStatus = 10
            this.newComputeMD5(_file);
          } else if (!_file.task_id) {
            _file.fileStatus = 1
            this.createTask(_file);
          } else {
            _file.resume();
          }
        } else {
          _file.fileStatus = 0
        }
      } else {
        _file.fileStatus = 1;
        _file.resume();
      }
    },
    //刷新
    retryFile(_file) {
        if (this.upClicked) return;
        this.upClicked = true
        setTimeout(() => {
            this.upClicked = false
        }, 300)
      if (this.isNewUpload === 1) {
        if (this.isUp <= 2) {
          this.isUp += 1
          if (!_file.prdMd5) {
            _file.fileStatus = 10
            this.newComputeMD5(_file);
          } else if (!_file.task_id) {
            _file.fileStatus = 1
            this.createTask(_file);
          } else {
            _file.resume();
          }
        } else {
          _file.fileStatus = 0
        }
      } else {
        _file.retry();
        _file.fileStatus = 1;
      }
    },
    // 当个文件操作：暂停
    pauseFile(_file) {
        if (this.upClicked) return;
        this.upClicked = true
        setTimeout(() => {
            this.upClicked = false
        }, 300)
      if (_file) {
        this.handleSetUp(_file)
        _file.fileStatus = 2;
        _file.pause();
      }
      this.handleNext()
    },
    // 当个文件操作：关闭 取消
    colseFile(id) {
      this.colseFileId = id;
      this.ctype = 2;
      this.showCancelBox = true;
    },
    //当个文件操作：关取消
    doColseFile(id) {
      let tmpfiles = this.uploadFiles;
      for (let j = 0, len = tmpfiles.length; j < len; j++) {
        if (tmpfiles[j].id === id) {
          this.handleSetUp(tmpfiles[j])
          tmpfiles[j].cancel();
          tmpfiles[j].fileStatus = 5;
          this.uploadFiles.splice(j, 1);
          this.showCancelBox = false;
          this.handleNext()
          return;
        }
      }
    },
    // 底部操作：全部开始
    startUpload() {
      if (this.uploadFiles.length == 0 || this.allStartDis) {
        return;
      }
      if (this.isNewUpload === 1) {
        for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
          let _file = this.uploadFiles[i];
          if (_file.fileStatus === 2) {
            if (this.isUp <= 2) {
              this.isUp += 1
              if (!_file.prdMd5) {
                _file.fileStatus = 10
                this.newComputeMD5(_file);
              } else if (!_file.task_id) {
                _file.fileStatus = 1
                this.createTask(_file);
              } else {
                _file.fileStatus = 1
                _file.retry();
              }
            } else {
              _file.fileStatus = 0
            }
          }
        }
      } else {
        this.uploader.upload();
        var tmpfiles = this.uploadFiles;
        for (var j = 0, len = tmpfiles.length; j < len; j++) {
          if (tmpfiles[j].fileStatus !== 4) {
            tmpfiles[j].fileStatus = 1;
            Vue.set(this.uploadFiles, j, tmpfiles[j]);
          }
        }
        this.uploader.resume();
      }
    },
    // 底部操作：暂停上传
    pauseUpload() {
      if (this.uploadFiles.length == 0 || !this.showAllStop) {
        this.isUp = 0
        return;
      }
      if (this.isNewUpload === 1) {
        this.isUp = 0
        for (let i = 0; i < this.uploadFiles.length; i++) {
          let _file = this.uploadFiles[i];
          if (_file.fileStatus === 1 || _file.fileStatus === 0 || _file.fileStatus === 10) {
            _file.pause();
          }
        }
      } else {
        this.uploader.pause();
        var tmpfiles = this.uploadFiles;
        for (var j = 0, len = tmpfiles.length; j < len; j++) {
          if (tmpfiles[j].fileStatus !== 4) {
            tmpfiles[j].fileStatus = 2;
            Vue.set(this.uploadFiles, j, tmpfiles[j]);
          }
        }
      }
    },
    // 底部操作：取消上传
    cancelUpload() {
      this.isUp = 0
      if (this.uploadFiles.length == 0) {
        return;
      }
      this.$store.commit("setAttr", { name: "showUpDownBox", val: 2 });
      if (this.isNewUpload === 1) {
        for (let i = 0; i < this.uploadFiles.length; i++) {
          let _file = this.uploadFiles[i];
          _file.cancel();
        }
      } else {
        this.uploader.cancel();
      }
      // this.uploadFiles = [];
      for (var j = this.uploadFiles.length - 1; j >= 0; j--) {
        if (this.uploadFiles[j].fileStatus !== 4) {
          this.uploadFiles[j].fileStatus = 5;
          if (this.uploadFiles[j].fileStatus == 0) {
            this.uploadFiles[j].fileStatus = 5;
          }
          this.uploadFiles.splice(j, 1); // 如果不等于4，则移除当前项
        }
      }
    },
    showCancelUpload() {
      var ingUpload = this.uploadFiles.filter((item) => {
        return item.fileStatus != 3 && item.fileStatus != 4;
      });
      if (ingUpload.length > 0) {
        this.ctype = 1;
        this.showCancelBox = true;
      } else {
        this.uploadFiles = [];
        this.$store.commit("setAttr", { name: "showuploadlayer", val: false });
      }
    },
    //获取允许上传后缀白名单
    blackRegexList() {
      blackRegexListAPI({ os: "pc" }).then((res) => {
        if (res.status == "1") {
          this.$store.commit("setAttr", { name: "regex", val: res.data.regex });
        } else {
        }
      });
    },
    //添加文件
    initUpload() {
      this.uploader = new Uploader({
        target(file) {
          let md5 = file.prdMd5;
          let url = sessionStorage.getItem(`fmUrl_${md5}_${file.id}`);
          return url;
        },
        chunkSize: 2 * 1024 * 1024,
        // 是否还允许再次上传
        simultaneousUploads: 5,
        initialPaused: true,
        allowDuplicateUploads: true,
        // 是否测试每个块是否在服务端已经上传了
        testChunks: true,
        // 服务器分片校验函数，秒传及断点续传基础
        checkChunkUploadedByResponse: function (chunk, message) {
          var objMessage = JSON.parse(message);
          if (parseInt(objMessage.data.file_id) > 0) {
            return true;
          }
        },
        query: function (file) {
          let md5 = file.prdMd5;
          let uniqueId = sessionStorage.getItem(`fmMd5_${md5}_${file.id}`);
          let task_id = sessionStorage.getItem(`fmTask_${md5}_${file.id}`);
          let query = self.fileInfo.query;
          return {
            ...query,
            uniqueId: uniqueId,
            hash: md5,
            task_id: task_id,
          };
        },
      });
      var upobj = this.uploader;
      upobj.assignBrowse(document.getElementById("fmUploadBtn"));
      upobj.assignDrop(document.getElementById("fmUpload"));
      // 文件添加 单个文件
      upobj.on("fileAdded", (file, event) => {
        file.folderid = this.$store.state.UploadFloder.id
        file.folderName = this.$store.state.UploadFloder.name
        let state = this.handleAddFile(file)
        if (!state) {
          file.cancel()
        }
          if (this.$store.state.handleDropEnd) {
              this.$store.state.handleDropEnd()
          }
        return state
      });
    },
    handleAddFile(file) {
      this.$store.commit("setAttr", {
        name: "showUpDownBox",
        val: 1,
      });
      this.$store.commit("setAttr", {
        name: "navAct",
        val: 0,
      });
      if (file.size > 4 * 1024 * 1024 * 1024) {
        this.$toast("单个文件大小不能超过4G", 2);
        return false;
      }
      if (file.name == "") {
        this.$toast("文件名不能为空", 2);
        return false;
      }

      let regResult = this.$store.state.regex.every(regex => new RegExp(regex,"i").test(file.name));
      if (!regResult) {
        this.$toast("不支持该类型文件上传，请尝试其他格式", 2);
        return false;
      }
      file.fileStatus = 0; // 0等待上传，1上传中，2暂停上传,3上传失败 ，4上传成功
      file.md5Flag = 1;
      let _file = file;
      if (this.isNewUpload === 1) {
        let opt = {
          id: file.id,
          name: file.name,
          prdMd5: file.prdMd5,
          md5Flag: file.md5Flag,
          fileStatus: file.fileStatus,
          size: file.size,
          file: file.file,
          currentSpeed: 0,
          loaded: 0,
          folderid: file.folderid,
          folderName: file.folderName,
          sizeM1: this.part_size,
          $store: this.$store,
        };
        _file = new OneUpload(opt, this.upCallback);
      }
      this.uploadFiles.unshift(_file);
      if (this.isNewUpload === 1) {
        let num = this.isUp
        if (num <= 2) {
          this.isUp += 1
          _file.fileStatus = 10
          this.newComputeMD5(_file);
        }
      }
      return true
    },
    upCallback(args) {
      if (args.action === 'success' || args.action === 'fail') {
          let _is_up = this.isUp - 1
          if (_is_up < 0) {
              this.isUp = 0
          } else {
              this.isUp = _is_up
          }
        this.handleNext()
      }
    },
    async onSelectFolder(e) {
      if (e.target.files.length) {
        let folderName = this.getFolderName(e.target.files[0])
        let folderRes = await this.createFolder(folderName)
        let date = new Date().getTime()
        for (let i = 0; i < e.target.files.length; i++) {
          let val = e.target.files[i]
          date += i
          let file = {
            id: date,
            name: val.name,
            prdMd5: '',
            md5Flag: 1,
            fileStatus: 0,
            size: val.size,
            file: val,
            folderid: folderRes.folderid,
            folderName: folderRes.folderName,
            getExtension: () => {
              return file.name
            }
          }
          this.handleAddFile(file)
        }

        setTimeout(() => {
          this.uploader.cancel();
        }, 1000)
      }
      e.target.value = ''
    },
    async createFolder(name) {
      let result = {
        folderid: this.$store.state.UploadFloder.id,
        folderName: this.$store.state.UploadFloder.name
      }
      if (!name) return result;
      let params = {
        name: name,
        pid: this.$store.state.UploadFloder.id,
        id: '',
      };
      if (params.name) {
        params.name = params.name.substring(0, 50)
      }
      try {
        let res = await modiFolder(params)
        if (res.status == "1" || res.status == "60010") {
          result.folderid = res.data.folder_id
          result.folderName = params.name
        }
      } catch (e) {}
      return result
    },
    getFolderName(file) {
      if (file) {
        let rePath = file.webkitRelativePath || file.fullPath
        let FolderNames = rePath.replace('/' + file.name, '')
        let _name = FolderNames.split('/')
        return _name[0]
      }
      return ''
    },
    //拖拽上传
    async toDragUpload(opt){
      let upList = opt.value || []

      for (let i = 0; i < upList.length; i++) {
        let obj = upList[i]
        let folder = {
          folderid: this.$store.state.UploadFloder.id,
          folderName: this.$store.state.UploadFloder.name
        }
        let date = new Date().getTime()
        if (obj.list.length > 0) {
          if (obj.type === 'folder') {
            let folderName = this.getFolderName(obj.list[0])
            folder = await this.createFolder(folderName)
          }
        } else {
          continue
        }

        for (let j = 0; j < obj.list.length; j++) {
          let val = obj.list[j]
          date += j
          let file = {
            id: date,
            name: val.name,
            prdMd5: '',
            md5Flag: 1,
            fileStatus: 0,
            size: val.size,
            file: val,
            folderid: folder.folderid,
            folderName: folder.folderName,
            getExtension: () => {
              return file.name
            }
          }
          this.handleAddFile(file)
        }
      }
      setTimeout(() => {
        this.uploader.cancel();
      }, 1000)
    },
    handleSetUp(file, type = 'del') {
      if (type === 'del') {
        if (file.fileStatus === 1 || file.fileStatus === 10) {
          this.isUp -= 1
          if (this.isUp < 0) {
            this.isUp = 0
          }
        }
      } else if (type === 'add') {
        let state = this.isUp <= 2
        if (state) {
          this.isUp += 1
        }
        return state
      }
    },
    handleNext() {
      let flag = 0
      if (this.isUp <= 2) {
          let _next
          for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
              if (this.uploadFiles[i].fileStatus == 0) {
                  _next = this.uploadFiles[i]
                  break
              }
          }
        if (_next) {
          this.isUp += 1
          if (!_next.prdMd5) {
              _next.fileStatus = 10
            this.newComputeMD5(_next);
          } else if (!_next.task_id) {
              _next.fileStatus = 1
            this.createTask(_next);
          } else {
            _next.retry();
          }
          flag = 1
        }
      }
      return flag
    },
    //获取文件夹
    getFolderMoveList(folder) {
      if (folder.id) {
        this.moveNav.push(folder);
      } else {
        this.moveNav = [{ name: "全部文件", id: "0" }];
      }
      let params = {
        id: folder.id || 0,
      };
      folderMoveList(params)
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
      this.data = {
        id: folder.id,
        name: folder.name,
      };
    },
    // 移动弹窗-点击顶部文件夹路径-跳出当层文件夹
    changeNav(item, index) {
      let arr = this.moveNav.slice(0, index + 1);
      this.moveNav = arr;
      folderMoveList({ id: item.id })
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
      this.data = {
        id: item.id,
        name: item.name,
      };
    },
    //确定 文件夹
    confirmFloder() {
      this.$store.commit("setUploadFloder", this.data);
      this.isShowFileDir = false;
    },
    //清空缓存
    removeUid(file) {
      sessionStorage.removeItem(`fmMd5_${file.prdMd5}_${file.id}`);
      sessionStorage.removeItem(`fmUrl_${file.prdMd5}_${file.id}`);
      sessionStorage.removeItem(`fmTask_${file.prdMd5}_${file.id}`);
    },
    handleReadFile(_blob) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer();
        fileReader.onloadend = (e) => {
          spark.append(e.target.result);
          resolve(spark.end());
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(_blob);
      });
    },
    async newComputeMD5(file) {
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let hashAll = "";
      let _flag = true;

      if (file.part_count === 0) {
          let _blob = blobSlice.call(file.file, 0, file.size);
          let _md5 = await this.handleReadFile(_blob);
          hashAll += this.hexToBinaryString(_md5);
      } else {
          for (let i = 0; i < file.part_count; i++) {
              if (file.fileStatus === 5) {
                  _flag = false;
                  break;
              }
              let _blob;
              if (i === file.part_count - 1) {
                  _blob = blobSlice.call(file.file, i * file.sizeM1, file.size);
              } else {
                  _blob = blobSlice.call(
                      file.file,
                      i * file.sizeM1,
                      (i + 1) * file.sizeM1
                  );
              }
              try {
                  let _md5 = await this.handleReadFile(_blob);
                  hashAll += this.hexToBinaryString(_md5);
              } catch (e) {
                  this.reduceUp()
                  _flag = false;
                  break;
              }
          }
      }

      if (!_flag) {
        file.fileStatus = 3;
        file.prdMd5 = "";
        this.handleNext()
        return;
      }

      file.md5Flag = 2;
      let md5 = SparkMD5.hashBinary(hashAll);
      file.prdMd5 = md5;
      file.uniqueIdentifier = md5;
      sessionStorage.setItem(
        `fmMd5_${md5}_${file.id}`,
        "" + md5 + Math.random() * (9999 - 1 + 1)
      );
      if (file.fileStatus !== 2 && file.fileStatus !== 5) {
        this.createTask(file);
      }
    },
    //获取taskid 判秒传文件
    createTask(file) {
      let _data = {
        folderid: '0',
        type: 1,
        hash: file.prdMd5,
        totalSize: file.size,
        filename: file.name,
      };
      if (!file.folderid) {
        file.folderid = this.$store.state.UploadFloder.id
        file.folderName = this.$store.state.UploadFloder.name;
      }
      _data.folderid = file.folderid
      getDirectUploadLink(_data).then((res) => {
        if (res.status == "1") {
          file.fileStatus = 1;
          let formObj = res.data;
          sessionStorage.setItem(
            `fmUrl_${file.prdMd5}_${file.id}`,
            formObj.url
          ); //设置 url
          if (parseInt(formObj.file_id) > 0) {
            this.removeUid(file);
            file.file_id = formObj.file_id;
            file.task_id = formObj.task_id
            file.isMc = true;
            file.success();
          } else {
            file.file_id = formObj.file_id;
            file.task_id = formObj.task_id;
            file.merge_part_url = formObj.merge_part_url;
            file.upload_part_url = formObj.upload_part_url;
            file.cancel_url = formObj.cancel_url;
            sessionStorage.setItem(
              `fmTask_${file.prdMd5}_${file.id}`,
              formObj.task_id
            ); //设置 task_id 缓存
            file.resume();
          }
        }else if(res.status=='52001'){//空间不足
            this.reduceUp()
          file.fileStatus = 3;
          file.msgToast = res.msg;
          this.$toast(res.msg, 2);
          this.handleNext()
        }else if(res.status=='40001') {//未绑定手机号
            this.reduceUp()
          file.fileStatus = 3;
          this.bindType = 1;
            this.handleNext()
          this.isShowBindPhone = true;
          return false
        }else {
            this.reduceUp()
          file.fileStatus = 3;
          file.msgToast = res.msg;
          this.$toast(res.msg, 2);
            this.handleNext()
        }
      });
    },
      reduceUp() {
          let __up = this.isUp - 1
          if (__up < 0) {
              __up = 0
          }
          this.isUp = __up
      },
    //==============
    //下载
    getUrlParms(url, parameterName) {
      parameterName = parameterName.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + parameterName + "(=([^&#]*)|&|#|$)");
      var results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    fileDown(opt) {
      var str = opt.value.url;
      var auth_key = this.getUrlParms(str, "auth_key");
      this.downTitle = opt.value.title;
      let currentDownId = Date.now() 
      
      let newDownloadTask = {
        typeid: opt.value.typeid || "",
        fid: opt.value.fid,
        type: opt.value.fileType,
        name: opt.value.fileName,
        fileSize: opt.value.fileSize, //文件大小
        downloadProgress: 0, //下载进度
        currentSize: 0, //当前已下载大小
        surplusSecond: 0, //剩余下载时间
        dfcurrentSpeed: 0, //当前下载速度
        surplusSize: 0, //剩余下载大小
        lastDataSize: 0, //上一秒加载的大小
        size: 0, //跟上一秒的差值
        myInterval: "",
        spLength: 0,
        iscomplete: false, //是否下载完成
        xmlHttp: "",
        isAbort: false, //是否取消终止下载
        isFail: false, //是否下载失败
        url: opt.value.url, //下载链接
        auth_key: auth_key,
        fileStatus: 0,
        down_id:opt.value.down_id,
        currentDownId,
        timeId:0
      };
      this.downloadData.unshift(newDownloadTask);
      this.addToDownloadQueue(newDownloadTask);
    },
    addToDownloadQueue(newDownloadTask) {
      // 检查当前正在进行的下载数量
      const runningDownloads = this.downloadData.filter(
        (task) => task.fileStatus === 1
      ).length;
      if (runningDownloads < 5) {
        // 如果正在进行的下载数量少于 5，则启动新的下载任务
        this.startDownload(newDownloadTask);
      }
    },
    startDownload(task) {
      this.downloadfile(task.url, task.name, "application/octet-stream",task.currentDownId);
    },
    downloadfile(url, strFileName, strMimeType,currentDownId) {
      var that = this;
      var xmlHttp = null;
     
      if (window.ActiveXObject) {
        // IE6, IE5 浏览器执行代码
        xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
      } else if (window.XMLHttpRequest) {
        // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
        xmlHttp = new XMLHttpRequest();
      }
       xmlHttp.currentDownId = currentDownId;

      const downloadingTasks = this.downloadData.filter(
        (task) => task.fileStatus === 0
      );
      const downloadingCount = this.downloadData.filter(
        (task) => task.fileStatus === 1
      ).length;
      const remainingSlots = this.Max_DOWN_NUM - downloadingCount; // 剩余可下载数量

      if (remainingSlots > 0) {
        const tasksToStart = downloadingTasks.slice(0, remainingSlots);
        tasksToStart.forEach((task) => {
          task.fileStatus = 1;
          task.xmlHttp = xmlHttp;
          that.startInterval(task.currentDownId, xmlHttp);
        });
      }
      //2.如果实例化成功，就调用open（）方法：
      if (xmlHttp != null) {
        xmlHttp.open("get", url, true);
        xmlHttp.responseType = "blob"; //关键
        xmlHttp.send();
        xmlHttp.onreadystatechange = doResult; //设置回调函数
      }
      function doResult() {
        if (xmlHttp.readyState == 4) {
          //4表示执行完成
          if (xmlHttp.status == 200) {
            //200表示执行成功
            if (window.download) {
              window.download(xmlHttp.response, strFileName, strMimeType);
            }
            for (let i = 0; i < that.downloadData.length; i++) {;
              if (currentDownId == that.downloadData[i].currentDownId) {
                that.downloadData[i].fileStatus = 2;
                that.downloadData[i].dfcurrentSpeed = 0;
                if (that.downloadData[i].theSpeed) {
                  that.downloadData[i].theSpeed.clearSpeed();
                  that.downloadData[i].theSpeed = null;
                }
              }
            }
          } else {
            for (let i = 0; i < that.downloadData.length; i++) {
              if (that.downloadData[i].currentDownId == currentDownId) {
                that.downloadData[i].fileStatus = 3;
                that.downloadData[i].dfcurrentSpeed = 0;
                if (that.downloadData[i].theSpeed) {
                  that.downloadData[i].theSpeed.clearSpeed();
                  that.downloadData[i].theSpeed = null;
                }
              }
            }
          }
        }
      }
    },
    startInterval(currentDownId, xmlHttp) {
      let that = this;
      const itemIndex = this.downloadData.findIndex((item) => item.currentDownId === currentDownId);
      if (itemIndex !== -1) {
        const currentItem = this.downloadData[itemIndex];

        currentItem.theSpeed = new TheSpeed();
        currentItem.theSpeed.initSpeed((_speed) => {
          if (currentItem.dfcurrentSpeed >= 0) {
            currentItem.dfcurrentSpeed = (_speed / 1024 / 1024).toFixed(2); // Update current speed
          }

          if (
            currentItem.surplusSecond !== undefined &&
            currentItem.surplusSecond !== null
          ) {
            currentItem.surplusSecond = that.$utils.formatSeconds(
              (currentItem.surplusSize ? currentItem.surplusSize : 0) / _speed
            ); // Update surplus time
            currentItem.size = 0;
          }
          if(currentItem.timeId < 15){
            currentItem.timeId += 1; 
          }else{
              if(this.$utils.bytesToSizePagec(currentItem.currentSize)){
                  currentItem.timeId = 0; 
                  currentItem.xmlHttp.abort();
                  currentItem.fileStatus = 3
              }
          }

        });
    

      xmlHttp.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            if (currentItem) {
              if (evt.target.currentDownId == currentItem.currentDownId) {
                let transfer =
                  evt.loaded - currentItem.currentSize;
                currentItem.theSpeed.spLength += transfer;
                currentItem.currentSize = evt.loaded;
                let percentComplete = evt.loaded / evt.total; //获取进度
                currentItem.downloadProgress = (
                  percentComplete * 100
                ).toFixed(1);
                currentItem.surplusSize = evt.total - evt.loaded; //剩余大小
                let sizeModify =
                  evt.loaded - currentItem.lastDataSize; //避免网络不行出现负数。。
                if (sizeModify) {
                  currentItem.size += sizeModify;
                }
                currentItem.lastDataSize = evt.loaded;
              }
            }
          }
        },
        false
      );
      }
    },
    //重试
    retryDownload(item,accessid='') {
      this.downItem = item;
      if(accessid){
        this.downItem = {...this.downItem ,accessid}
      }
      this.goDownloadAlagain()
    },
    goDownloadAlagain(captcha_verify){
      return new Promise((resolve) => {
        let params={
          type:4,// 1:列表下载，2:福利点下载，3:vip下载，4：重试
          code:this.downItem.fshort,
          captcha_verify:captcha_verify||'',
          accessid:this.downItem.accessid,
          down_id:this.downItem.down_id,
          retry_url:this.downItem.url
        }
        downloadFile(params).then(res=>{
          if(res.status == "1"){
            resolve({
              result: true,
              validate: true
            })
            var auth_key = this.getUrlParms(res.data.url, "auth_key");
            let currentDownId = Date.now();
            this.downItem.auth_key= auth_key
            this.downItem.url = res.data.url
            this.downItem.down_id = res.data.down_id
            this.downItem.fileStatus = 0
            this.downItem.downloadProgress = 0, //下载进度
            this.downItem.currentSize=0, //当前已下载大小
            this.downItem.surplusSecond= 0, //剩余下载时间
            this.downItem.dfcurrentSpeed= 0, //当前下载速度
            this.downItem.surplusSize=0, //剩余下载大小
            this.downItem.lastDataSize= 0, //上一秒加载的大小
            this.downItem.size= 0, //跟上一秒的差值
            this.downItem.myInterval="",
            this.downItem.spLength= 0,
            this.downItem.xmlHttp="",
            this.downItem.currentDownId = currentDownId,
            this.downItem.timeId=0,
            this.addToDownloadQueue(this.downItem);
            // this.downloadfile(res.data.url, fileName, "application/octet-stream");
          }else if(res.status=='40001') {//未绑定手机号
            this.bindType = 1;
            this.isShowBindPhone = true;
          }else if(res.status=='50006'){//被锁定
            this.$toast(res.msg, 2);
          }else if(res.status == '90001'){
            resolve({
                result: false,
                validate: false
              })
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.goDownloadAlagain)
          }else if(res.status == "90101") {
            this.showRisk = 1
            this.$refs.Riskver.open()
          }else {
            resolve({
                result: true,
                validate: false
              })
            this.$toast(res.msg, 2);
          }
        })
      })
    },
    //删除
    delDownFile(item){
      this.downloadData.splice(this.downloadData.indexOf(item), 1);
    },
    //取消下载
    cancelDownload: _.debounce(
      function(item){
        if (item.fileStatus == 0) {
        this.downloadData.splice(this.downloadData.indexOf(item), 1);
        return;
      }
      for (let i = 0; i < this.downloadData.length; i++) {
        if (this.downloadData[i].currentDownId == item.currentDownId) {
          this.downloadData[i].isAbort = true;
          this.downloadData[i].iscomplete = true;
          this.downloadData[i].xmlHttp.abort();
          if (item.dfcurrentSpeed) {
            item.dfcurrentSpeed = 0;
          }
          if (item.theSpeed) {
            item.theSpeed.clearSpeed();
            item.theSpeed = null;
          }
          this.downloadData.splice(this.downloadData.indexOf(item), 1);
        }
      }

    
    },50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    beforeunloadHandler(e) {
      if (this.downloadData.length > 0 || this.uploadFiles.length>0) {
        this._beforeUnload_time = new Date().getTime();
        e = e || window.event;
        if (e) {
          e.returnValue = "关闭提示";
        }
        return "关闭提示";
      }
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        if (this.downloadData.length > 0) {
        }
      }
    },
    goAskdetail(type) {
      if (type == 1) {
        window.open("/askdetail/84");
      } else if (type == 2) {
        window.open("/askdetail/82");
      } else {
        window.open("/askdetail/85");
      }
    },
    openDialog(id) {
      this.$store.commit("setAttr", {
        name: "showUpDownBox",
        val: 1,
      });
      this.$store.commit("setAttr", {
        name: "navAct",
        val: id,
      });
    },
    showCancelFun(fun, id) {
      if (
        (fun == "doColseFile" && this.uploadFiles.length == 0) ||
        (fun == "cancelUpload" && this.uploadFiles.length == 0)
      ) {
        return;
      }
      if (fun == "cancelUpload") {
        this.$refs.cancelUpload.tit = "确定取消全部任务？";
      }
      if (fun == "doColseFile" || fun == 'cancelDownload') {
        this.$refs.cancelUpload.tit = "确定要取消该任务？";
      }
      if (fun == "clearUpList") {
        this.$refs.cancelUpload.tit = "确定要清空全部记录？";
      }
      if (fun == "cancelUpTask") {
        this.$refs.cancelUpload.tit = "确定要取消该任务？";
      }
      this.cancelUploadFlag = true;
      this.cancelID = id;
      this.cancelFun = fun;
    },
    cancelUploadClose() {
      this.cancelUploadFlag = false;
    },
    cancelUploadConfirm() {
      this[this.cancelFun](this.cancelID);
      if (this.cancelID === 'closeDia') {
        this.isUp = 0
        if (this.cancelFun === 'cancelAllDownload') {
          this.cancelAllUpload()
        } else {
          this.cancelAllDownload()
        }
        this.closeUp()
        this.cancelID = ''
      }
      this.cancelUploadFlag = false;
    },
    cancelAllUpload() {
      if (this.isNewUpload === 1) {
        for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
          let item = this.uploadFiles[i]
          if (item) {
            item.cancel()
          }
        }
      } else {
        this.uploader.cancel();
      }

      for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
        this.uploadFiles[i].fileStatus = 5;
        this.uploadFiles.splice(i, 1);
      }
    },
    cancelAllDownload() {
      for (let i = this.downloadData.length - 1; i >= 0; i--) {
        let item = this.downloadData[i]
        if (item) {
          if (item.xmlHttp) {
            item.xmlHttp.abort();
            item.isAbort = true;
          }
          if (item.dfcurrentSpeed) {
            item.dfcurrentSpeed = 0;
          }
          if (item.theSpeed) {
            item.theSpeed.clearSpeed();
            item.theSpeed = null;
          }
        }
        this.downloadData.splice(i, 1);
      }
    },
    handleCloseUp() {
      this.$store.commit("setAttr", {
        name: "shutdown",
        val: true,
      });
      if (this.uping.length > 0) {
        this.$refs.cancelUpload.tit = "列表有未上传完的文件，确定要放弃上传吗？";
        this.cancelUploadFlag = true;
        this.cancelID = 'closeDia';
        this.cancelFun = 'cancelAllUpload';
        this.$store.commit("setAttr", {
          name: "navAct",
          val: 0,
        });
        return true
      }
      if (this.downloading.length > 0) {
        this.$refs.cancelUpload.tit = "列表有未下载完的文件，确定要放弃下载吗？";
        this.cancelUploadFlag = true;
        this.cancelID = 'closeDia';
        this.cancelFun = 'cancelAllDownload';
        this.$store.commit("setAttr", {
          name: "navAct",
          val: 1,
        });
        return true
      }
      this.cancelAllUpload()
      this.cancelAllDownload()
      this.closeUp()
    },
    toVip() {
      window.open("/vip");
    },
    toApp() {
      window.open("/app");
    },
    minute() {
      this.time2 = this.time2 - 1;
      if (this.time2 == 0) {
        this.time2 = 60;
        clearInterval(this.timerA);
      }
    },
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      this.getPhoneAly();
    },



    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
