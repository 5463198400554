<template>
  <el-dialog
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="PromoteDialog" :append-to-body="true" :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
                <div class="left" v-show="type==2">
                    <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                    <span>会员专享功能</span>
                </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
                <img :src="$utils.getPng('web4/'+icon)">
                <span>{{msg}}</span>
          </div>
          <div class="d-footer">
            <fm-button type="primary" round @click="govip" v-show="vip_update=='2'">开通会员</fm-button>
              <fm-button type="primary" round @click="govip" v-show="vip_update=='1'">升级会员</fm-button>
              <fm-button type="primary" round @click="handleClose" v-show="vip_update=='0'">知道啦</fm-button>
          </div>
      </div>
      </el-dialog>
</template>

<script>
export default {
    props:{
        isShow:Boolean,
        msg:String,
        type:Number,
        vip_update:String,
        icon:String
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        govip(){
            window.open('/vip')
            this.$emit('handleClose')
        }
    }
}
</script>

<style>

</style>