<template>
  <div class="home fmDialog">
    <el-dialog
      :before-close="handleCloseToast"
      width="480px"
      :visible.sync="showToast"
    >
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <div class="tit1">提示</div>
          </div>
          <fm-button
            type="info"
            size="mini"
            text
            icon="icon-model-close"
            @click="handleCloseToast()"
          ></fm-button>
        </div>
        <div class="dialogCenter">
          <div class="tit">
            您提供的个人身份证信息、支付订单截图（支付宝、微信、Apple订单截图）将用于申请人的身份识别及与帐号使用历史信息的比对，作为证明申请人为帐号使用人的参考依据。如您不同意，则无法进入后续流程。
          </div>
          <div class="bottom">
            <fm-button
              size="medium"
              @click="sure()"
              >同 意</fm-button
            >
            <fm-button style="margin-left: 8px" type="info" plain size="medium" @click="handleCloseToast()"
              >不同意</fm-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showToast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleCloseToast() {
      this.$emit("handleCloseToast");
    },
    sure() {
      this.$emit("sure");
    },
  },
};
</script>

<style lang="scss" scoped>
.fmDialog {
  .diaBox {
    border-radius: 16px;
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 0 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
      }
    }
    .dialogCenter {
      font-size: 14px;
      font-weight: 400;
      font-size: 14px;
      padding: 20px 24px 24px 24px;
      @include useTheme {
        color: getVar("text-color1-2c2c2c");
      }

      .tit1 {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
      }
      .bottom {
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn1 {
          width: 110px;
          height: 36px;
          background: rgba(246, 121, 66, 0.1);
          border-radius: 18px;
          color: #f67942;
          margin-right: 8px;
          &:hover {
            color: #fff;
            background-color: #f67942;
            border-color: #f67942;
          }
          &:nth-child(2) {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
      .bottom1 {
        display: flex;
        .btn2 {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }
  ::v-deep .el-dialog {
    width: 480px;
    min-height: 208px;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    @include useTheme {
      background: getVar("text-color3-ffffff");
      color: getVar("text-color1-2c2c2c") !important;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
