<template>
  <div class="downPage" v-loading="showCheckPucode == -1">
    <div class="pageBox">
      <div v-show="showCheckPucode == 1" class="down-content">
        <!-- 文件信息 -->
        <el-skeleton :loading="getFileInfoGlag">
          <template slot="template">
            <div class="dp-fileinfo">
              <el-skeleton-item class="icon"></el-skeleton-item>
              <div class="info">
                <div style="height: 26px">
                  <el-skeleton-item style="width: 620px"></el-skeleton-item>
                </div>
                <div style="height: 21px; margin-top: 6px">
                  <el-skeleton-item style="width: 320px"></el-skeleton-item>
                </div>
              </div>
              <!-- <el-skeleton-item class="fm-button" style="border-radius: 20px;"></el-skeleton-item> -->
            </div>
            <div class="dp-use">
              <div class="title" style="height: 26px">
                <el-skeleton-item style="width: 108px"></el-skeleton-item>
              </div>
              <div class="box">
                <el-skeleton-item
                  v-for="item in skeletonItem"
                  :key="item"
                  style="height: 36px; margin-right: 12px; border-radius: 18px"
                  :style="
                    item == 1
                      ? 'width: 178px;'
                      : item == 2
                      ? 'width: 163px;'
                      : 'width: 130px;'
                  "
                >
                </el-skeleton-item>
              </div>
            </div>
          </template>
          <template>
            <div v-show="fileStatus == 1">
              <!-- 文件信息 -->
              <div class="dp-fileinfo">
                <img class="icon" v-if="fileInfo.ext_icon" :src="fileInfo.ext_icon" />
                <div class="info">
                  <div style="height: 26px">
                    <span class="name"
                      >{{ fileInfo.name }}{{ fileInfo.extension }}</span
                    >
                  </div>

                  <div class="descBox">
                    <span>创建日期：{{ fileInfo.intime }}</span>
                    <span>文件大小：{{ fileInfo.size }}</span>
                    <span class="desc"
                      >本文件由网友：{{
                        fileInfo.username
                      }}
                      自行上传，并不代表本站立场</span
                    >
                    <span class="report" @click="goReport">举报</span>
                  </div>
                </div>
                <!-- <fm-button round size="medium" @click="hCollect">{{ isCollect ? '已收藏' : '收 藏' }}</fm-button> -->
              </div>
              <!-- 会员专享功能 -->
              <div class="dp-use">
                <span class="title">会员专享功能</span>
                <div class="box">
                  <div class="btnTop1" @click="electronFn">
                    <fm-button
                      @click="newFloder"
                      icon="icon-pagea1"
                      type="info"
                      plain
                      round
                      size="medium"
                      >通过PC客户端下载</fm-button
                    >
                  </div>
                  <div class="btnTop1" @click="goVipDownload">
                    <fm-button
                      icon="icon-pagea2"
                      type="info"
                      plain
                      round
                      size="medium"
                      >网页端 极速下载</fm-button
                    >
                  </div>
                  <div class="btnTop1" @click="goRestore">
                    <fm-button icon="icon-pagea3" round size="medium"
                      >转存至云盘</fm-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <OtherStatus
              v-show="fileStatus != 1"
              :status="fileStatus"
            ></OtherStatus>
          </template>
        </el-skeleton>

        <!-- 开通会员 -->
        <!-- <span class="title">开通会员</span> -->
        <VipBenefits style="max-width: 1700px" ref="VipBenefits"></VipBenefits>
        <span class="vipdesc"
          >注：标有*特权需消耗下载额度使用，剩余下载额度不足时，可消耗福利点使用</span
        >
        <!-- 飞猫盘客户端 -->
        <span class="title" v-show="fileStatus == 1">飞猫盘客户端</span>
        <DownloadBox v-show="fileStatus == 1"></DownloadBox>
        <!-- 非会员下载按钮 -->
        <!-- <div
          class="dp-normal-box"
          @click="goDownPageb"
          v-show="fileStatus == 1"
        >
          <img :src="$utils.getPng('downa_icon_down')" />
          <span>非会员下载</span>
        </div> -->
        <fm-button
          style="margin: 62px auto 0"
          v-show="fileStatus == 1"
          @click="goDownPageb"
          icon="icon-pagea4"
          type="info"
          plain
          round
          size="medium"
          >非会员下载</fm-button
        >
      </div>
      <!-- 取件码 -->
      <CheckPucode
        v-show="showCheckPucode == 2"
        @handlePucode="handlePucode"
        :pucode="pucode"
        :adData="adData"
        :auth_logo="auth_logo"
        :auth_name="auth_name"
      >
      </CheckPucode>
      <!-- 拦截弹框 -->
      <!-- type 1：会员  2：转存-->
      <PromoteDialog
        @handleClose="isShowPromote = false"
        :isShow="isShowPromote"
        :msg="promoteMsg"
        :vip_update="vip_update"
        :icon="icon"
        :type="promoteType"
      >
      </PromoteDialog>
      <!-- 消耗弹框 -->
      <!-- <ConsumeBox
        @confirm="confirmConsume"
        :showContent="showContent"
        :isShow="showConsumeBox"
        :consumeInfo="consumeInfo"
        :checkPopupType="checkPopupType"
        :popup_type="popup_type"
        :vipLimitInfo="vipLimitInfo"
        @handleClose="showConsumeBox = false"
      >
      </ConsumeBox> -->
      <ConsumeBox
        @confirm="confirmConsume"
        :showContent="showContent"
        :isShow="showConsumeBox"
        :consumeInfo="consumeInfo"
        @handleClose="showConsumeBox = false"
      >
      </ConsumeBox>
      <!-- 转存成功 -->
      <SuccessBox
        :isShow="showSuccessBox"
        :successBoxMsg="successBoxMsg"
        @handleClose="showSuccessBox = false"
      >
      </SuccessBox>
      <!-- 唤起客户端 -->
      <ClientDownloadBox :isShow="showClientDownloadBox"></ClientDownloadBox>
      <!-- 绑定手机1  实名认证2 验证码3-->
      <BindPhone
        :isShow="isShowBindPhone"
        :type="bindType"
        :time="time"
        @getPhoneCode="getPhoneCode"
        @bindPhone="bindPhone"
        @handleClose="isShowBindPhone = false"
      ></BindPhone>
      <!-- 客户端唤起 -->
      <Electronpop
        :code="$route.path.match(/s(\S*)/)[1].slice(1)"
        :isElectron="isElectron"
        @Visible="isElectron = false"
      ></Electronpop>

      <!-- 3元一天 -->
      <LimitedTimeVip
        ref="showLimitedTime"
        :isShowModel="showLimitedTime"
        :activity_vip_id="activity_vip_id"
        @handleClose="showLimitedTime = false"
        @openSuccess="openSuccess"
      ></LimitedTimeVip>

      <div
        v-if="pay_activities == 1 && !showLimitedTime && !showSuccessfullyModel"
        class="threeBtn"
        @click="threeBtnFn"
      >
        <img :src="$utils.getPng('threeBtn')" alt="" />
        <div class="threeyin"></div>
      </div>

      <!-- 支付弹框 -->
      <PayOrder
        ref="PayOrder"
        :isShow="isShowPayOrder"
        :payData="payData"
        :showBankInfo="showBankInfo"
        :allMember="allMember"
        :superSlideData="superSlideData"
        @clearCoupon="clearCoupon"
        @handleClosePayDia="handleClosePayDia"
        @openSuccess="openSuccess"
      ></PayOrder>
      <!-- 优惠码 -->
      <DiscountCodeBox
        ref="DiscountCodeBox"
        :isShow="showDiscountCode"
        @handleClose="handleCloseDis"
        @checkCoupe="couponValidate"
      ></DiscountCodeBox>
      <SuccessfullyModel
        :payToast="payToast"
        :isShowModel="showSuccessfullyModel"
        @successRefresh="successRefresh"
      ></SuccessfullyModel>
      <Riskverification
        ref="Riskver"
        @handleClose="handleCloseRisk"
        @riskveAgain="riskveAgain"
      ></Riskverification>
    </div>
  </div>
</template>

<script>
import VipBenefits from "@/components/VipBenefits/index";
import DownloadBox from "@/components/DownloadBox/index";
import PromoteDialog from "@/components/Dialog/PromoteDialog";
import ConsumeBox from "@/components/DownPage/ConsumeBox";
import SuccessBox from "@/components/DownPage/SuccessBox";
import ClientDownloadBox from "@/components/DownPage/ClientDownloadBox";
import CheckPucode from "@/components/DownPage/CheckPucode";
import OtherStatus from "@/components/DownPage/OtherStatus";
import BindPhone from "@/components/Cloud/BindPhone";
import Electronpop from "@/components/DownPage/Electronpop";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import LimitedTimeVip from "@/components/DownPage/LimitedTimeVip";
import VIPPAYMIXIN from "@/mixin/vip.vue";
import {
  checkFilePucode,
  getDownFileInfo,
  getNewSecurl,
  activePopupStatAPI,
  turnSaveFile,
} from "@/utils/apiList/downpage";
import { bindPhoneSendCode, bindPhoneAPI } from "@/utils/apiList/account";
import { downloadFile } from "@/utils/apiList/cloud";
import Riskverification from "@/components/Dialog/Riskverification.vue";
export default {
  components: {
    VipBenefits,
    DownloadBox,
    PromoteDialog,
    ConsumeBox,
    SuccessBox,
    ClientDownloadBox,
    CheckPucode,
    OtherStatus,
    BindPhone,
    Electronpop,
    PayOrder,
    LimitedTimeVip,
    DiscountCodeBox,
    SuccessfullyModel,
    Riskverification,
  },
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      skeletonItem: [1, 2, 3],
      getFileInfoGlag: true,
      isShowPromote: false,
      icon: "",
      showUpgradeBox: false,
      showConsumeBox: false,
      showSuccessBox: false,
      showClientDownloadBox: false,
      adData: {},
      auth_logo: "",
      auth_name: "",
      pucode: "", //取件码
      code: "", //文件链接码
      fileInfo: {}, //文件信息
      promoteMsg: "",
      promoteType: 2, //2会员 3转存
      showCheckPucode: -1, //1文件信息 2取件码
      consumeInfo: {
        title: "",
        content: "",
        vip_update: 1, //是否可以升级会员1是0否
      }, //消耗信息
      checkPopupType: 1, //1会员 2 转存
      popup_type: "",
      showContent: 2,
      vipLimitInfo: {},
      fileStatus: 1, //2删除、锁定、不存在 3私有
      isShowBindPhone: false, //
      bindType: 1, //
      timerA: null,
      time: 60,
      msgid: "",
      vip_update: "",
      successBoxMsg: "",
      cLoading: false,
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
      isElectron: false,
      showLimitedTime: false,
      prompt_activities: 0, //三元一天支付弹窗
      activity_vip_id: "1055", //三元一天会员Id
      pay_activities: 0, //三元一天右下角图片点击出现三元一天支付弹窗的按钮
      phone_item: {
        phone: "",
        pcode: "",
      },
      savefileParams: {},
      showRisk: -1, //0 是转存，1下载
      accessid: "",
    };
  },
  watch: {
    showConsumeBox(val) {
      //关闭弹窗清空accessid ，有福利点消耗弹窗 先出现风险验证弹窗在出现福利点消耗 出现福利点再次去请求接口的时候需要保留accessid 所以在福利点消耗弹窗关闭时清空accessid 再次出现风险验证
      if (!val) {
        this.accessid = "";
      }
    },
  },
  computed: {
    isCollect: function () {
      return Number(this.fileInfo.is_collect) === 1;
    },
  },
  created() {
    this.getFileInfoGlag = true;
    this.code = this.$route.params.id;
    if (sessionStorage.getItem("isFromShareList") == '1') {
      sessionStorage.removeItem("isFromShareList");
      this.handlePucode(this.$route.query.pucode);
    } else {
      this.checkPucode();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // if (from.name == "fmDown") {
      // }else{
      //    this.pucode = this.$route.query.pucode
      //    this.getDownFileInfo()
      // }
    });
  },
  methods: {
    async hCollect() {
      if (this.cLoading) return;
      this.cLoading = true;
      let act, id;
      if (this.isCollect) {
        act = "0";
        id = this.fileInfo.collect_id;
      } else {
        act = "1";
        id = this.fileInfo.id;
      }
      let res = await handleCollect({
        action: act,
        type: "file",
        id,
      });
      if (res.state) {
        this.fileInfo.is_collect = act;
      } else if (res.msg) {
        this.$toast(res.msg, 2);
      }
      this.cLoading = false;
    },
    //检测取件码
    checkPucode() {
      checkFilePucode({ code: this.code }).then((res) => {
        if (res.status == "1") {
          if (res.data.if_check_pucode == "1") {
            this.showCheckPucode = 2;
            this.adData = res.data.ad_list[0];
            this.auth_name = res.data.auth_name;
            this.auth_logo = res.data.auth_logo;
            //是否自动填充
            if (this.$route.query.pucode) {
              this.pucode = this.$route.query.pucode;
            }
          } else {
            this.showCheckPucode = 1;
            this.getDownFileInfo();
          }
        } else {
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
          this.$toast(res.msg, 2);
        }
      });
    },
    handlePucode(pucode) {
      this.pucode = pucode;
      this.getDownFileInfo();
    },
    //获取文件信息
    getDownFileInfo() {
      this.getFileInfoGlag = true;
      this.showLimitedTimeFun(false);
      getDownFileInfo({ code: this.code, pucode: this.pucode }).then((res) => {
        if (res.status == "1") {
          this.showCheckPucode = 1;
          this.fileInfo = res.data;
          this.prompt_activities = res.data.prompt_activities;
          this.activity_vip_id = res.data.activity_vip_id;
          this.pay_activities = res.data.pay_activities;
          this.getFileInfoGlag = false;
          if (this.prompt_activities == 1) {
            this.showLimitedTimeFun(true);
            activePopupStatAPI({})
              .then((result) => {})
              .catch((err) => {}); //该接口只有在进下载a页面才需要给后端计数
          }
        } else if (res.status == "61001") {
          this.$toast(res.msg, 2);
        } else if (res.status == "60001") {
          //文件不存在或者被删除
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
        } else if (res.status == "50006") {
          //锁定
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
        } else if (res.status == "60004") {
          //私有文件拒绝访问
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 3;
        } else {
          this.showCheckPucode = 1;
          this.$toast(res.msg, 2);
        }
      });
    },
    goReport() {
      window.open("https://jinshuju.net/f/fKYT3q");
    },
    //普通下载 跳转去下载b
    goDownPageb() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (!this.fileInfo.has_down) {
        this.$toast("尊贵的会员，请点击“网页端 极速下载”进行高速下载", 4);
        return;
      }
      getNewSecurl({ code: this.code }).then((res) => {
        if (res.status == "1") {
          this.$router.push({
            path: "/fmDown/" + res.data.url,
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //去下载
    goVipDownload(e, accessid = "") {
      this.checkPopupType=1
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      this.accessid = accessid;
      if (e) {
        e = e || window.event;
        this.ms = e.clientX + "*" + e.clientY;
        this.sc = window.screen.width + "*" + window.screen.height;
      }

      this.svipDown();
    },
    //会员下载
    svipDown: _.debounce(
          function (confirm = 0,captcha_verify) {
               return new Promise((resolve) => {
                  let params = {
                    type: 3, // 1:列表下载，2:福利点下载，3:vip下载，4：重试
                    code: this.code,
                    confirm: confirm,
                    ms: this.ms,
                    sc: this.sc,
                    captcha_verify: captcha_verify || "",
                    accessid: this.accessid,
                  };
                  downloadFile(params).then((res) => {
                    if (res.status == "1") {
                      resolve({
                        result: true,
                        validate: true,
                      });
                      let fileName = `${res.data.file.name}${res.data.file.extension}`;
                      let obj = {
                        fid: res.data.file.id,
                        url: res.data.url,
                        fileName: fileName,
                        fileSize: res.data.file.size, //文件大小
                        fileType: res.data.file.extension,
                        ext_icon: res.data.file.ext_icon,
                        title: "极速下载中",
                        down_id: res.data.down_id, //下载id，重试时需要用到
                      };
                      this.$store.commit("setAttr", { name: "downloadFile", val: obj });
                      this.$router.push({
                        path: "/tNewDown/" + res.data.jump_url,
                      });
                    } else if (res.status == "51001") {
                      resolve({
                        result: true,
                        validate: true,
                      });
                      //额度用尽
                      this.showContent = 1;
                      this.consumeInfo = res.data;
                      this.showConsumeBox = true;
                    } else if (res.status == "42003") {
                      //三元一天弹
                      resolve({
                        result: true,
                        validate: true,
                      });
                      this.showLimitedTimeFun(true);
                      this.$toast(res.msg, 4);
                    } else if (res.status == "63100") {
                      resolve({
                        result: true,
                        validate: true,
                      });
                      this.promoteMsg = res.msg;
                      this.promoteType = 2;
                      this.vip_update = "1";
                      this.icon = "icon_diamond";
                      this.isShowPromote = true;
                    } else if (res.status == "90001") {
                      resolve({
                        result: false,
                        validate: false,
                      });
                      this.$toast(res.msg, 2);
                      this.$AliCode.handleOpen(this.svipDown);
                    } else if (res.status == "90101") {
                      resolve({
                        result: true,
                        validate: true,
                      });
                      this.showRisk = 1;
                      // this.$initRiskve.open();
                      this.$refs.Riskver.open();
                    }else if (res.status == "68001" || res.status == "68002") {
                      resolve({
                        result: true,
                        validate: true,
                      });
                      this.showContent = 3;
                      this.consumeInfo = res.data;
                      this.showConsumeBox = true;
                    } else {
                      //60001无法下载，文件不存在或已被删除 //50006文件已被锁定,暂时无法访问
                      resolve({
                        result: true,
                        validate: false,
                      });
                      this.$toast(res.msg, 2);
                    }
                  });
                });
          },
          1000,
          {
              leading: true, //指定在延迟开始前调用
              trailing: false, //指定在延迟结束后调用
          }
      ),
    //去转存
    goRestore(e) {
      this.checkPopupType=2
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (e) {
        e = e || window.event;
        this.ms = e.clientX + "*" + e.clientY;
        this.sc = window.screen.width + "*" + window.screen.height;
      }
      this.savefile(0);
    },
    //文件转存
    savefile(confirm, accessid = "") {
      this.savefileParams = confirm;

      this.accessid = accessid;

      this.savefileALY();
    },
    savefileALY(captcha_verify) {
      return new Promise((resolve) => {
        let confirm = this.savefileParams;
        let params = {
          code: this.code,
          confirm: confirm,
          captcha_verify: captcha_verify || "",
          accessid: this.accessid,
          ms: this.ms,
          sc: this.sc,
          // folder_id:0 //扩展参数允许转存的目录，目前不用传
        };
        turnSaveFile(params).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.showConsumeBox = false;
            this.showSuccessBox = true;
            this.successBoxMsg = "转存成功";
            // this.getDownFileInfo();
          } else if (res.status == "68001" || res.status == "68002") {
            resolve({
              result: true,
              validate: true,
            });
            this.showContent = 3;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "81001") {
            //福利点不足
            resolve({
              result: true,
              validate: true,
            });
            this.showContent = 2;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "40001") {
            //未绑定手机号
            resolve({
              result: true,
              validate: true,
            });
            this.bindType = 1;
            this.isShowBindPhone = true;
          } else if (res.status == "52001") {
            //空间已满
            resolve({
              result: true,
              validate: true,
            });
            this.icon = "icon_space";
            this.promoteMsg = res.data.content;
            this.vip_update = res.data.vip_update;
            this.promoteType = 3;
            this.isShowPromote = true;
          } else if (res.status == "62001") {
            //已转存过 (62001)
            resolve({
              result: true,
              validate: true,
            });
            this.showSuccessBox = true;
            this.successBoxMsg = res.msg;
          } else if (res.status == "42003") {
            //一元三天
            resolve({
              result: true,
              validate: true,
            });
            this.showLimitedTimeFun(true);
            this.$toast(res.msg, 4);
          } else if (res.status == "90001") {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.savefileALY);
          } else if (res.status == "90101") {
            resolve({
              result: true,
              validate: true,
            });
            // this.$initRiskve.open();
            this.showRisk = 0;
            this.$refs.Riskver.open();
          } else {
            resolve({
              result: true,
              validate: false,
            });
            //60013 文件已过期" 50006 锁定
            this.$toast(res.msg, 2);
          }
        });
      });
    },

    //确认消耗
    confirmConsume() {
        this.checkPopupType==1 ? this.svipDown(1,this.accessid) :  this.savefile(1,this.accessid);
    },
    //发送验证码
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
              this.$refs.bindPhoneCom?.onFocus();
            }, 1000);
          } else if (res.status == "90001") {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(phone)) {
          this.$toast("请正确输入手机号", 3);
          return true
      }

      this.getPhoneAly();
    },

    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //去登录页
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
    electronFn() {
      this.isElectron = true;
    },
    refreshPage() {
      this.$store.commit("getuserVuex");
      this.getDownFileInfo();
    },
    showLimitedTimeFun(newval) {
      this.showLimitedTime = newval;
    },
    gopageA() {},
    threeBtnFn() {
      this.showLimitedTimeFun(true);
    },
    riskveAgain(accessid) {
      if (this.showRisk == 1) {
        this.goVipDownload("", accessid);
      } else {
        this.savefile(this.savefileParams, accessid);
      }
    },
    handleCloseRisk() {
      this.showConsumeBox = false;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
