<template>
  <div class="otherStatus" :class="{'mt':status==4}">
    <span class="title">文件状态</span>
    <div class="box">
        <div class="desc">
            <svg-btn :icon-class="status==3 ? 'filestatus-icon2':'filestatus-icon1'"/>
            <span v-if="status==4">
                该文件已过期，<a @click="goVip">开通会员</a> 立即极速下载
            </span>
            <span v-else>{{ status==2 ? '此文件已被系统审核过滤或被上传者删除，无法访问':'私有文件暂时无法访问' }}</span>
        </div>
        <div class="gohelp" v-show="status==2">
            <span>参考资料：</span><a @click="goHelp(98)">《飞猫盘违规内容处置机制》</a>
            <span class="first">如有疑问可访问：</span><a @click="goHelp(0)">《飞猫盘帮助中心》</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        status:{
            type:Number,
            default:2
        }
    },
    methods:{
        goVip(){
            window.open('/vip')
        },
        goHelp(type){
            if(type==0){
               window.open('/help')
            }else{
                 window.open(`${this.$glb.fmUrl}/askdetail/98`);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .otherStatus{
        .title{
            margin-top: 0;
        }
        .box{
            margin-top:20px ;
            height: 160px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include useTheme{
                border:getVar('border-color-e5e5e5')
            }
            .desc{
                display: flex;
                align-items: center;
                span{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    a{
                        color: #F67942;
                        // text-decoration: underline;
                        border-bottom: 2px solid #F67942;
                        cursor: pointer;
                    }
                }
                .svg-icon{
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                }
            }
            .gohelp{
                margin-top: 22px;
                span{
                    @include useTheme{
                        color:getVar('text-color1-8a8b92')
                    }
                }
                a{
                    color: #F67942;
                    cursor: pointer;
                }
                .first{
                    margin-left: 58px;
                }
            }
        }
    }
    .mt{
        margin-bottom: 40px;
    }
</style>