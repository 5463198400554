<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div class="app-bottom">
        <div class="submitQuestions">
          <div class="top">
            <div class="leftSilder">
              <div class="type">分类：</div>
              <el-cascader
                popper-class="askItemBox1"
                :key="optionsKey"
                :options="options"
                :props="optionProps"
                v-model="classData"
                @change="classChange"
                clearable
              ></el-cascader>
            </div>
            <div class="input">
              <el-input
                class="input"
                :class="!name?'input2':''"
                placeholder="请输入标题(30字以内)"
                v-model="name"
              >
                <template slot="prepend" v-if="!name">
                  <svg-btn icon-class="icon-interactive-butto"></svg-btn
                ></template>
              </el-input>
              <fm-button class="btnSend" type="primary" round @click="submit"
                >提 交</fm-button
              >
            </div>
          </div>
          <div class="editBox" style="height: calc(100vh - 140px)">
            <div ref="editor" class="editBox-item"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import { getCspCategory, commitProductionOrder } from "@/utils/apiList/help";
import TheNewUpload from "@/mixin/TheNewUpload.vue";
export default {
  mixins: [TheNewUpload],
  components: {
    NavTop,
  },
  data() {
    return {
      editor: null,
      editorContent: "", // 文件描述 富文本
      name: "",
      article_pic: this.$glb.fileServer + "/upload/articlePic",
      img_base_url:this.$glb.avaterPreview,
      optionsKey: 1, // 联级选择的key
      optionsData: [],
      options: [],
      // 规定联级菜单格式
      optionProps: {
        value: "id",
        label: "name",
        children: "child",
      },
      classData: [],
      top_tid: "",
      sec_tid: "",
      newUpType: 1,
    };
  },
  created() {
    this.top_tid = this.$route.query.top_tid;
    this.sec_tid = this.$route.query.sec_tid;
    this.classData = [+this.top_tid, +this.sec_tid];
    this.getCspCategoryFn();
  },
  mounted() {
    this.editFun();
  },

  methods: {
    editFun() {
      // 富文本----S
      var E = window.wangEditor;
      var editor = new E(this.$refs.editor);
      editor.config.uploadImgServer = this.article_pic;
      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        let _date = new Date().getTime();
        for (let i = 0; i < resultFiles.length; i++) {
          let _file = {
            file: resultFiles[i],
          };
          _file.file.uid = _date + i;
          let result = await this.asyncMyUploadImg(_file);
          if (result.action === "success") {
            insertImgFn(this.img_base_url + result.res.data.path);
          }
        }
      };
      editor.config.cdnUrl = "";
      editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "strikeThrough",
        "foreColor",
        "link",
        "list",
        "justify",
        "quote",
        "image",
        "undo",
        "redo",
      ];
      // 取消自动 focus
      // editor.config.focus = false
      editor.config.zIndex = 100;
      editor.config.showLinkImg = false;
      // 将图片大小限制为 3M
      editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
      editor.config.uploadImgMaxLength = 5;
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteTextHandle = function (content) {
        if (content === "" && !content) return "";
        content = content.replace(/<br\/?>/gi, "fm#br#fm");
        content = content.replace(/<p>/gi, "fm#p1#fm");
        content = content.replace(/<p\/>/gi, "fm#p2#fm");
        // content = content.replace(/<.*?>/ig, '')
        content = content.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
        content = content.replace(/<style>[\s\S]*?<\/style>/gi, "");
        content = content.replace(/<\/?[^>]*>/g, "");
        content = content.replace(/[ | ]*\n/g, "\n");
        content = content.replace(/&nbsp;/gi, "");

        content = content.replace(/fm#p1#fm/gi, "<p>");
        content = content.replace(/fm#p2#fm/gi, "<p/>");
        content = content.replace(/fm#br#fm/gi, "<br/>");
        return content;
        // return content.replace(/<.*?>/ig, '')
      };
      editor.config.uploadImgHooks = {
        // success: function (xhr, editor, result) {},
      };
      editor.config.onchange = (html) => {
        this.editorContent = html;
      };
      editor.create();
      this.editor = editor;
      // 富文本----E
    },
    getCspCategoryFn() {
      getCspCategory().then((res) => {
        if (res.status == 1) {
          ++this.optionsKey;
          this.optionsData = res.data.top_type.filter((item) => {
            return item.id !== "5";
          });
          this.options = this.optionsData.filter((item) => {
            return item.id;
          });
        } else {
          this.$toast(res.msg);
        }
      });
    },
    submit() {
      if (this.name == "" || this.name.length>30) {
        this.$toast("标题不能为空，长度30字以内", 2);
        return;
      }
      if (this.editorContent == "") {
        this.$toast("内容不能为空", 2);
        return;
      }
      if (!this.top_tid || !this.sec_tid) {
        this.$toast("请选择分类后再提交", 2);
        return;
      }
      commitProductionOrder({
        top_tid: this.top_tid,
        sec_tid: this.sec_tid,
        title: this.name,
        content: this.editorContent,
      }).then((res) => {
        if (res.status == 1) {
          this.$toast(res.msg, 1);
          setTimeout(() => {
            window.location.href = "/help";
          }, 500);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 选择投稿分类
    classChange(value) {
      this.classData = value;
      this.top_tid = value[0];
      this.sec_tid = value[1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
