<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="bindPhoneDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('web4/icon-circle-fill')" />
          <span>{{ type == 0 ? "设置密码" : "修改密码" }}</span>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="tit" style="margin-bottom: 16px">
          {{
            type == 0
              ? "请输入密码，最少设置 6 位数字或字母"
              : "请输入新密码，最少设置 6 位数字或字母"
          }}
        </div>
        <div class="dc12">
          <el-input
            v-model="password0"
            :placeholder="type == 0 ? '请输入密码' : '新密码'"
            clearable
          >
          </el-input>
          <el-input
            v-model="password1"
            :placeholder="type == 0 ? '请再次输入密码' : '再次输入新密码'"
            style="margin-top: 12px"
            clearable
          >
          </el-input>
        </div>
      </div>

      <div class="d-footer" style="margin-top: 24px">
        <fm-button
          @click="confirm()"
          type="primary"
          size="small"
          :disabled="password0.length == 0 || password1.length == 0"
          >确 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { setPasswordAPI } from "@/utils/apiList/account";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      password0: "",
      password1: "",
    };
  },
  methods: {
    handleClose() {
      this.password0 = "";
      this.password1 = "";
      this.$emit("handleClose");
    },

    confirm() {
      if (this.password0.length == 0 || this.password1.length == 0) {
        return;
      }

      if (this.password0.length < 6 || this.password1.length < 6) {
        this.$toast("密码不正确，长度请在6-20之间", 2);
        return;
      }
      if (!/^[a-zA-Z0-9]{6,}$/.test(this.password0)) {
        this.$toast("请使用数字或字母设置密码", 2);
        return;
      } else if (!/^[a-zA-Z0-9]{6,}$/.test(this.password1)) {
        this.$toast("请使用数字或字母设置密码", 2);
        return;
      } else if (this.password0 !== this.password1) {
        this.$toast("密码不一致，请重试", 2);
        return;
      }
      setPasswordAPI({
        password: this.password0,
        repeat_password: this.password1,
      })
        .then((res) => {
          if (res.status == 1) {
            this.$store.commit("getuserVuex");
            this.handleClose();
            this.$toast(res.msg, 1);
            if (this.type != 0) {
              setTimeout(() => {
                this.$parent.loginOut();
              }, 500);
            }
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
