<template>
  <div class="welfare-box">
    <TabList curId="1"></TabList>
    <div class="welfare-content">
      <div class="welfare-max">
        <div class="titMax">
          <span class="title">
            <div class="text">赚福利点</div>
            <div class="welfarenub" @click="todetail()">
              <div class="species-box">
                <img
                  class="species"
                  :src="$utils.getPng('species_fu')"
                  alt=""
                />
                <div class="nbm">
                  <div class="shuzi">{{ point_int || $store.state.userInfo.point_int }}</div>
                  <div class="text">福利点</div>
                </div>
              </div>
              <svg-btn class="le" icon-class="icon-direction-right"></svg-btn>
            </div>
          </span>
          <div class="rule-box">
            <font class="desc">福利点可免费下载文件或兑换会员特权</font>
            <div class="rule" @click="showRulesFn()">
              <svg-btn
                class="rule_fu"
                icon-class="icon_question_circle"
              ></svg-btn>
            </div>
          </div>
        </div>
        <!-- 签到 -->
        <div class="top1">
          <div class="one">
            <div class="onetop">
              <div class="fuli2">打卡签到</div>
            </div>
            <div v-if="!isFirst" class="sign-box">
              <div
                class="sign-button pointer"
                :class="!isSigned ? '' : 'sign-button2'"
                @click="onSignFn"
              >
                {{ !isSigned ? "立即签到" : "已签到" }}
              </div>
              <div class="sign-to-app">
                {{
                  !isSigned
                    ? "完成打卡签到可获得「福利点」"
                    : "请前往飞猫盘APP-福利社领取福利点"
                }}
              </div>
            </div>
          </div>
          <div class="one">
            <div class="onetop">
              <div class="fuli2">超级签到奖励</div>
            </div>
            <div class="qiandao-box">
              <WebSign
                ref="WebSign"
                class="WebSign"
                @video_item="video_urlFn"
                @onSign="isSignedFn"
              ></WebSign>
            </div>
          </div>
        </div>
        <!-- 日常任务 -->
        <div class="top2">
          <div class="furight">
            <div class="fuli2">日常任务</div>
            <div class="list" v-if="dailyTaskList">
              <div
                class="item"
                v-for="(item, index) in dailyTaskList"
                :key="index"
              >
                <img class="imga" :src="item.icon" alt="" />
                <div class="itemcen">
                  <div class="itemtit1">
                    {{ item.name }}
                    <div class="desc">
                      <img
                        class="species"
                        :src="$utils.getPng('species_fu')"
                        alt=""
                      />
                      {{ item.point }}
                    </div>
                  </div>
                  <div class="itemtit2">{{ item.desc }}</div>
                </div>
                <el-button
                  class="btn"
                  @click="receiveTask(item)"
                  :class="item.status == 2 ? 'btnwait' : 'btnyes'"
                >
                  {{ item.status_name }}</el-button
                >
              </div>
            </div>
          </div>
          <div class="furight">
            <div class="fuli2">新手任务</div>
            <div class="list" v-if="newbieTasks">
              <div
                class="item"
                v-for="(item, index) in newbieTasks"
                :key="index"
              >
                <img class="imga" :src="item.icon" alt="" />
                <div class="itemcen">
                  <div class="itemtit1">
                    {{ item.name }}
                    <div class="desc">
                      <img
                        class="species"
                        :src="$utils.getPng('species_fu')"
                        alt=""
                      />
                      {{ item.point }}
                    </div>
                  </div>
                  <div class="itemtit2">{{ item.desc }}</div>
                </div>
                <el-button
                  class="btn"
                  @click="receiveTask(item)"
                  :class="item.status == 2 ? 'btnwait' : 'btnyes'"
                >
                  {{ item.status_name }}</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <span class="exchange-title">花福利点</span>
        <div class="exchangefulibox">
          <div
            class="ecfbone"
            v-for="(item, index) in exchangeList"
            :key="index"
          >
            <img
              class="imgl"
              :src="item.icon + '_' + $store.state.theme + '.png'"
            />
            <div
              v-if="index < 2"
              class="shenyu"
              :style="'backgroundImage:url(' + $utils.getPng('titlenub') + ')'"
            >
              <div v-if="item.residueNums == 0" class="yi">今日已兑完</div>
              <div v-else class="yi">今日剩余{{ item.residueNums }}份</div>
            </div>
            <div class="box" v-if="index == 0 || index == 1">
              <div class="ecoright">
                <div class="ect1">{{ item.name }}</div>
                <div class="ect2">
                  <div class="point">{{ item.point }}</div>
                  <div class="text">福利点</div>
                </div>
              </div>
              <el-button
                v-if="item"
                class="btn"
                :class="item.status == 0 ? 'btnwait' : 'btnyes'"
                :disabled="item.status == 0"
                @click="handleExchange(item)"
              >
                {{ item.status == 0 ? "已兑完" : "兑换" }}</el-button
              >
            </div>
            <div class="box" v-else>
              <div class="ecoright">
                <div class="ect1">{{ item.name }}</div>
                <div class="ect2" v-if="index == 2">
                  <div class="point">{{ item.point }}</div>
                  <div class="text">福利点可用</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 福利活动规则 -->
        <WelfareActiviteRules
          title="福利活动规则"
          :isShow="showRules"
          :rulesList="rulesList"
          @handleClose="showRules = false"
        />

        <!-- goapp 视频 -->
        <Signinpop
          ref="refsigninpop"
          :type="signPopType"
          :isShow="signinpopshow"
          :video_url="video_item.video_url"
          :video_img="video_item.video_img"
          :addnum="addnum"
          @handleClose="onSignClose"
        ></Signinpop>

        <!-- 福利点明细 -->
        <WelfarePointDetail
          :isShow="showWelfarePointDetail"
          @handleClose="pointClose"
        ></WelfarePointDetail>

        <BindPhone
          :isShow="isShowBindPhone"
          :type="bindType"
          :time="time"
          @getPhoneCode="getPhoneCode"
          @bindPhone="bindPhone"
          @confirmNameAuth="realNameAuth"
          @handleClose="isShowBindPhone = false"
        ></BindPhone>

        <TitleDialog
          :is-show="excShow"
          :title="excObj.title"
          :subTitle="excObj.subTitle"
          :text="excObj.text"
          @handleClose="onExcClose"
          @handleConfirm="onExcConfirm"
        >
        </TitleDialog>
      </div>
    </div>
  </div>
</template>

<script>
import TitleDialog from "@/components/Dialog/TitleDialog.vue";
import TabList from "../TabList/index.vue";
import WelfareActiviteRules from "@/components/Welfare/WelfareActiviteRules.vue";
import Signinpop from "@/components/Welfare/Signinpop.vue";
import WelfarePointDetail from "@/components/Welfare/WelfarePointDetail.vue";
import {
  userSign,
  welfareTaskInfo,
  welfareRules,
  welfareExchangeReward,
} from "@/utils/apiList/welfare";
import WebSign from "./components/webSign.vue";
import BindPhone from "@/components/Cloud/BindPhone";
import {
  bindPhoneAPI,
  bindPhoneSendCode,
  realNameAuth,
} from "@/utils/apiList/account";
export default {
  components: {
    TabList,
    WelfareActiviteRules,
    Signinpop,
    WelfarePointDetail,
    WebSign,
    BindPhone,
    TitleDialog,
  },
  data() {
    return {
      excLoading: false,
      excShow: false,
      excObj: {
        title: "提示",
        subTitle: "",
        text: "",
        tid: "",
      },
      msgid: "",
      timerA: null,
      time: 60,
      bindType: 1,
      isShowBindPhone: false,
      isSigned: false,
      signPopType: false,
      showRules: false,
      rulesList: [],
      video_item: {
        video_url: "",
        video_img: "",
      },
      signinpopshow: false,
      addnum: "0",
      showWelfarePointDetail: false,
      dailyTaskList: [],
      newbieTasks: [],
      exchangeList: [],
      phone_item: {
        phone: "",
        pcode: "",
      },
      point_int: '',
        isFirst: true
    };
  },
  mounted() {
    this.getTaskList();

    this.$store.commit("setAttr", {
      name: "bindPhoneCallback",
      val: this.getTaskList,
    });
  },
  beforeDestroy() {
    this.$store.commit("setAttr", {
      name: "bindPhoneCallback",
      val: null,
    });
  },
  methods: {
    isSignedFn(e) {
        this.isFirst = false
      this.isSigned = e;
    },
    video_urlFn(item) {
      this.video_item = item;
      this.addnum = item.day_point;
    },
    onSignFn() {
      if (!this.isSigned) {
        this.signInRewardV2();
      } else {
        this.$toast("您今天已经签到过了！", 2);
      }
    },
    onSignClose() {
      this.signinpopshow = false;
    },
    async getRules() {
      try {
        let res = await welfareRules();
        if (res.status == "1") {
          this.rulesList = res.data.list;
        }
      } catch (e) {}
    },
    signInRewardV2() {
      userSign({}).then(async (res) => {
        if (res.status == "1") {
          this.signinpopshow = true;
          this.signPopType = false;
          this.$refs.refsigninpop.videoPlay();
          this.addnum = res.data.add;
          this.$refs.WebSign.signInRewardBar();
        } else if (res.status == 40001) {
          this.bindType = 1;
          this.isShowBindPhone = true;
        } else {
          this.$toast(res.msg, 2);
          this.$refs.WebSign.signInRewardBar();
        }
        this.getTaskList();
      });
    },
    getTaskList() {
      welfareTaskInfo().then((res) => {
        if (res.status == "1") {
          this.dailyTaskList = res.data.daily_tasks || [];
          this.newbieTasks = res.data.newbie_tasks || [];
          this.exchangeList = res.data.exchange_list || [];
          this.point_int = res.data.point || '';
        }
      });
    },
    showRulesFn() {
      this.showRules = true;

      this.getRules();
    },
    todetail() {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      this.showWelfarePointDetail = true;
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
                  result: false,
                  validate: false
                })
              this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //发送验证码
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      this.getPhoneAly();
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
          this.getTaskList();
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    pointClose() {
      this.showWelfarePointDetail = false;
    },
    receiveTask(item) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      if (item.status_name == "已完成") {
        this.signinpopshow = true;
        this.signPopType = true;
        this.$refs.refsigninpop.videoPlay();
        return;
      }
      if (item.type == "0") {
      } else if (item.type == "1") {
        window.open(this.$glb.Jmdyh, "_blank");
      } else if (item.type == "2") {
        window.open("/drive");
      } else if (item.type == "3") {
        if (item.name == "绑定手机号") {
          this.$router.push({
            name: "account",
            query: { isNeedBind: true },
          });
        } else {
          this.$router.push({ name: "account" });
        }
      } else if (item.type == "4") {
        window.open(`${this.$glb.upJmdyh}/pub`, "_blank");
      } else if (item.type == "5") {
        window.open("/jx/" + item.todo);
      } else if (item.type == "6") {
        window.open("/jmcloud");
      } else if (item.type == "7") {
        window.open(this.$glb.goJMZB + "/task", "_blank");
      } else if (item.type == "8") {
        this.signinpopshow = true;
        this.signPopType = true;
        this.$refs.refsigninpop.videoPlay();
      } else {
        window.open(this.$glb.Jmdyh, "_blank");
      }
    },
    handleExchange(item) {
      if (item.status == 0) {
        return false;
      }
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return false;
      }

      this.excObj.subTitle = item.title;
      this.excObj.text = item.desc;
      this.excObj.tid = item.tid;
      this.excShow = true;
    },
    onExcClose() {
      this.excShow = false;
    },
    async onExcConfirm() {
      this.excShow = false;

      if (this.excObj.tid) {
        if (this.excLoading) return false;
        this.excLoading = true;
        let tid = this.excObj.tid;
        try {
          let res = await welfareExchangeReward({ tid: tid, edition: 1 });
          if (res.status) {
            if (res.status == "1") {
              this.$toast(res.msg, 1);
              this.$store.commit("getuserVuex");
            } else if (res.status == "40001") {
              this.$toast(res.msg, 4);
            } else {
              this.$toast(res.msg, 2);
            }
            this.getTaskList();
          }
        } catch (e) {}
        this.excLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
