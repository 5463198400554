<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div class="app-bottom" v-loading="loading">
        <div class="userloginOutBox" v-if="!isDel">
          <div class="tit">账号注销申请表</div>
          <div class="tit1">注销申请通过后，将在7个工作日内完成全部注销</div>
          <div class="form">
            <div class="userName">
              <div class="label">注销账号</div>
              <div class="labelInfo">{{ $store.state.userInfo.username }}</div>
            </div>
            <div class="userName">
              <div class="label">手机号</div>
              <div class="labelInfo">
                {{ $store.state.userInfo.pcode
                }}{{ $store.state.userInfo.phone }}
              </div>
              <fm-button
                @click="time == '60' ? getSendCode() : ''"
                :disabled="time != '60'"
                size="medium"
                style="width: 108px"
                >{{
                  time == "60" ? "获取验证码" : "已发送(" + time + "s)"
                }}</fm-button
              >
            </div>
            <div class="item">
              <div class="leftLabel">
                <span class="span1">验证码</span><span class="span2">*</span>
              </div>
              <div class="input">
                <el-input v-model="ucode" placeholder="输入验证码">
                  <svg-btn
                    v-show="ucode != ''"
                    @click="ucode = ''"
                    slot="suffix"
                    icon-class="icon-ipt-clear"
                  ></svg-btn>
                </el-input>
                <div class="err" v-if="isErr">请输入6位验证码</div>
              </div>
            </div>
            <div class="item item1">
              <div class="leftLabel" style="margin-top: 8px">
                <span class="span1">注销原因</span><span class="span2">*</span>
              </div>
              <div class="reasonBox">
                <el-input
                  v-model="reason"
                  type="textarea"
                  :autosize="{ minRows: 8, maxRows: 11 }"
                  maxlength="500"
                  show-word-limit
                  placeholder="输入注销原因"
                >
                </el-input>
              </div>
            </div>
            <div class="item">
              <div class="leftLabel"></div>
              <div class="reasonBox">
                <div
                  class="icon-checks-box pointer"
                  @click="give_money = give_money == 1 ? 0 : 1"
                >
                  <svg-btn
                    class="icon_checks"
                    :icon-class="
                      give_money == 1
                        ? 'icon-file-check'
                        : 'icon-file-uncheck-' + $store.state.theme
                    "
                  ></svg-btn>
                </div>
                <div class="checklabel">
                  本人保证此账号归属本人所有，且自愿放弃本账号在飞猫、橘猫旗下产品的所有数据（包括但不限于网盘文件、账户数据、会员特权、众包币、众包余额等）且已知悉并认可账号一经注销无法找回的客观事实。
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <fm-button
              :disabled="ucode == '' || reason == ''"
              @click="ucode == '' || reason == '' ? '' : accountDelReply()"
              style="width: 240px; height: 40px"
              type="primary"
              round
              size="medium"
              >提 交</fm-button
            >
          </div>
        </div>
        <div v-else class="userloginOutBox">
          <div class="del_detail">
            <img :src="$utils.getPng('web4/down1_abnormalFile')" />
            <span class="content">{{ detail.title }}</span>
            <span class="tips">{{ detail.label }}</span>
            <fm-button round size="medium" @click="showCancelApply = true"
              >撤 销</fm-button
            >
          </div>
        </div>
      </div>
      <CancelApply
        :isShow="showCancelApply"
        @handleClose="showCancelApply = false"
        @handleConfirm="handleConfirm"
      ></CancelApply>
    </div>
  </div>
</template>

<script>
import CancelApply from "@/components/Dialog/CancelApply";
import {
  userDelSendCode,
  accountDelReply,
  accountDelDetail,
  accountDelCancle,
} from "@/utils/apiList/help";
import NavTop from "@/components/NavTop";
export default {
  components: {
    NavTop,
    CancelApply,
  },
  data() {
    return {
      ucode: "",
      reason: "",
      msgid: "",
      isErr: false,
      timerA: null,
      time: 60,
      give_money: 0,
      detail: {},
      isDel: "",
      loading: true,
      showCancelApply: false,
    };
  },
  created() {
    this.accountDelDetail();
  },
  watch: {
    ucode(newval) {
      newval.length > 6 ? (this.isErr = true) : (this.isErr = false);
    },
  },
  methods: {
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    //发送短信验证码
    getSendCode(captcha_verify) {
      return new Promise((resolve) => {
        userDelSendCode({ captcha_verify: captcha_verify || "" }).then(
          (res) => {
            if (res.status == "1") {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 1);
              this.msgid = res.data.msgid;
              this.timerA = setInterval(() => {
                this.minute();
              }, 1000);
            } else if (res.status == 90001) {
              resolve({
                result: false,
                validate: false,
              });
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.getSendCode);
            } else {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 2);
            }
          }
        );
      });
    },
    //提交
    accountDelReply() {
      if (this.give_money == 0) {
        this.$toast("请勾选注销条款", 2);
        return;
      }
      let params = {
        ucode: this.ucode,
        reason: this.reason,
        msgid: this.msgid,
        give_money: this.give_money,
      };
      accountDelReply(params).then((res) => {
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.$router.push({
            path: "/help",
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //申请详情
    accountDelDetail() {
      accountDelDetail().then((res) => {
        if (res.status == "1") {
          if (res.data.id == "") {
            this.isDel = false;
          } else {
            this.isDel = true;
            this.detail = res.data;
          }
          setTimeout(() => {
            this.loading = false;
          }, 200);
        }else if(res.status=='40001'){
          this.$router.push({
            path: "/help",
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //撤销注销
    handleConfirm() {
      accountDelCancle({ id: this.detail.id }).then((res) => {
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.$router.push({
            path: "/help",
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
