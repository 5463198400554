<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/Dialog/SafeDialog.vue
 * @Description: 安全验证
-->

<template>
  <div class="EquipmentDialog">
    <el-dialog
      :visible.sync="equipmentFlag2"
      :before-close="close"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <img
              class="icon"
              :src="$utils.getPng('web4/icon_i')"
              alt=""
              srcset=""
            />
            <div class="tit1">安全提示</div>
          </div>
        </div>
        <div class="dialogCenter">
          <div class="titBig" style="margin-bottom: 23px">
            请确认授权登录当前浏览器
          </div>
          <div class="tit1">本次登录地点：{{ dataArr.current_province }}</div>
          <div class="tit1">上次登录地点：{{ dataArr.last_province }}</div>
          <div class="bottom">
            <fm-button
              class="btn"
              @click="onConfirm"
              type="primary"
              size="small"
              >确 认</fm-button
            >
            <!-- <fm-button
              style="margin-left: 12px"
              @click="close"
              type="info"
              plain
              size="small"
              >取 消</fm-button
            > -->
            <div class="btn_no" style="margin-left: 12px" @click="close()">
              取 消
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      equipmentFlag2: false,
      dataArr: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    onConfirm() {
      this.$emit("onConfirm");
    },
    show(arr) {
      this.dataArr = arr;
      this.equipmentFlag2 = true;
    },
    close() {
      this.equipmentFlag2 = false;
      this.dataArr = {};
      this.$emit("onClose");
    },
    close2() {
      this.equipmentFlag2 = false;
      this.dataArr = {};
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  background: #fff !important;
  width: 480px;
  min-height: 208px;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  .el-dialog__body {
    padding: 0;
  }
}

.diaBox {
  .dialogTop {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 20px 24px 0 24px;
    .left {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .tit1 {
      height: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #2c2c2c;
      line-height: 24px;
    }
  }
  .dialogCenter {
    font-size: 14px;
    font-weight: 400;
    color: #2c2c2c;
    font-size: 14px;
    padding: 32px 24px 24px 24px;
    .titBig {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }

    .tit1 {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #8a8b92;
    }
    .bottom {
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn_no {
        padding: 0px 16px;
        border-radius: 8px;
        height: 36px;
        font-size: 14px;
        text-align: center;
        line-height: 34px;
        color: #2c2c2c;
        border: 1px solid #e5e5e5;
        cursor: pointer;
      }
      .btn_no:hover {
        background-color: #f6f6f6;
      }
      .btn_no:active {
        background-color: #e5e5e5;
        border-color: #d9d9d9;
      }
    }
  }
}
</style>
