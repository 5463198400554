var tick = 1
var maxTick = 65535
var resolution = 4
var timer
var inc = function () {
  if (document.visibilityState === 'visible') {
    tick = (tick + 1) & maxTick
  } else {
    tick = (tick + resolution) & maxTick
  }
}

function speedometer(seconds) {
  if (!timer) {
    timer = setInterval(inc, (1000 / resolution) | 0)
    if (timer.unref) timer.unref()
  }

  var size = resolution * (seconds || 5)
  var buffer = [0]
  var pointer = 1
  var last = (tick - 1) & maxTick

  return function (delta) {
    var dist = (tick - last) & maxTick
    if (dist > size) dist = size
    last = tick

    while (dist--) {
      if (pointer === size) pointer = 0
      buffer[pointer] = buffer[pointer === 0 ? size - 1 : pointer - 1]
      pointer++
    }

    if (delta) buffer[pointer - 1] += delta

    var top = buffer[pointer - 1]
    var btm = buffer.length < size ? 0 : buffer[pointer === size ? 0 : pointer]

    return buffer.length < resolution ? top : (top - btm) * resolution / buffer.length
  }
}

export default class TheSpeed {
  constructor(
    options = {
      time: 1000
    }
  ) {
    this.timer = null
    this.spLength = 0
    this.lastTimestamp = 0
    this.speed = null
    this.params = {
      ...options
    }
  }

  initSpeed(func = () => {}) {
    this.clearSpeed()
    this.speed = speedometer()
    this.lastTimestamp = new Date().getTime()
    this.timer = setInterval(() => {
      let _newStamp = new Date().getTime()
      let _length = this.spLength
      let _cha = _newStamp - this.lastTimestamp
      if (_cha >= this.params.time * 2) {
        _length = Math.round(this.spLength * this.params.time / _cha)
      }
      this.lastTimestamp = _newStamp
      let _speed = this.speed(_length)
      this.spLength = 0
      func(_speed)
    }, this.params.time)
  }

  clearSpeed() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
      this.speed = null
      this.spLength = 0
    }
  }
}
