<template>
    <div class="drag-outer"
    ref="dragWrap"
    @mouseenter="isHover = true"
    @mouseleave="isMousedown = false"
    @mousemove="dragMousemove">
        <div class="drag-inner"
        ref="dragElement"
      
        @mousedown="dragMousedown"
        @mouseup.stop="isMousedown = false">
            <slot></slot>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'ElementDrag',
    props: {
        scaleZoom: {
            type: Object,
            default () {
                return {
                    max: 5, 
                    min: 0.2
                }
            }
        }
    },
    data() {
        return {
            isMousedown: false,
            isHover: true,
            moveStart: {},
            translate: {x: 0, y: 0},
            scale: 1,
            size: 0,
            deg:0,
        }
    },
    methods: {
        resetData(){
            this.isMousedown=false
            // this.isHover= false
            this.moveStart={}
            this.translate= {x: 0, y: 0}
            this.scale = 1
            this.size= 0
            this.deg=0
            this.$refs.dragElement.style.transform = `scale(1) rotateZ(${this.deg}deg)`;
        },
        handleScroll(e) {
            if(this.isHover) {
                let speed = e.wheelDelta/120
                if(e.wheelDelta > 0 && this.scale < this.scaleZoom.max) {
                    this.scale+=0.04*speed
                    if(this.$refs.dragElement){
                        this.$refs.dragElement.style.transform = `scale(${this.scale}) translate(${this.translate.x}px, ${this.translate.y}px) rotateZ(${this.deg}deg)`
                    }
                }else if(e.wheelDelta < 0 && this.scale > this.scaleZoom.min){
                    this.scale+=0.04*speed
                    if(this.$refs.dragElement){
                        this.$refs.dragElement.style.transform = `scale(${this.scale}) translate(${this.translate.x}px, ${this.translate.y}px) rotateZ(${this.deg}deg)`
                    }
                }
            }
        },
        dragMousedown() {
            this.moveStart.x = event.clientX
            this.moveStart.y = event.clientY
            this.isMousedown = true
        },
        dragMousemove() {
            if(this.isMousedown) {
                this.translate.x += (event.clientX - this.moveStart.x) / this.scale
                this.translate.y += (event.clientY - this.moveStart.y) / this.scale
                this.$refs.dragElement.style.transform = `scale(${this.scale}) translate(${this.translate.x}px, ${this.translate.y}px) rotateZ(${this.deg}deg)`
                this.moveStart.x = event.clientX
                this.moveStart.y = event.clientY
            }
        },
        isFlagChange() {
            this.translate= {x: 0, y: 0}
            this.scale = 1
            this.size = 0
            this.$refs.dragElement.style.transform = `scale(1) rotateZ(${this.deg}deg)`;
            this.$emit('updateFlag')
        },
        handleRotate(type) {
            if (type == 1) {
                this.deg += 90;
            } else {
                this.deg -= 90;
            }
            if (this.deg >= 360) {
                this.deg = 0;
            }
            this.$refs.dragElement.style.transform = `scale(${this.scale}) rotateZ(${this.deg}deg)`;
        },
        wheelHandle(e) {
            const ev = e || window.event; // 兼容性处理 => 火狐浏览器判断滚轮的方向是属性 detail，谷歌和ie浏览器判断滚轮滚动的方向是属性 wheelDelta
            // dir = -dir; // dir > 0 => 表示的滚轮是向上滚动，否则是向下滚动 => 范围 (-120 ~ 120)
            const dir = ev.detail ? ev.detail * -120 : ev.wheelDelta;
            //滚动的数值 / 2000 => 表示滚动的比例，用此比例作为图片缩放的比例
            this.imgScaleHandle(dir / 2000);
        },
        imgScaleHandle(zoom) {
            // this.size += zoom;
            // if (this.size < -0.8) {
            //     this.size = -0.8;
            // }
            // this.scale =  1 + this.size
            // this.$refs.dragElement.style.transform = `scale(${1 + this.size}) rotateZ(${this.deg}deg)`;
            this.scale += zoom;
            if (this.scale < -0.8) {
                this.scale = -0.8;
            }
            this.$refs.dragElement.style.transform = `scale(${this.scale}) rotateZ(${this.deg}deg)`;
        },
        imgHandle(id) {
            if (id == 1) {
                this.imgScaleHandle(-0.1);
            } else if (id == 2) {
                this.imgScaleHandle(0.1);
            } else if (id == 3) {
                this.isFlagChange();
            } else if (id == 4) {
                this.handleRotate(2);
            } else {
                this.handleRotate(1);
            }
        },
    },
    mounted() {
        window.addEventListener('mousewheel',this.handleScroll) 
    }
}
</script>
 
<style lang="scss" scoped>
.drag-outer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .drag-inner {
        transform-origin: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        >* {
            -webkit-user-drag: none;
            user-drag: none;
        }
    }
}
</style>