import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
  if (data?.ak){
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  }else{
    return data
  }
}

function inParameter(data){
  if (data){
    return { sn: ed, jt: utils.secret(data, ak)}
  }else{
    return data
  }
}

//文件列表接口
export function getCloudList(data) {
    return request({
      url: "/disk-service/file/list",
      method: "post",
      data: inParameter(data),
    }).then((res)=>{
      res.data = decrypt(res.data)
      return res
    }).catch((err) => {});
}

// 创建/重命名文件夹接口 
export function modiFolder(data) {
  return request({
    url: "/disk-service/file/modifolder",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//移动文件夹目录列表 
export function folderMoveList(data) {
  return request({
    url: "/disk-service/file/folderMoveList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//文件移动 
export function fileMove(data) {
  return request({
    url: "/disk-service/file/fileMove",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//修改文件名 
export function fileReName(data) {
  return request({
    url: "/disk-service/file/fileReName",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 通过文件夹id获取文件夹名称 
export function findFolderInfo(data) {
  return request({
    url: "/disk-service/file/findFolderInfo",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 分享文件
export function setShare(data) {
  return request({
    url: "/disk-service/file/setShare",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//预览 文档
export function previewTask(data) {
  return request({
    url: "/disk-service/preview/previewTask",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//word,ppt,pdf 的文件预览 (此接口需要轮询) 
export function officeDocument(data) {
  return request({
    url: "/disk-service/preview/officeDocument",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//txt 预览 
export function previewTxt(data) {
  return request({
    url: "/disk-service/preview/previewTxt",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//视频 execl 音乐 
export function previewAudiovisual(data) {
  return request({
    url: "/disk-service/preview/previewFile",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//图片预览 
export function previewImg(data) {
  return request({
    url: "/disk-service/preview/previewImg",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//解压 压缩包解压预览
export function queryZipInfoTask(data) {
  return request({
    url: "/disk-service/file/createZipQueryInfoTask",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 创建解压任务 
export function createUnZipAsyncTask(data) {
  return request({
    url: "/disk-service/file/createUnZipAsyncTask",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//获取解压进度 
export function queryProgressTask(data) {
  return request({
    url: "/disk-service/file/createZipQueryProgressTask",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//删除解压记录 
export function unZipLogDelete(data) {
  return request({
    url: "/disk-service/file/unZipLogDelete",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 查看文件夹层级路径
export function queryParentFolders(data) {
  return request({
    url: "/disk-service/file/queryParentFolders",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 获取云盘容量 
export function diskCapacity(data) {
  return request({
    url: "/disk-service/file/diskCapacity",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 创建上传文件任务
export function getDirectUploadLink(data) {
  return request({
    url: "/disk-service/file/getDirectUploadLink",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//  获取允许上传后缀白名单
export function uploadWhiteList(data) {
  return request({
    url: "/disk-service/file/uploadWhiteList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//  获取允许上传后缀黑名单
export function blackRegexListAPI(data) {
  return request({
    url: "/disk-service/file/getFileValidationRegexList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}


//下载自己的文件获取链接
export function selfDownload(data) {
  return request({
    url: "/disk-service/file/selfDownload",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 加入回收站
export function recycleFile(data) {
  return request({
    url: "/disk-service/recycle/add",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//下载历史
// 开启和关闭下载历史接口 
export function setDownLogSwitch(data) {
  return request({
    url: "/disk-service/file/setDownLogSwitch",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//下载历史列表接口 
export function historyFileList(data) {
  return request({
    url: "/disk-service/file/historyFileList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 下载历史删除 
export function historyFileDel(data) {
  return request({
    url: "/disk-service/file/historyFileDel",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//回收站列表 
export function recycleList(data) {
  return request({
    url: "/disk-service/recycle/list",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//回收站文件恢复
export function restoreFile(data) {
  return request({
    url: "/disk-service/recycle/restore",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//回收站删除
export function completeDel(data) {
  return request({
    url: "/disk-service/recycle/delete",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//清空回收站 
export function clearRecycleBin(data) {
  return request({
    url: "/disk-service/recycle/clear",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//获取回收站过期时间 
export function getExpireDay(data) {
  return request({
    url: "/disk-service/recycle/getExpireDay",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//合并接口

export function hebing(data) {
  return request({
    url: "/disk-service/index/test",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//四合一下载
export function downloadFile(data) {
  return request({
    url: "/disk-service/down/download",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 轮播文字
export function bannerTextList(data) {
  return request({
    url: "/disk-service/common/getBannerTextList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
