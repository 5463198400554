<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div class="app-bottom">
        <div class="feedbackHistory">
          <div class="top">
            <div class="leftTit">{{ feedbackTemp.tag_name || '' }}</div>
            <div class="tit">{{ feedbackTemp.title }}</div>
          </div>
          <div class="recordListBox">
            <div class="item" v-for="(item, index) in list" :key="item">
              <img class="avater" :src="item.logo" alt="" />
              <div class="rightBox">
                <div class="info">
                  <div class="name">{{ item.username }}</div>
                  <div class="time">{{ item.in_time }}</div>
                </div>
                <div class="descBox">
                  <div class="firstDesc" v-if="index == 0">问题描述：</div>
                  <div v-html="item.content" class="desc-content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="editBox" v-show="(feedbackTemp.status || feedbackTemp.status == 0) && parseInt(feedbackTemp.status)!==2">
            <div ref="editor"></div>
            <fm-button :disabled="disabled" class="btnSend" type="primary" round @click="handleReply">回 复</fm-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import TheNewUpload from "@/mixin/TheNewUpload.vue";
import { getQuestionsDetail, reply } from "@/utils/apiList/feedbackHistory";
export default {
  mixins: [TheNewUpload],
  components: {
    NavTop,
  },
  data() {
    return {
      input: "",
      editorContent: "", // 文件描述 富文本
      article_pic: this.$glb.fileServer + "/upload/articlePic",
      img_base_url:this.$glb.avaterPreview,
      newUpType: 1,
      feedbackId: null,
      feedbackTemp: {},
      replying: false,
      editor: null
    };
  },
  computed: {
    list: function () {
      return (this.feedbackTemp.list || [])
    },
    disabled: function () {
      return !this.editorContent
    }
  },
  created() {
    this.feedbackId = this.$route.query.id || null
    if (!this.feedbackId) {
      this.$router.replace({
        name: 'help',
        params: {
          topAct: 1
        }
      })
    }
  },
  mounted() {
    this.editFun();
    this.getQuestionsDetail()
  },
  methods: {
    async handleReply() {
      if (this.replying || this.disabled) return false;
      this.replying = true
      try {
        let res = await reply({
          id: this.feedbackId,
          content: this.editorContent
        })
        this.$toast(res.msg, res.status == 1 ? 1 : 2);
        if (res.status == 1) {
          this.editor.txt.clear()
          this.editorContent = ''
          await this.getQuestionsDetail()
        }
      } catch (e) {}
      this.replying = false
    },
    async getQuestionsDetail() {
      if (!this.feedbackId) return false
      try {
        let res = await getQuestionsDetail({
          id: this.feedbackId
        })
        if (res.status == 1) {
          this.feedbackTemp = res.data
        }
      } catch (e) {}
    },
    editFun() {
      // 富文本----S
      var E = window.wangEditor;
      var editor = new E(this.$refs.editor);
      editor.config.uploadImgServer = this.article_pic;
      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        let _date = new Date().getTime();
        for (let i = 0; i < resultFiles.length; i++) {
          let _file = {
            file: resultFiles[i],
          };
          _file.file.uid = _date + i;
          let result = await this.asyncMyUploadImg(_file);
          if (result.action === "success") {
            insertImgFn(this.img_base_url + result.res.data.path);
          }
        }
      };
      editor.config.cdnUrl = "";
      editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "strikeThrough",
        "foreColor",
        "link",
        "list",
        "justify",
        "quote",
        "image",
        "undo",
        "redo",
      ];
      // 取消自动 focus
      // editor.config.focus = false
      editor.config.zIndex = 100;
      editor.config.showLinkImg = false;
      // 将图片大小限制为 3M
      editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
      editor.config.uploadImgMaxLength = 5;
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteTextHandle = function (content) {
        if (content === "" && !content) return "";
        content = content.replace(/<br\/?>/gi, "fm#br#fm");
        content = content.replace(/<p>/gi, "fm#p1#fm");
        content = content.replace(/<p\/>/gi, "fm#p2#fm");
        // content = content.replace(/<.*?>/ig, '')
        content = content.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
        content = content.replace(/<style>[\s\S]*?<\/style>/gi, "");
        content = content.replace(/<\/?[^>]*>/g, "");
        content = content.replace(/[ | ]*\n/g, "\n");
        content = content.replace(/&nbsp;/gi, "");

        content = content.replace(/fm#p1#fm/gi, "<p>");
        content = content.replace(/fm#p2#fm/gi, "<p/>");
        content = content.replace(/fm#br#fm/gi, "<br/>");
        return content;
        // return content.replace(/<.*?>/ig, '')
      };
      editor.config.uploadImgHooks = {
        // success: function (xhr, editor, result) {},
      };
      editor.config.onchange = (html) => {
        this.editorContent = html;
      };
      editor.create();
      this.editor = editor;
      // 富文本----E
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
