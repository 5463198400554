<template>
    <div class="fc-top">
      <div class="tabList">
        <span class="tabItem" :class="{'curStyle':item.id==curId}" v-for="(item,index) in tabList" :key="index" @click="setNav(item)">{{item.name}}</span>
      </div>
    </div>
</template>

<script>
export default {
  props:{
    curId:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      tabList: [
        {id:0,name:'开通会员',url:'/vip'},
        {id:1,name:'福利社',url:'/welfare'},
        // {id:2,name:'达人中心',url:''},
        // {id:3,name:'做任务得福利',url:''},
        // {id:4,name:'福利活动',url:'/activities'}
      ],
    };
  },
  created() {
  },
  methods: {
    setNav(item){
      if (item.id == 4 && this.$store.state.userInfo.phone == "") {
        this.$store.state.handleOpenPhone({
          type: 1,
          show: true
        })
        return false
      }

      if(item.id==2){
        window.open('https://llztest.jvmao.com/llz')
      }
      if(item.id==3){
        window.open('https://jmzb2023test.jvmao.com/task')
      }
      this.$router.push({
        path:item.url
      })
    },
  },
};
</script>
<style lang="scss" scoped>
    .fc-top{
        height: 68px;
        display: flex;
        align-items: center;
    }
    .tabList{
        span{
            font-size: 16px;
            font-weight: 400;
            padding: 0 20px;
            cursor: pointer;
            margin-right: 4px;
            height: 40px;
            display: inline-block;
            line-height: 40px;
            border-radius: 20px;
            @include textUnSelect;
            @include useTheme{
              color: getVar("text-color1-8a8b92");
            }
        }
        .curStyle{
            font-weight: 600;
            @include useTheme{
              background: getVar("text-color1-f6f6f6");
              color: getVar("text-color2-2c2c2c");
            }
        }
        .tabItem:hover{
          @include useTheme{
              background: getVar("text-color1-f6f6f6");
              color: getVar("text-color2-2c2c2c");
            }
        }
    }
</style>
