<template>
  <div class="downPage">
    <div class="pageBox">
      <div class="dp-filedownload">
        <span class="title">文件下载中，如果没有速度请等待下载失败后点击重试</span>
        <div class="download-progress">
          <div class="progress-box">
            <div class="file-info">
              <img class="icon" :src="fileInfo.ext_icon" />
              <div class="info">
                <span class="name">{{ fileInfo.fileName }}</span>
                <div class="time">
                  <span class="txt"
                    >{{ $utils.bytesToSize(fileInfo.currentSize) }} / {{
                      fileInfo.fileSize
                    }}</span
                  >
                  <span class="txt" v-if="fileInfo.fileStatus == 1"
                    >{{ fileInfo.dfcurrentSpeed }}M/s - 
                    {{ formatTime(fileInfo.surplusSecond) }}</span
                  >
                  <span class="txt" v-else>- -</span>
                  <span class="txt success" v-show="fileInfo.fileStatus == 2">下载完成</span>
                  <span class="fail" v-show="fileInfo.fileStatus == 3"
                    >下载失败</span
                  >
                  <fm-button
                      @click="retry($event)"
                      v-show="fileInfo.fileStatus == 3"
                      icon="icon-refresh"
                      round
                      size="mini"
                      >重试</fm-button
                  >
                  <span class="txt" v-show="fileInfo.fileStatus == 1">{{
                    fileInfo.title
                  }}</span>
                  <fm-button
                    @click="goVip"
                    icon="icon-btn-vip"
                    round
                    size="mini"
                    v-show="fileInfo.title == '下载中' && fileInfo.fileStatus == 1"
                    >立即提速</fm-button
                  >
                </div>
              </div>
              <div
                v-show="fileInfo.fileStatus == 1"
                class="bg-progress"
                :style="'width:' + fileInfo.downloadProgress + '%'"
              ></div>
            </div>
            <div
              v-show="fileInfo.fileStatus == 1"
              class="line"
              :style="'width:' + fileInfo.downloadProgress + '%'"
            ></div>
          </div>
        </div>
        <!-- 开通会员 -->
        <!-- <span class="title">开通会员</span> -->
        <VipBenefits style="max-width: 1700px" ref="VipBenefits"></VipBenefits>
        <!-- 支付弹框 -->
        <PayOrder
          ref="PayOrder"
          :isShow="isShowPayOrder"
          :payData="payData"
          :showBankInfo="showBankInfo"
          :allMember="allMember"
          :superSlideData="superSlideData"
          @clearCoupon="clearCoupon"
          @handleClosePayDia="handleClosePayDia"
          @openSuccess="openSuccess"
        ></PayOrder>
        <!-- 优惠码 -->
        <DiscountCodeBox
          ref="DiscountCodeBox"
          :isShow="showDiscountCode"
          @handleClose="handleCloseDis"
          @checkCoupe="couponValidate"
        ></DiscountCodeBox>
        <SuccessfullyModel
          :payToast="payToast"
          :isShowModel="showSuccessfullyModel"
          @successRefresh="successRefresh"
        ></SuccessfullyModel>
      </div>
    </div>
  </div>
</template>

<script>
import VipBenefits from "@/components/VipBenefits/index";
import TheSpeed from "../../utils/upload/speedometer";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import VIPPAYMIXIN from "@/mixin/vip.vue";
import { getDownFileInfo } from "@/utils/apiList/downpage";
import {downloadFile} from "@/utils/apiList/cloud";
export default {
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      fileInfo: {},
      code: "",
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
    };
  },
  components: {
    VipBenefits,
    PayOrder,
    DiscountCodeBox,
    SuccessfullyModel,
  },
  created() {
    this.code = this.$route.params.code;
      this.gopageA1();
    if (this.$store.state.downloadFile.url) {
      this.goDownload(this.$store.state.downloadFile);
    } else {
      this.fileInfo = this.$store.state.downloadFile;
    }
  },
  beforeDestroy() {
    if(this.fileInfo.fileStatus == 1){//切换路由、中止下载任务
      this.fileInfo.xmlHttp.abort();
    }
  },
  methods: {
    //==========================
    formatTime(input) {
      if (typeof input === "string") {
        const matches = input.match(/(\d+)小时(\d+)分钟(\d+)秒/);
        if (matches) {
          let hours = parseInt(matches[1]).toString().padStart(2, "0");
          let minutes = parseInt(matches[2]).toString().padStart(2, "0");
          let seconds = parseInt(matches[3]).toString().padStart(2, "0");
          return `${hours}:${minutes}:${seconds}`;
        } else {
          return "";
        }
      } else {
        return "00:00:00";
      }
    },
    goDownload(opt) {
      this.fileInfo = {
        title: opt.title,
        ext_icon: opt.ext_icon,
        fid: opt.fid,
        type: opt.fileType,
        fileName: opt.fileName,
        fileSize: opt.fileSize, //文件大小
        downloadProgress: 0, //下载进度
        currentSize: 0, //当前已下载大小
        surplusSecond: 0, //剩余下载时间
        dfcurrentSpeed: 0, //当前下载速度
        surplusSize: 0, //剩余下载大小
        lastDataSize: 0, //上一秒加载的大小
        size: 0, //跟上一秒的差值
        spLength: 0,
        fileStatus: 0,
        xmlHttp: null,
        down_id:opt.down_id,
        timeId:0
      };
      this.downloadfile(opt.url, opt.fileName, "application/octet-stream");
    },
    downloadfile(url, strFileName, strMimeType) {
      var that = this;
      var xmlHttp = null;
      if (window.ActiveXObject) {
        // IE6, IE5 浏览器执行代码
        xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
      } else if (window.XMLHttpRequest) {
        // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
        xmlHttp = new XMLHttpRequest();
      }
      that.fileInfo.fileStatus = 1;
      that.fileInfo.xmlHttp = xmlHttp;
      that.startInterval(that.fileInfo, xmlHttp);
      //2.如果实例化成功，就调用open（）方法：
      if (xmlHttp != null) {
        xmlHttp.open("get", url, true);
        xmlHttp.responseType = "blob"; //关键
        xmlHttp.send();
        xmlHttp.onreadystatechange = doResult; //设置回调函数
      }
      function doResult() {
        if (xmlHttp.readyState == 4) {
          //4表示执行完成
          if (xmlHttp.status == 200) {
            //200表示执行成功
            if (window.download) {
              window.download(xmlHttp.response, strFileName, strMimeType);
            }
            that.fileInfo.fileStatus = 2;
            that.fileInfo.dfcurrentSpeed = 0;
            if (that.fileInfo.theSpeed) {
              that.fileInfo.theSpeed.clearSpeed();
              that.fileInfo.theSpeed = null;
            }
            that.fileInfo.url = "";
            that.$store.commit("setAttr", {
              name: "downloadFile",
              val: that.fileInfo,
            });
          } else {
            that.fileInfo.fileStatus = 3;
            that.fileInfo.dfcurrentSpeed = 0;
            if (that.fileInfo.theSpeed) {
              that.fileInfo.theSpeed.clearSpeed();
              that.fileInfo.theSpeed = null;
            }
            that.fileInfo.url = "";
            that.$store.commit("setAttr", {
              name: "downloadFile",
              val: that.fileInfo,
            });
          }
        }
      }
    },
    startInterval(obj, xmlHttp) {
      let that = this;
      obj.theSpeed = new TheSpeed();
      obj.theSpeed.initSpeed((_speed) => {
        if (obj?.dfcurrentSpeed >= 0) {
          obj.dfcurrentSpeed = (_speed / 1024 / 1024).toFixed(2); //当前速度
        }
        if (
          obj &&
          obj.surplusSecond !== undefined &&
          obj.surplusSecond !== null
        ) {
          obj.surplusSecond = that.$utils.formatSeconds(
            (obj.surplusSize ? obj.surplusSize : 0) / _speed
          ); //剩余时间
          obj.size = 0;
        }
        if(obj.timeId < 15){
              obj.timeId += 1; 
        }else{
            if(this.$utils.bytesToSizePagec(obj.currentSize)){
                obj.timeId = 0; 
                obj.xmlHttp.abort();
                obj.fileStatus = 3
            }
        }
      });
      xmlHttp.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            if (obj) {
              let transfer = evt.loaded - obj.currentSize;
              obj.theSpeed.spLength += transfer;
              obj.currentSize = evt.loaded;
              let percentComplete = evt.loaded / evt.total; //获取进度
              obj.downloadProgress = (percentComplete * 100).toFixed(1);
              obj.surplusSize = evt.total - evt.loaded; //剩余大小
              let sizeModify = evt.loaded - obj.lastDataSize; //避免网络不行出现负数。。
              if (sizeModify) {
                obj.size += sizeModify;
              }
              obj.lastDataSize = evt.loaded;
            } else {
            }
          }
        },
        false
      );
    },
    retry(e) {
      e = e || window.event;
      this.ms = e.clientX + "*" + e.clientY;
      this.sc = window.screen.width + "*" + window.screen.height;
      // this.$store.state.downloadFile.title = "极速下载中"
      //   ? this.svipDown()
      //   : this.downByPoint();
      this.retryDownload()
    },
    retryDownload: _.debounce(
      function () {
        let params={
        url: this.code, 
        type:4,// 1:列表下载，2:福利点下载，3:vip下载，4：重试
        // retry_url: this.$store.state.downloadFile.url, 
        ms: this.ms, 
        sc: this.sc,
        down_id:this.$store.state.downloadFile.down_id
      }
      //重试
      downloadFile(params).then((res)=>{
        if(res.status=='1'){
            let fileName = `${res.data.file.name}${res.data.file.extension}`;
            let obj = {
              fid: res.data.file.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.file.size, //文件大小
              fileType: res.data.file.extension,
              ext_icon: res.data.file.ext_icon,
              title: this.$store.state.downloadFile.title,
              down_id:res.data.down_id,//下载id，重试时需要用到
            };
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.goDownload(this.$store.state.downloadFile);
          }else if(res.status=='20004'){
            this.$router.push({
                path: res.data.go_back,
            });
          }else{//50006锁定 60001删除 60013 过期
            this.$toast(res.msg, 2);
          }
       })
      },
    1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //普通下载重试
    downByPoint() {
      downByPoint({ url: this.code, retry: 1, ms: this.ms, sc: this.sc }).then(
        (res) => {
          if (res.status == "1") {
            let fileName = `${res.data.name}${res.data.extension}`;
            let obj = {
              fid: res.data.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.size, //文件大小
              fileType: res.data.extension,
              ext_icon: res.data.ext_icon,
              title: "下载中",
            };
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.goDownload(this.$store.state.downloadFile);
          } else if (res.status == "20004") {
            this.$router.push({
              path: res.data.go_back,
            });
          } else {
            this.$toast(res.msg, 2);
          }
        }
      );
    },
    //会员重试
    svipDown() {
      svipDown({ code: this.code, retry: 1, ms: this.ms, sc: this.sc }).then(
        (res) => {
          if (res.status == "1") {
            let fileName = `${res.data.name}${res.data.extension}`;
            let obj = {
              fid: res.data.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.size, //文件大小
              fileType: res.data.extension,
              ext_icon: res.data.ext_icon,
              title: "极速下载中",
            };
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.goDownload(this.$store.state.downloadFile);
          } else if (res.status == "20004") {
            this.$router.push({
              path: res.data.go_back,
            });
          } else {
            this.$toast(res.msg, 2);
          }
        }
      );
    },
    goVip() {
      window.open("/vip");
    },
    //获取文件信息

    refreshPage() {
      this.$store.commit("getuserVuex");
    },
    gopageA1() {
      getDownFileInfo({ url: this.code }).then((res) => {
        if (res.status == "1") {
          if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
            this.$router.push({
              path: "/s/" + res.data.fshort,
            });
          }
        }else if(res.status=='20004'){
          this.$router.push({
            path: res.data.go_back,
          });
        }else if(res.status=='60001'){
          this.$toast(res.msg,2)
        }
      });
    },
    gopageA() {},
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
