<template>
  <el-dialog
    title="提示"
    :visible.sync="isShowModel"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="limitDialog"
    @handleClose="handleClose"
  >
    <div class="Riskverification">
      <div class="r-top">
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="r-center">
        <div class="r-ctop">
          <svg-btn icon-class="icon-tips-warning" />
          <span class="tit1"> 您的账号存在安全风险 </span>
        </div>
        <div class="r-top1">
          请使用您账号绑定手机号对应的手机打开「飞猫盘」APP扫描此二维码进行安全验证
        </div>
        <div class="r-codeBox" v-loading="loading">
          <div id="qrcodeVer"></div>
          <div
            class="mb1 mb2 pointer"
            v-if="order_status == '12001'"
            @click="refresh"
          >
            <img class="imgdb" :src="$utils.getPng('iconsx')" alt="" />
            请点击刷新
          </div>
        </div>
        <div class="r-down">
          无法验证？前往 <span class="r-down1" @click="gohelp">帮助中心</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { generateRiskQrcodeAPI, queryQrcodeAPI } from "@/utils/apiList/home";
import qrcode from "qrcode";

export default {
  data() {
    return {
      isShowModel: false,
      checkTimer: null,
      order_status: 0,
      loading: true,
      accessid: "",
    };
  },

  methods: {
    getCode() {
      this.loading = true;
      generateRiskQrcodeAPI().then((res) => {
        if (res.status == "1") {
          this.loading = false;
          var code = JSON.stringify(res.data);
          var qr = qrcode(0, "L");
          qr.addData(code);
          qr.make();
          document.getElementById("qrcodeVer").innerHTML = qr.createImgTag(
            5,
            0
          );
          clearInterval(this.checkTimer);
          this.order_status = 0;
          this.checkTimer = setInterval(() => {
            this.checkOrder(code);
          }, 4000);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    checkOrder: _.debounce(
      function (code) {
        //除了 wxpay 全部是 alipay
        let query = JSON.parse(code);
        queryQrcodeAPI(query)
          .then((res) => {
            this.order_status = res.status;
            if (res.status == 2) {
              clearInterval(this.checkTimer);
            }
            if (res.status == 1) {
              this.accessid = res.data.accessid;
              this.$toast(res.msg, 1);
              this.handleClose();
              clearInterval(this.checkTimer);
              this.riskveAgain(res.data.accessid);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    open() {
      this.isShowModel = true;
      this.getCode();
    },
    refresh() {
      this.getCode();
    },
    handleClose() {
      clearInterval(this.checkTimer);
      this.isShowModel = false;
      this.$emit("handleClose");
    },
    gohelp() {
      window.open("/help");
    },
    riskveAgain(accessid) {
      this.$emit("riskveAgain", accessid);
    },
  },
};
</script>

<style lang="scss">
// 1元3天活动弹框
.limitDialog {
  z-index: 5000;

  // background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  .el-dialog {
    /* background: rgba(0, 0, 0, .2) !important; */
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
    backdrop-filter: none;
    box-shadow: none;
    width: 528px !important;
    height: 383px;
    padding: 0;
  }

  :v-deep .el-dialog__body {
    width: 528px;
    height: 383px;
    padding: 0;
    background: transparent !important;
    margin: 0;
  }
}
.Riskverification {
  width: 480px;
  height: 394px;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  @include useTheme {
    background: getVar("text-color3-ffffff");
  }
  padding: 20px;
  .r-top {
    display: flex;
    justify-content: flex-end;
    height: 24px;
  }
  .r-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .r-ctop {
      display: flex;
      align-items: center;
      justify-content: center;
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
        line-height: 24px;
        margin-left: 8px;
      }
    }
    .r-top1 {
      height: 44px;
      font-size: 14px;
      font-weight: 400;
      @include useTheme {
        color: getVar("text-color1-2c2c2c");
      }
      text-align: center;
      line-height: 22px;
      margin-top: 12px;
    }
    .r-codeBox {
      width: 164px;
      height: 164px;
      background: #ffffff;
      box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;

      @include useTheme {
        border: getVar("boder-qrcode");
      }
      margin-top: 24px;
      .mb1 {
        background: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        font-weight: 400;
        border-radius: 9px;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 164px;
        height: 164px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mb2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-bottom: 6px;
        }
      }
    }
    .r-down {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-top: 12px;
      @include useTheme {
        color: getVar("text-color1-8a8b92");
      }
      .r-down1 {
        color: #f67942;
        text-decoration: underline;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
