<template>
  <div class="jmView">
    <div v-loading="fullscreenLoading" class="jmzbPage"></div>
    <SafeDialog @onConfirm="onConfirm" @onClose="onClose" ref="myDialog" />
  </div>
</template>

<script>
import { limitationDelAPI, clientOssLoginAPI } from "@/utils/apiList/login";
import SafeDialog from "@/components/Dialog/SafeDialog.vue";
export default {
  name: "redirect",
  components: {
    SafeDialog,
  },
  data() {
    return {
      token: "",
      fullscreenLoading: false,
      loginTip: {
        pre: "",
        now: "",
        access_token: "",
      },
      url: "",
    };
  },
  mounted() {
    this.token = this.$route.query.token || "";
    this.openFullScreen1();
    setTimeout(() => {
      this.getAuth();
    }, 500);
  },
  methods: {
    onConfirm() {
      limitationDelAPI({access_token: this.loginTip.access_token}).then((res)=>{
        if (res.status == 1) {
          this.$router.replace({ path: this.url });
          this.setInfo();
        } else {
          this.$toast(res.msg, 2);
          this.$router.replace({ path: "/login", query: { path: this.url } });
        }
        this.$refs.myDialog.close2();
      })

    },
    onClose() {
      this.loginTip.pre = "";
      this.loginTip.now = "";
      this.loginTip.access_token = "";
      this.$router.replace({ path: "/login", query: { path: this.url } });
    },
    setInfo() {
      this.$store.commit("getuserVuex");
    },
    getAuth() {
      clientOssLoginAPI({token: this.token}).then((res)=>{
        this.url = res.data.url == "/cloud?index=4" ? "/recycle" : res.data.url;
        if (res.status == 1) {
          this.token = this.$route.query.token || "";
          this.fullscreenLoading = false;
          this.$router.push({ path: this.url });
          this.setInfo();
        } else if (res.status == 1011) {
          this.loginTip.pre = res.data.last_province;
          this.loginTip.now = res.data.current_province;
          this.loginTip.access_token = res.data.access_token;
          this.token = res.data.access_token || this.$route.query.token || "";
          this.$refs.myDialog.show({
            current_province: this.loginTip.now,
            last_province: this.loginTip.pre,
          });
        } else {
          this.$toast(res.msg, 2);
          this.$router.replace({ path: "/login" });
        }

      })
    },
    openFullScreen1() {
      this.fullscreenLoading = true;
    },
  },
};
</script>


<style lang="scss" scoped>
.jmView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.jmzbPage {
  width: 100%;
  text-align: center;
}
</style>
