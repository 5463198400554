<!--
 * @FilePath: /www.feimaoyun.com_html/src/components/RaceNotice/index.vue
 * @Description: 设置密码跑马灯
-->

<template>
  <div ref="parentBox" class="race-notice-box">
    <div ref="msg1" :style="{ left: left1 + 'px' }" class="race-notice-text">{{ msg }}</div>
    <div :style="{ left: left2 + 'px' }" class="race-notice-text">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "raceNotice",
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      left1: 0,
      left2: 0,
      pWidth: 0,
      cWidth: 0,
      stop: false,
      gap: 30
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.pWidth = this.$refs.parentBox.clientWidth
    this.cWidth = this.$refs.msg1.clientWidth
    this.left1 = this.gap
    this.left2 = this.gap * 2 + this.cWidth

    this.initMove()
  },
  beforeDestroy() {
    this.stop = true
  },
  methods: {
    init() {
      this.formatAnimate()
    },
    initMove() {
      window.requestAnimationFrame(this.moveRender)
    },
    moveRender() {
      this.left1 -= 0.5
      this.left2 -= 0.5

      if (this.left1 <= -this.cWidth) {
        this.left1 = this.gap + this.cWidth + this.gap
      }

      if (this.left2 <= -this.cWidth) {
        this.left2 = this.gap + this.cWidth + this.gap
      }

      if (!this.stop) {
        this.initMove()
      }
    },
    formatAnimate() {
      var lastTime = 0;
      var prefixes = 'webkit moz ms o'.split(' '); //各浏览器前缀

      var requestAnimationFrame = window.requestAnimationFrame;
      var cancelAnimationFrame = window.cancelAnimationFrame;

      var prefix;
//通过遍历各浏览器前缀，来得到requestAnimationFrame和cancelAnimationFrame在当前浏览器的实现形式
      for( var i = 0; i < prefixes.length; i++ ) {
        if ( requestAnimationFrame && cancelAnimationFrame ) {
          break;
        }
        prefix = prefixes[i];
        requestAnimationFrame = requestAnimationFrame || window[ prefix + 'RequestAnimationFrame' ];
        cancelAnimationFrame  = cancelAnimationFrame  || window[ prefix + 'CancelAnimationFrame' ] || window[ prefix + 'CancelRequestAnimationFrame' ];
      }

//如果当前浏览器不支持requestAnimationFrame和cancelAnimationFrame，则会退到setTimeout
      if ( !requestAnimationFrame || !cancelAnimationFrame ) {
        requestAnimationFrame = function( callback, element ) {
          var currTime = new Date().getTime();
          //为了使setTimteout的尽可能的接近每秒60帧的效果
          var timeToCall = Math.max( 0, 16 - ( currTime - lastTime ) );
          var id = window.setTimeout( function() {
            callback( currTime + timeToCall );
          }, timeToCall );
          lastTime = currTime + timeToCall;
          return id;
        };

        cancelAnimationFrame = function( id ) {
          window.clearTimeout( id );
        };
      }

//得到兼容各浏览器的API
      window.requestAnimationFrame = requestAnimationFrame;
      window.cancelAnimationFrame = cancelAnimationFrame;
    }
  }
}
</script>

<style lang="scss" scoped>
.race-notice-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.race-notice-text {
  white-space: nowrap;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
