<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="inputDialog" :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <span>{{type==1 ? '新建文件夹' :type==2 ? '重命名文件夹' : '重命名文件'}}</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
            <el-input :class="{'paddingRight':type == 3}" v-model="rename" :placeholder="type==3 ? '请输入文件名称' : '请输入文件夹名称'">
                <svg-btn class="icon-clear" v-show="rename!=''" @click="rename=''" slot="suffix" icon-class="icon-ipt-clear"></svg-btn>
                <span class="ext" v-show="type == 3" slot="suffix">
                    {{reextension}} 
                </span>
            </el-input>
           
          </div>
          <div class="d-footer">
              <fm-button @click="confirm" type="primary" size="small">确 定</fm-button>
              <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
          </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        type:{
            type: Number,
            default: 1
        },
        // rename:{
        //     type:String,
        //     default:''
        // },
        reextension:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            rename:''
        }
    },
    methods:{
        handleClose(){
            this.rename=''
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('handleName',this.rename)
        }
    }
}
</script>

<style lang="scss">
</style>