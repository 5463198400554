<template>
  <div class="text-center img-div sign-progress-box">
    <div class="sign-progress">
      <div class="sign-tip1">
        <span class="sign-tip-light">{{
          signtype
            ? welfaredata.finish_all_sign_get_over_text
            : ""
        }}</span>
      </div>
      <div class="sign-tip">
        {{
          !signtype
            ? welfaredata.finish_all_sign_get_text
            : welfaredata.to_app_award_text
        }}<span class="sign-tip-light">{{ welfaredata.super_award_text }}</span>
      </div>
      <div class="sign-checks flex-between">
        <div
          v-for="(item, index) in signProgress"
          :key="item.key"
          class="a-sign-check img-div"
        >
          <div class="sign-check-icon">
            <img
              :src="signStatus[item.key] != '0' ? checked : uncheck"
              alt=""
            />
            <div :class="index === signProgress.length - 1 ? 'sign-line-last' : null" class="sign-line"></div>
          </div>
          <div class="sign-check-label">{{ item.label }}</div>
        </div>
        <div class="a-sign-check img-div">
          <div style="margin-top: -8px;" class="img-div">
            <img :src="signGift" width="48" alt="" />
          </div>
          <div class="sign-check-label">
            <div class="sign-check-button">
              <img :src="$store.state.theme == 'light' ? $utils.getPng('sign-super-text') : $utils.getPng('sign-super-text_dark')" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { welfareText, userSignProgress } from '@/utils/apiList/welfare'
export default {
  name: "webSign",
  data() {
    return {
      isSigned: false,
      welfaredata: {
        day_point: 0,
        finish_all_sign_get_text: "完成多端打卡签到可获得",
        super_award_text: "「超级签到奖励」",
        to_app_get_text: "请前往APP领取"
      },
      signData: {
        logo: this.$utils.getPng("sign-logo"),
        day_point: 5,
        finish_all_sign_get_text: "完成多端打卡签到可获得",
        super_award_text: "「超级签到奖励」",
        to_app_get_text: "请前往APP领取"
      },
      signProgress: [
        { label: "移动端", key: "is_APP_sign" },
        { label: "网页端", key: "is_WEB_sign" },
        { label: "桌面端", key: "is_Client_sign" }
      ],
      signStatus: {
        is_APP_sign: 0,
        is_Client_sign: 0,
        is_Super_sign: 0,
        is_WEB_sign: 0
      },
      checked: this.$utils.getPng("qiandao_fu2"),
      uncheck: this.$utils.getPng("qiandao_fu"),
      signGift: this.$utils.getPng("jiangli_fu")
    };
  },
  mounted() {
    this.signInRewardBar();
  },
  computed:{
    signtype(){
      return this.signStatus.is_APP_sign !=0 && this.signStatus.is_WEB_sign !=0 && this.signStatus.is_Client_sign !=0
    }
  },
  methods: {
    handleSign(item) {
      this.$emit("onSign",item);
    },
    welfareText() {
      welfareText().then((res) => {
        if (res.status == '1') {
          this.$emit("video_item", res.data);
          this.welfaredata = res.data;
        } else {
          this.welfaredata = this.signData;
        }
      })
    },
    // 签到进度
    signInRewardBar() {
      this.welfareText();
      userSignProgress().then((res) => {
        if (res.status == '1') {
          this.isSigned = res.data.is_WEB_sign != 0;
          this.signStatus = res.data;
          this.handleSign(this.isSigned)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-progress-box {
  text-align: center;
  max-width: 430px;
  margin: 0 auto;
}
.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.img-div {
  font-size: 0;
  line-height: 0;
}

.sign-tip1 {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  @include useTheme{
    color: getVar("text-color2-2c2c2c");
  }
  line-height: 24px;
  margin-bottom: 8px;
}
.sign-tip {
  font-size: 14px;
  font-weight: 400;
  @include useTheme{
    color: getVar("text-color1-8a8b92");
  }
  line-height: 16px;
  .sign-tip-light {
    @include useTheme{
      color: getVar("text-color2-2c2c2c");
    }
  }
}
.sign-progress {
  margin-top: 22px;
  @include useTheme{
    background: getVar("text-color1-ffffff");
  }
  border-radius: 16px;
}
.flex-between {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-checks {
  position: relative;
  margin-top: 31px;
  padding: 0 5px;
}
.a-sign-check + .a-sign-check {
  margin-left: 55px;
}
.a-sign-check {
  position: relative;
  padding: 0 5px;
}
.sign-check-icon {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  img {
    width: 100%;
  }
}
.sign-line {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 68px;
  height: 4px;
  @include useTheme{
    background-color: getVar("text-color1-fdf2e3");
  }
}
.sign-line.sign-line-last {
  width: 74px;
}
.sign-check-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  @include useTheme{
    color: getVar("text-color2-2c2c2c");
  }
  line-height: 20px;
  margin-top: 22px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
}
.sign-check-button {
  width: 84px;
  height: 22px;
  img {
    width: 100%;
    height: 100%;
  }
}
.sign-to-app {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  padding: 2px 0 12px;
}
.no-sign {
  img {
    opacity: 0.4;
  }
}
</style>
