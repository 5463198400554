<template>
  <div ref="vlBox" class="q-vl">
    <div ref="vlScrollBox" :style="{ height: finweckTreeRef.sum() + 'px' }" class="q-vl-box">
      <div ref="vlItems" :style="{ transform: `translateY(${finweckTreeRef.sum(start)}px)` }" class="q-vl-items us-none">
        <slot v-for="(item, index) in list" :item="item" :index="start + index"></slot>

        <slot name="noMore"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import FinweckTree from "@/components/QVirtualList/finweck";
export default {
  name: "qVirtualList",
  props: {
    items: Array,
    itemSize: Number,
    keyField: String,
    isOver: Boolean,
  },
  data() {
    return {
      keyToHeightOffset: new Map(),
      scrollTop: 0,
      vlBoxHeight: 0,
      timer: null
    }
  },
  computed: {
    finweckTreeRef: function () {
      const ft = new FinweckTree(this.items.length, this.itemSize);
      this.items.forEach((item, index) => {
        const key = item[this.keyField];
        const heightOffset = this.keyToHeightOffset.get(key);
        if (heightOffset !== undefined) {
          ft.add(index, heightOffset);
        }
      });
      return ft;
    },
    start: function () {
      return Math.max(this.finweckTreeRef.getBound(this.scrollTop) - 1, 0);
    },
    vlCount: function () {
      return (Math.ceil(this.vlBoxHeight / this.itemSize) + 2)
    },
    list: function () {
      return this.items.slice(this.start, this.start + this.vlCount)
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  mounted() {
    this.$refs.vlBox.addEventListener('scroll', this.onscroll)

    this.timer = setInterval(() => {
      this.vlBoxHeight = this.$refs.vlBox.clientHeight
      if (this.vlBoxHeight > 0) {
        clearInterval(this.timer)
        this.timer = null
        this.$emit('getVlBoxAttr', {
          vlBox: this.$refs.vlBox,
          start: this.start,
          vlCount: this.vlCount
        })
      }
    }, 50)
  },
  beforeDestroy() {
    this.$refs.vlBox.removeEventListener('scroll', this.onscroll)
    window.removeEventListener('resize', this.onResize)

    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    onResize() {
      this.vlBoxHeight = this.$refs.vlBox.clientHeight
    },
    onscroll(e) {
      this.scrollTop = e.target.scrollTop

      let distance = this.$refs.vlScrollBox.clientHeight - this.scrollTop - this.$refs.vlBox.clientHeight
      if (!this.isOver && distance <= 50) {
        this.$emit('onEnd')
      }

      this.$emit('onScroll', {
        sTop: this.scrollTop,
        distance,
        start: this.start,
        vlCount: this.vlCount
      })
    },
    scrollToTop() {
        this.scrollTop = 0
        this.$refs.vlBox.scrollTop = 0;
        this.$refs.vlBox.scrollLeft = 0;
        this.$refs.vlScrollBox.scrollLeft = 0;
        this.$refs.vlScrollBox.scrollTop = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.q-vl {
  max-height: inherit;
  height: 100%;
  overflow: auto;
}
</style>
