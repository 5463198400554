<template>
  <div class="vip">
    <TabList curId="0"></TabList>
    <div class="vipbox">
      <div class="userInfo">
        <el-skeleton :loading="loading" animated>
          <template>
            <div class="leftbox" style="display: flex">
              <div class="head-box" v-if="memberInfo.frame_url">
                <img class="headImg" :src="memberInfo.logo" />
                <img class="signBox" :src="memberInfo.frame_url" />
              </div>
              <img
                class="logo"
                v-else
                :src="
                  memberInfo.logo
                    ? memberInfo.logo
                    : $utils.getPng('user-nopic2')
                "
              />
              <div class="infos" style="margin-left: 16px; width: 100%">
                <div class="topInfos">
                  <div
                    class="nickName1"
                    :style="
                      memberInfo.tag.length == 0
                        ? 'max-width: 161px'
                        : 'max-width: 300px'
                    "
                    style="height: 24px; line-height: 24px"
                  >
                    {{
                      $utils.getCookie($glb.fmCookieName)
                        ? memberInfo.nickname
                        : "未登录"
                    }}
                  </div>
                  <img
                    class="sign-icon"
                    v-show="
                      $utils.getCookie($glb.fmCookieName) &&
                      memberInfo.tag.length == 0
                    "
                    :src="$utils.getPng('web4/no_vip')"
                  />
                </div>
                <span
                  class="noVip"
                  v-show="
                    $utils.getCookie($glb.fmCookieName) &&
                    memberInfo.tag.length == 0
                  "
                  >立即开通会员服务享受特权</span
                >
                <div
                  class="isVip"
                  v-if="memberInfo.tag.length > 0"
                  style="
                    overflow: hidden;
                    height: 26px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div
                    v-for="(item, index) in memberInfo.tag.slice(0, 2)"
                    :key="index"
                    style="margin-right: 8px"
                  >
                    <el-tooltip :content="item.label" placement="bottom">
                      <img
                        style="height: 18px"
                        :src="
                          item.url.indexOf('icon-dcvip-light') > 0
                            ? $utils.getPng(
                                `web4/icon-dcvip-${$store.state.theme}`
                              )
                            : item.url
                        "
                      />
                    </el-tooltip>
                  </div>
                  <el-dropdown
                    v-if="memberInfo.tag.length > 2"
                    placement="bottom-start"
                    :hide-on-click="false"
                  >
                    <div class="icondcvip">
                      <svg-btn icon-class="icon-dcvip" />
                    </div>
                    <el-dropdown-menu class="moreAct" slot="dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in memberInfo.tag.slice(
                          2,
                          memberInfo.tag.length
                        )"
                        :key="index"
                      >
                        <el-tooltip
                          :enterable="false"
                          :content="item.label"
                          placement="right"
                        >
                          <div>
                            <img
                              style="height: 18px"
                              :src="
                                item.url.indexOf('icon-dcvip-light') > 0
                                  ? $utils.getPng(
                                      `web4/icon-dcvip-${$store.state.theme}`
                                    )
                                  : item.url
                              "
                            />
                          </div>
                        </el-tooltip>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </template>
          <template slot="template">
            <div class="leftbox">
              <div class="head-box">
                <img class="headImg" :src="$utils.getPng('user-nopic2')" />
              </div>
              <div class="infos" style="margin-left: 16px">
                <div class="topInfos" style="height: 26px">
                  <el-skeleton-item
                    class="nickName1"
                    style="width: 80px; height: 15px; margin-bottom: 8px"
                  />
                </div>
                <el-skeleton-item
                  variant="text"
                  style="width: 200px; height: 15px"
                />
              </div>
            </div>
          </template>
        </el-skeleton>
        <div class="introduce">
          <div class="one" v-for="item in introduceList" :key="item.id">
            <img
              class="leftImg"
              :src="
                $utils.getPng(
                  `web4/icon-vip-${$store.state.theme}-${item.icon}`
                )
              "
            />
            <div class="oneIntr">
              <div class="tit">{{ item.tit }}</div>
              <div class="tit1">{{ item.tit1 }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="comment-box" v-if="commentData">
        <div class="demobox1"></div>
        <div class="demobox2"></div>
        <vue-seamless-scroll
          :data="commentData"
          :class-option="optionSetting"
          class="picMarquee-left"
        >
          <div class="bd" style="width: 100%">
            <ul class="infoList" style="margin-bottom: 12px">
              <el-skeleton
                style="width: 100%"
                :loading="scrollLoading"
                animated
              >
                <template slot="template">
                  <div class="box" v-for="item in 4" :key="item">
                    <div class="skeletonScr">
                      <el-skeleton-item
                        variant="image"
                        style="width: 20px; height: 20px; border-radius: 50%"
                      />
                      <el-skeleton-item
                        variant="text"
                        style="width: 0px; margin-left: 10px"
                      />
                    </div>
                  </div>
                </template>
                <template>
                  <li
                    v-for="(item, index) in commentData.slice(0, 4)"
                    :key="index"
                  >
                    <img class="avater" :src="item.logo" />
                    <div class="desc">
                      <div class="desc-li" style="margin-right: 1px">
                        {{ item.nickname }}：
                      </div>
                      <div class="desc-li">{{ item.content }}</div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
            </ul>
          </div>
        </vue-seamless-scroll>
        <vue-seamless-scroll
          style="transform: translateX(-170px)"
          :data="commentData"
          :class-option="optionSetting"
          class="picMarquee-left"
        >
          <div class="bd">
            <ul class="infoList">
              <el-skeleton
                style="width: 100%"
                :loading="scrollLoading"
                animated
              >
                <template slot="template">
                  <div class="box" v-for="item in 4" :key="item">
                    <div class="skeletonScr">
                      <el-skeleton-item
                        variant="image"
                        style="width: 20px; height: 20px; border-radius: 50%"
                      />
                      <el-skeleton-item
                        variant="text"
                        style="width: 0px; margin-left: 10px"
                      />
                    </div>
                  </div>
                </template>
                <template>
                  <li
                    v-for="(item, index) in commentData.slice(
                      4,
                      commentData.length
                    )"
                    :key="index"
                  >
                    <img class="avater" :src="item.logo" />
                    <div class="desc">
                      <div class="desc-li">
                        {{ item.nickname }}：
                      </div>
                      <div class="desc-li">{{ item.content }}</div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
            </ul>
          </div>
        </vue-seamless-scroll>
      </div>

      <!-- <span class="v-title">开通会员</span> -->
      <VipBenefits style="max-width: 1700px" ref="VipBenefits"></VipBenefits>
      <span class="v-desc"
        >注：标有*特权需消耗下载额度使用，剩余下载额度不足时，可消耗福利点使用</span
      >

      <!-- 支付弹框 -->
      <PayOrder
        ref="PayOrder"
        :isShow="isShowPayOrder"
        :payData="payData"
        :showBankInfo="showBankInfo"
        :allMember="allMember"
        :superSlideData="superSlideData"
        @clearCoupon="clearCoupon"
        @handleClosePayDia="handleClosePayDia"
        @openSuccess="openSuccess"
      ></PayOrder>
      <!-- 优惠码 -->
      <DiscountCodeBox
        ref="DiscountCodeBox"
        :isShow="showDiscountCode"
        @handleClose="handleCloseDis"
        @checkCoupe="couponValidate"
      ></DiscountCodeBox>
      <SuccessfullyModel
        :payToast="payToast"
        :isShowModel="showSuccessfullyModel"
        @successRefresh="successRefresh"
      ></SuccessfullyModel>
    </div>
  </div>
</template>

<script>
import TabList from "../TabList/index.vue";
import VipBenefits from "@/components/VipBenefits/index";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import VIPPAYMIXIN from "@/mixin/vip.vue";
export default {
  components: {
    TabList,
    VipBenefits,
    PayOrder,
    DiscountCodeBox,
    SuccessfullyModel,
  },
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      loading: false,
      introduceList: [
        {
          id: 1,
          icon: 4,
          tit: "专属下载额度",
          tit1: "最高享300次/天",
        },
        {
          id: 2,
          icon: 3,
          tit: "下载加速",
          tit1: "最高享极致加速",
        },
        {
          id: 3,
          icon: 2,
          tit: "去广告体验",
          tit1: "会员专享纯净体验",
        },
        {
          id: 4,
          icon: 1,
          tit: "更多云体验",
          tit1: "6项在线功能",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    optionSetting() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    refreshPage() {
      this.$store.commit("getuserVuex");
      this.getPcMember();
    },
    gopageA() {},
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
