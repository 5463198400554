<template>
  <div class="navTop">
    <div class="navl" @click="goDrive">
      <img
        class="logo"
        :draggable="true"
        :src="$utils.getPng('web4/icon_loog10')"
        alt=""
      />
      <!-- <img class="logo1" :src="$utils.getPng('web4/icon_fmp')" alt="" /> -->
      <svg-btn class="logo1" icon-class="logo-fm"></svg-btn>
    </div>
    <div class="navr">
      <!-- <HoverPop>
        <div class="vipBox" @mouseenter="getVipAdv">
          <img class="vipImg" :src="$utils.getPng('web4/icon-vip')" alt="" />
          <div class="tit">开通会员</div>
        </div>

        <div slot="content" class="vipHoverBox">
          <div class="top1">
            <img
              class="iconLine"
              :src="$utils.getPng('web4/icon-line')"
              alt=""
            />
            <div class="topTit">{{ vipAdvTitle }}</div>
            <img
              class="iconLine iconLine1"
              :src="$utils.getPng('web4/icon-line')"
              alt=""
            />
          </div>
          <div class="center">
            <div class="item" v-for="item in vipList" :key="item.id">
              <img class="imgSrc" :src="item.icon" alt="" />
              <div class="tit">{{ item.text }}</div>
            </div>
          </div>
          <div class="btnVip pointer" @click="openVip()">立即开通</div>
        </div>
      </HoverPop> -->

      <div class="userInfoBox" v-if="myCookieValue">
        <!-- 福利点悬浮框 -->
        <div class="fuliBox">
          <!-- <HoverPop>
            <div class="fuli pointer" @click="routeTo('/welfare')">
              <img
                class="fuliImg"
                :src="$utils.getPng('web4/icon-fulipoint')"
                alt=""
              />
              <div class="tit">福利点 {{ $store.state.userInfo.point }}</div>
            </div>

            <div slot="content" class="fuliHoverBox">
              <div class="item" v-for="item in fuliList" :key="item.id">
                <img class="imgSrc" :src="item.imgSrc" alt="" />
                <div class="tit">{{ item.name }}</div>
              </div>
              <div class="btnFuli pointer" @click="routeTo('/welfare')">
                获得福利点
              </div>
            </div>
          </HoverPop> -->

          <div
            class="needSetPswVisible"
            v-if="$store.state.userInfo.is_pass == 0"
          >
            <!-- <div class="line"></div> -->
            <div
              class="set-psw-box"
              @click="handleClickPsw"
              :style="`backgroundImage:url(${
                $store.state.theme == 'light'
                  ? $utils.getPng('set-password')
                  : $utils.getPng('web4/icon-equir-n')
              })`"
            >
              <div class="set-psw-img">
                <img :src="$utils.getPng('set-password-icon')" alt="" />
              </div>
              <div class="set-psw-text">
                <RaceNotice msg="请尽快设置密码，避免盗号风险！"></RaceNotice>
              </div>
            </div>
          </div>
        </div>

        <HoverPop>
          <div
            class="userBox"
            :style="$store.state.userInfo.is_pass == 0 ? 'margin-left:0' : ''"
          >
            <div class="imgBox">
              <img class="avater" :src="$store.state.userInfo.logo" alt="" />
              <img
                v-if="$store.state.userInfo.frameUrl"
                class="icon-header"
                :src="$store.state.userInfo.frameUrl"
                alt=""
              />
            </div>
            <div class="name">{{ $store.state.userInfo.nickname }}</div>
            <svg-btn class="iconOpen" icon-class="icon-open" />
            <!-- <img
              class="iconOpen"
              :src="$utils.getPng('web4/icon-open')"
              alt=""
              srcset=""
            /> -->
          </div>

          <div slot="content" class="userOperateBox">
            <div
              class="userOBTop"
              :style="`backgroundImage:url(${
                $store.state.theme == 'light'
                  ? $utils.getPng('web4/icon-bg')
                  : $utils.getPng('web4/icon-bg-n')
              })`"
            >
              <div class="avaterBox">
                <img class="avater" :src="$store.state.userInfo.logo" alt="" />
                <img
                  v-if="$store.state.userInfo.frameUrl"
                  class="icon-header"
                  :src="$store.state.userInfo.frameUrl"
                  alt=""
                />
              </div>
              <div class="userRight">
                <div class="name">{{ $store.state.userInfo.nickname }}</div>
                <div class="welfare">
                  <div
                    class="boxa"
                    v-if="$store.state.userInfo.web_tag?.length > 0"
                  >
                    <img
                      style="width: auto; height: 16px; flex-grow: 0"
                      :src="
                        $store.state.userInfo.web_tag[0].indexOf(
                          'icon-dcvip-light'
                        ) > 0
                          ? $utils.getPng(
                              `web4/icon-dcvip-${$store.state.theme}`
                            )
                          : $store.state.userInfo.web_tag[0]
                      "
                      alt=""
                    />
                  </div>
                  <div class="boxa pointer" v-else>
                    <img
                      class="fuli"
                      :src="$utils.getPng('web4/icon-fulipoint')"
                      alt=""
                    />
                    <div class="tit1" @click="routeTo('/welfare')">
                      {{ $store.state.userInfo.point }}福利点·福利社
                    </div>
                  </div>

                  <div class="line"></div>
                  <div class="loginOut pointer" @click="loginOut()">退出</div>
                </div>
              </div>
            </div>
            <div class="userOBdown">
              <!-- show_banner== 1 非不限时长是会员 -->
              <img
                v-show="$store.state.userInfo.is_vip == 0"
                class="icon-vip1 pointer"
                @click="toVip()"
                :src="$utils.getPng('web4/icon-vip1')"
                alt=""
              />
              <img
                v-show="
                  $store.state.userInfo.show_banner == 1 &&
                  $store.state.userInfo.is_vip == 1
                "
                class="icon-vip1 pointer"
                @click="toVip()"
                :src="$utils.getPng('web4/icon-vip2')"
                alt=""
              />
              <div class="operateTable">
                <div
                  class="item pointer"
                  v-for="(item, index) in table"
                  :key="index"
                  @click="tableClick(item)"
                >
                  <img
                    class="itemIcon"
                    :src="
                      $utils.getPng(
                        `web4/icon-seting-${$store.state.theme}-${item.icon}`
                      )
                    "
                    alt=""
                  />
                  <div class="itemTit">{{ item.tit }}</div>
                </div>
              </div>
            </div>
          </div>
        </HoverPop>
        <div class="general" @click="isShowuserNews = true">
          <el-badge
            :max="99"
            :value="$store.state.userMsgNub"
            :hidden="$store.state.userMsgNub == 0"
          >
            <svg-btn class="logo1" icon-class="icon-general"></svg-btn>
          </el-badge>
        </div>
      </div>
      <div class="loginbtn" v-else>
        <fm-button
          type="primary"
          round
          size="medium"
          style="margin: 0 16px 0 24px"
          @click="toLogin(0)"
          >登 录</fm-button
        >
        <fm-button type="info" round size="medium" @click="toLogin(1)"
          >注 册</fm-button
        >
        <span class="divide"> </span>
      </div>

      <HoverPop>
        <!-- <div class="vipBox" @mouseenter="getVipAdv">
          <img class="vipImg" :src="$utils.getPng('web4/icon-vip')" alt="" />
          <div class="tit">开通会员</div>
        </div> -->
        <div class="nav-openvip" @click="openVip()">
          <span
            :style="`background-image:url(${$utils.getPng(
              'web4/openVipBtn-' + $store.state.theme
            )})`"
            >开通会员</span
          >
        </div>

        <!-- <div slot="content" class="vipHoverBox pointer" @click="openVip()">
          <div class="top1">
            <img
              class="iconLine"
              :src="$utils.getPng('web4/icon-line')"
              alt=""
            />
            <div class="topTit">{{ vipAdvTitle }}</div>
            <img
              class="iconLine iconLine1"
              :src="$utils.getPng('web4/icon-line')"
              alt=""
            />
          </div>
          <div class="center">
            <div class="item" v-for="item in vipList" :key="item.id">
              <img class="imgSrc" :src="item.icon" alt="" />
              <div class="tit">{{ item.text }}</div>
            </div>
          </div>
          <div class="btnVip pointer" @click="openVip()">立即开通</div>
        </div> -->
      </HoverPop>
    </div>
    <changePassDia
      type="0"
      :isShow="changePassFlag"
      @handleClose="changePassFlag = false"
    />

    <BindPhone
      key="navTopBind"
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      ref="bindPhoneCom"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="isShowBindPhone = false"
    ></BindPhone>
    <userNews
      :isShow="isShowuserNews"
      @handleClose="isShowuserNews = false"
    ></userNews>
  </div>
</template>

<script>
import { getUserInfoAPI, newReportedAPI } from "@/utils/apiList/home";
import { userMsginfoapi } from "@/utils/apiList/usermsg";
import { loginOutAPI } from "@/utils/apiList/login";
import RaceNotice from "../RaceNotice/index.vue";
import changePassDia from "@/views/Account/compoents/ChangePassword.vue";
import {
  bindPhoneAPI,
  bindPhoneSendCode,
  navTopAdInfo,
  realNameAuth,
} from "@/utils/apiList/account";
import HoverPop from "@/components/hoverPop/index.vue";
import BindPhone from "@/components/Cloud/BindPhone";
import userNews from "@/components/userNews/index.vue";
export default {
  data() {
    return {
      msgid: "",
      timerA: null,
      time: 60,
      bindType: 1,
      isShowBindPhone: false,
      table: [
        {
          icon: 1,
          tit: "开通会员",
          path: "/vip",
        },
        // {
        //   icon: 4,
        //   tit: "我的订阅",
        //   path: "/home",
        // },
        {
          icon: 2,
          tit: "帮助",
          path: "/help",
        },
        {
          icon: 3,
          tit: "设置",
          path: "/account",
        },
      ],
      myCookieValue: this.$utils.getCookie(this.$glb.fmCookieName),
      vipAdvTitle: "",
      vipList: [
        {
          id: 0,
          icon: this.$utils.getPng("web4/icon-t3"),
          text: "每天免排队、不限速下载300次",
        },
        {
          id: 1,
          icon: this.$utils.getPng("web4/icon-t1"),
          text: "送1000G云空间 + 500福利点",
        },
        {
          id: 1,
          icon: this.$utils.getPng("web4/icon-t4"),
          text: "尊享不限时长使用飞猫盘特权功能",
        },
      ],
      fuliList: [
        {
          id: 0,
          imgSrc: this.$utils.getPng("web4/icon-t2"),
          name: "免费下载文件",
        },
        {
          id: 1,
          imgSrc: this.$utils.getPng("web4/icon-t4"),
          name: "兑换会员特权",
        },
      ],
      changePassFlag: false,
      isShowuserNews: false,
      phone_item: {
        phone: "",
        pcode: "",
      },
      fmCookies: "",
    };
  },
  watch: {
    "$route.name": function () {
      this.checkShowBind();
    },
  },
  created() {
    if (this.$utils.getCookie(this.$glb.fmCookieName)) {
      localStorage.setItem(
        "fmCookieName",
        this.$utils.getCookie(this.$glb.fmCookieName)
      );
      this.fmCookies = this.$utils.getCookie(this.$glb.fmCookieName);
      this.getUserInfo();
      this.userMsginfoapiFn();
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", this.handleVisiable);
    this.$store.commit("setAttr", {
      name: "getusercallback",
      val: this.getUserInfo,
    });
    this.$store.commit("setAttr", {
      name: "handleOpenPhone",
      val: this.handleOpenPhone,
    });
    this.$store.commit("setAttr", {
      name: "clearCookieCallback",
      val: this.clearCookie,
    });
    let routeList = this.getAllRouteList(this.$router.options.routes);
    // this.$watch(
    //   () => this.myCookieValue,
    //   (newCookieValue, oldCookieValue) => {
    //     if (!newCookieValue) {
    //       if (
    //         routeList.some(
    //           (item) =>
    //             item.name == this.$route.name && item.requireLogin == true
    //         )
    //       ) {
    //         this.$router.push({
    //           path: "/login",
    //           query: { path: this.$route.name },
    //         });
    //       }
    //     }
    //   },
    //   { deep: true, immediate: true }
    // );
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  methods: {
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case "prerender":
          // console.log("网页预渲染，内容不可见");
          break;
        case "hidden":
          // console.log("内容不可见，处理后台、最小化、锁屏状态");
          break;
        case "visible":
          // 缓存字段loginflag是用来同步账号状态的

          // 1、 this.$utils.getCookie(this.$glb.fmCookieName) 不等于  localStorage.getItem("fmCookieName") 同步登录情况
          // 2、 this.fmCookies 页面存储 cookies ,this.$utils.getCookie(this.$glb.fmCookieName) 同域名下登录其他账号 会更新 this.$glb.fmCookieName
          // 3、 loginflag 记录切换登录
          // 4、 this.$utils.getCookie(this.$glb.fmCookieName) 不等于  localStorage.getItem("fmCookieName") 同步登录情况 ，loginflag ==1 并且存在cookie 并且与 页面存储的 this.fmCookies的不一致 时会刷新页面

          if(!this.$utils.getCookie(this.$glb.fmCookieName) && !this.fmCookies){
            return
          }

          if (
            (this.$utils.getCookie(this.$glb.fmCookieName) &&
              this.$utils.getCookie(this.$glb.fmCookieName) !=
                localStorage.getItem("fmCookieName")) ||
            (this.$utils.getCookie(this.$glb.fmCookieName) &&
              this.$utils.getCookie(this.$glb.fmCookieName) != this.fmCookies &&
              localStorage.getItem("loginflag") == 1) ||
            (!this.$utils.getCookie(this.$glb.fmCookieName) &&
              localStorage.getItem("loginflag") == 1)
          ) {
            localStorage.setItem("loginflag", 0);
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
          break;
      }
    },
    handleOpenPhone(args) {
      const { type, show } = args;
      this.bindType = type;
      this.isShowBindPhone = show;
    },
    routeTo(path) {
      this.$router.push({ path: path });
    },
    userMsginfoapiFn() {
      userMsginfoapi()
        .then((res) => {
          this.$store.commit("setAttr", {
            name: "userMsgNub",
            val: +res.data.count || 0,
          });
        })
        .catch((err) => {});
    },
    getUserInfo() {
      getUserInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.$store.commit("setAttr", {
              name: "userInfo",
              val: res.data,
            });
            sessionStorage.setItem("is_svip_history", res.data.is_vip);
            this.myCookieValue = this.$utils.getCookie(this.$glb.fmCookieName);
            this.checkShowBind();
          } else{
            this.myCookieValue = null
          }
        })
        .catch((err) => {});
    },
    checkShowBind() {
      let name = this.$route.name;
      if (["activities", "welfare"].includes(name)) {
        if (!this.$store.state.userInfo.phone) {
          getUserInfoAPI()
            .then((res) => {
              if (res.status == 1) {
                this.$store.commit("setAttr", {
                  name: "userInfo",
                  val: res.data,
                });
                if (!res.data.phone) {
                  this.bindType = 1;
                  this.isShowBindPhone = true;
                }
              }
            })
            .catch((err) => {});
        }
      } else if (this.isShowBindPhone) {
        this.isShowBindPhone = false;
      }
    },
    toLogin(index) {
      let path = this.$route.fullPath.toString().slice(1);
      if (index == "1") {
        let query = {
          path: this.$store.state.fmPath,
          tab: "2",
        };
        this.$router.push({ path: "/login", query });
      } else {
        this.$router.push({ path: "/login", query: { path: path } });
      }
    },
    tableClick(item) {
      this.$router.push({ path: item.path });
    },
    loginOut() {
      loginOutAPI()
        .then((res) => {
          if (res.status == 1) {
            localStorage.setItem("loginflag", 1);
            localStorage.removeItem("platfro");
            this.fmCookies = ""
            this.myCookieValue = this.$utils.getCookie(this.$glb.fmCookieName);
            this.$store.commit("clearUserData"); //重置缓存
            window.location.reload();
          }
        })
        .catch((err) => {});
    },
    handleClickPsw() {
      this.changePassFlag = true;
    },
    getAllRouteList(routes, names) {
      names = names || [];
      routes.forEach((route) => {
        if (route.name) {
          names.push({
            name: route.name,
            requireLogin: route.meta.requireLogin,
          });
        }
        if (route.children) {
          this.getAllRouteList(route.children, names);
        }
      });
      return names;
    },
    clearCookie() {
      this.$utils.clearCookie(this.$glb.fmCookieName);
      this.myCookieValue = this.$utils.getCookie(this.$glb.fmCookieName);
    },
    toVip() {
      this.$router.push({
        name: "vip",
      });
    },
    goDrive() {
      this.$router.push({
        path: "/" + this.$store.state.fmPath,
      });
    },
    getVipAdv() {
      navTopAdInfo().then((res) => {
        if (res.status == "1") {
          this.vipList = res.data.vlist;
          this.vipAdvTitle = res.data.title;
        }
      });
    },
    openVip() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      window.open("/vip");
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
              this.$refs.bindPhoneCom?.onFocus();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(phone)) {
        this.$toast("请正确输入手机号", 3);
        return true;
      }
      this.getPhoneAly();
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);

          if (this.$store.state.bindPhoneCallback) {
            this.$store.state.bindPhoneCallback();
          }
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
  },
  components: {
    RaceNotice,
    changePassDia,
    HoverPop,
    BindPhone,
    userNews,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
