<template>
    <el-dialog
        title="提示"
        :visible.sync="isShow"
        width="480px"
        custom-class="UpgradeDialog" :append-to-body="true" :close-on-click-modal="false">
        <div class="dialog-body">
            <div class="d-header">
                <div class="left">
                    <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                    <span>提示</span>
                </div>
                <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
            </div>
            <!-- <div class="d-content" v-show="showContent==1">
                <p class="title">
                    {{vipLimitInfo.vip_name}}每日 {{vipLimitInfo.download_limit_day_count}} 次<span>下载额度用尽</span>
                    </p>
                <p class="txt">
                    {{vipLimitInfo.vip_update=='1' ?
                     '升级会员继续享受极速下载':'明日继续享受极速下载'}}
                </p>
            </div>
            <div class="d-content" v-show="showContent == 2">
                <p class="title">
                    剩余福利点不足，<span>请开通/升级会员</span>
                </p>
                <p class="txt">
                    当前剩余 {{consumeInfo.point}} 福利点、剩余 {{ consumeInfo.download_limit }} 次下载额度
                </p>
            </div>
            <div class="d-content" v-show="showContent == 3">
                <p class="title">
                    确认<span>消耗 {{popup_type=='1' ? '1' : consumeInfo.current_point}} {{popup_type=='1' ? '次下载额度' : '福利点'}}</span> {{ checkPopupType=='2' ? '转存此文件？':'下载此文件？'}}</p>
                <p class="txt">
                    当前剩余 {{popup_type=='1' ? consumeInfo.download_limit : consumeInfo.point}} {{popup_type=='1' ? '次下载额度' : '福利点'}}
                </p>
            </div> -->
            <div class="d-content">
                <p class="title" v-html="consumeInfo.title">
                   </p>
                <p class="txt">
                    {{ consumeInfo.content }}
                </p>
            </div>
            <div class="d-footer" v-show="showContent==1">
                <fm-button v-show="consumeInfo.vip_update=='1'"  type="primary" size="small" @click="govip">升级会员</fm-button>
                <fm-button v-show="consumeInfo.vip_update=='1'"  type="info" plain size="small" @click="handleClose">不了，谢谢</fm-button>
                <fm-button v-show="consumeInfo.vip_update=='0'" type="primary" size="small" @click="handleClose">好的，谢谢</fm-button>
            </div>
            <div class="d-footer" v-show="showContent==2">
                <fm-button type="primary" size="small" @click="govip">开通/升级会员</fm-button>
            </div>
            <div class="d-footer" v-show="showContent==3">
                <fm-button type="primary" size="small" @click="confirm">确 认</fm-button>
                <fm-button @click="handleClose" type="info" plain size="small" >取 消</fm-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        consumeInfo:{
            type:Object
        },
        showContent:{ //1额度用尽 
            type:Number
        },
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('confirm')
        },
        govip(){
           window.open('/vip')
        }
    }
}
</script>

<style lang="scss" scoped>
    .UpgradeDialog{
        .title{
            margin-top: 32px;
            margin-bottom: 12px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            span{
                color: #F67942;
            }
        }
        .txt{
            line-height: 22px;
            margin-bottom: 24px;
            text-align: center;
        }

        .d-footer{
            .is-plain{
                margin-left: 12px;
            }
        }

    }
</style>