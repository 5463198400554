import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

export function limitationDelAPI(data) {
  return request({
    url: "/user-service/passport/limitationDel",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

// 客户端同步登录
export function clientOssLoginAPI(data) {
  return request({
    url: "/user-service/passport/clientOssLogin",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).then((res) => {
    res.data = decrypt(res.data);
    return res;
  }).catch((err) => {});
}

export function wxinfoAPI() {
  return request({
    url: "/user-service/passport/wxinfo",
    method: "post",
  })
  .then((res) => {
    res.data = decrypt(res.data);
    return res;
  })
  .catch((err) => {});
}

export function getqrcodeAPI() {
  return request({
    url: "/user-service/passport/getQrCode",
    method: "post",
  }).catch((err) => {});
}

export function phoneLoginCodeAPI(data) {
  return request({
    url: "/user-service/passport/phoneLoginCode",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

export function checkqrNewAPI(data) {
  return request({
    url: "/user-service/passport/checkQrLogin",
    method: "post",
    data,
  }).catch((err) => {});
}
//注册接口
export function phoneRegLoginAPI(data) {
  return request({
    url: "/user-service/passport/register",
    method: "post",
    data,
  }).catch((err) => {});
}

export function switchControlAPI(data) {
  return request({
    url: "/user/switchControl",
    method: "post",
    data,
  }).catch((err) => {});
}

export function userLoginVerifyAPI(data) {
  return request({
    url: "/user-service/passport/userLoginVerify",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

export function loginNewAPI(data) {
  return request({
    url: "/user-service/passport/login",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

export function registerAPI(data) {
  return request({
    url: "/user-service/passport/register",
    method: "post",
    data,
  }).catch((err) => {});
}

export function forgetSendcodeAPI(url, data) {
  return request({
    url,
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}
export function findcheckAPI(url, data) {
  return request({
    url,
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

export function resetPasswordAPI(data) {
  return request({
    url: "/user-service/passport/resetPassword",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}

export function getCaptnAPI(data) {
  return request({
    url: "/user/index",
    method: "post",
    data,
  }).catch((err) => {});
}

export function loadAPI(data) {
  return request({
    url: "/user-service/common/indexBgList",
    method: "post",
    data,
  })
  .then((res) => {
    res.data = decrypt(res.data);
    return res;
  })
  .catch((err) => {});
}

//获取国家区号getPhonePcode
export function getPhonePcodeAPI(data) {
  return request({
    url: "/user-service/common/getPhonePcode",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {});
}


export function getUrl(url,data) {
  return request({
    url: url+`&token=${data.token}`,
    method: "get"
  }).catch((err) => {});
}

export function getSyncLogin(data) { // 获取同步登陆数组接口
  return request({
    url: "/user-service/passport/getSyncLogin",
    method: "post",
    data
  })
  .then((res) => {
    res.data = decrypt(res.data);
    return res;
  })
  .catch((err) => {});
}

export function loginOutAPI(url,data) {
  return request({
    url: "/user-service/passport/loginOut",
    method: "post",
    data
  }).catch((err) => {});
}

