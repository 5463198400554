<template>
  <div class="login" :style="'backgroundImage:url(' + imgBg + ')'">
    <div class="navBar">
      <div class="navContent">
        <div class="fmInfo" @click="toHome()">
          <img class="logo" :src="$utils.getPng('web4/icon_loog10')" alt="" />
          <svg-btn class="logo1" icon-class="logo-fm"></svg-btn>
        </div>
        <div class="fmInfoRight">
          <div class="item" @click="toHome()">首页</div>
          <div class="item" @click="toLogin()">云盘</div>
          <div class="item" @click="tovip()">会员</div>
          <div class="item" @click="tollz()">推广返利</div>
        </div>
      </div>
    </div>
    <div class="downContent">
      <div class="boxContent" v-if="step == 1">
        <div class="topret" @click="toLogin()">
          <img class="imgret" :src="$utils.getPng('web4/ret')" alt="" />
          <div class="rettit">返回</div>
        </div>
        <div class="bindBox">
          <div class="bindtit">确定绑定飞猫盘账号</div>
          <div class="bindtit1">
            您的{{ fastType == "qq" ? "QQ" : "微信" }}尚未绑定飞猫盘账号
          </div>
          <el-button
            class="down-button"
            style="margin-top: 100px"
            @click="step = 2"
          >
            绑定已有账号
          </el-button>
          <div
            class="down-button-bb"
            style="margin-top: 16px"
            @click="step = 2"
          >
            注册新账号
          </div>
        </div>
      </div>
      <div class="boxContent step2" v-if="step == 2">
        <div class="topret" @click="step = 1">
          <img class="imgret" :src="$utils.getPng('web4/ret')" alt="" />
          <div class="rettit">返回</div>
        </div>
        <div class="bindBox2">
          <div class="bindphoneTit">验证手机号绑定</div>
          <div class="bindphoneTit1">请填写需要绑定/注册的手机号</div>
          <div class="phoneBoxeFirst" style="margin-top: 32px">
            <el-select class="elSelect" :popper-append-to-body= "false" v-model="loginBox.pcode" slot="suffix">
              <div
                class="choosecountry"
                style="color: #8a8b92; margin: 10px 0 12px 20px"
              >
                选择国家或地区
              </div>
              <el-option
                :label="item.tel"
                :value="item.tel"
                v-for="item in cty"
                :key="item.tel_id"
                style="
                  width: 368px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                {{ item.name }}
                <span>{{ item.tel }}</span>
              </el-option>
            </el-select>
            <el-input
              :class="{ 'is-error': showError == 1 }"
              v-model="loginBox.phoneNumber"
              placeholder="请输入手机号"
              @keyup.enter.native="loginclick2($event)"
              @blur="inputblur"
              :clearable="true"
            >
            </el-input>
          </div>
          <el-button
            style="margin-top: 16px"
            :class="buttonDisabled_1 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_1"
            @click="loginclick2"
            :loading="btnloading_1"
          >
            下一步
          </el-button>
          <div class="havePhone">
            已有账号？<span class="loginbyzh" @click="step = 4"
              >登录账号绑定</span
            >
          </div>
        </div>
      </div>
      <div class="boxContent step3" v-if="step == 3">
        <div class="topret" @click="step = 2">
          <img class="imgret" :src="$utils.getPng('web4/ret')" alt="" />
          <div class="rettit">返回</div>
        </div>
        <div class="yzBox">
          <div class="yzBoxTit">验证手机号</div>
          <div class="yzBoxTit1" style="height: 48px">
            请输入发送至<span
              style="color: #2c2c2c; margin: 0 7px; font-weight: 500"
              >{{ loginBox.pcode
              }}{{
                loginBox.phoneNumber.slice(0, 3) +
                "******" +
                loginBox.phoneNumber.slice(9, 11)
              }}</span
            >的 6 位验证码，有效期 3 分钟
          </div>

          <VerificationCode
            ref="ver"
            @next="next"
            @changeload="changeload"
            :error="reload"
          ></VerificationCode>
          <div class="sendAgain1 pointer" v-if="time === 60" @click="showhk()">
            重新获取验证码
          </div>
          <div class="sendAgain" v-if="time < 60">{{ time }}s后重新获取</div>
          <el-button
            style="margin-top: 40px"
            :class="buttonDisabled_2 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_2"
            :loading="btnloading_2"
            @click="nextApi()"
            >下一步</el-button
          >
        </div>
      </div>
      <div class="boxContent step3" v-if="step == 4">
        <div class="topret" @click="step = 1">
          <img class="imgret" :src="$utils.getPng('web4/ret')" alt="" />
          <div class="rettit">返回</div>
        </div>
        <div class="yzBox">
          <div class="yzBoxTit">登录账号绑定</div>
          <div class="yzBoxTit1">请登录需要绑定的账号及密码</div>
          <div class="userBoxSec">
            <div class="phoneBoxe">
              <el-input
                :class="{ 'is-error': showError == 5 }"
                v-model="loginBox.zhanghao"
                :placeholder="placeholder"
                @blur="inputblur"
              >
                <el-select
                  class="elSelect"
                  popper-class= "selectStyle" 
                  :popper-append-to-body= "false"
                  v-model="loginBox.pcode1"
                  slot="suffix"
                  @change="selectChange"
                >
                  <el-option
                    :label="item.name"
                    :value="item.name"
                    v-for="item in listType"
                    :key="item.id"
                    style="
                      width: 156px;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-input>
            </div>
            <div class="password">
              <el-input
                v-model="loginBox.password"
                :class="{ 'is-error': showError == 6 }"
                placeholder="请输入密码"
                :clearable="true"
                @keyup.enter.native="buttonDisabled_4 ? '' : loginclick($event)"
                type="password"
                :autocomplete="autoType"
                @blur="inputblur"
              >
              </el-input>
            </div>
          </div>

          <el-button
            style="margin-top: 16px"
            :class="buttonDisabled_4 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_4"
            :loading="btnloading_4"
            @click="loginclick()"
            >登录</el-button
          >
          <div class="havePhone">
            手机号已注册？<span class="loginbyzh" @click="step = 2"
              >验证手机号绑定</span
            >
          </div>
        </div>
      </div>
      <!-- 注册 -->
      <div class="boxContent step5" v-if="step == 5">
        <div class="topret" @click="cancelReg()">
          <img class="imgret" :src="$utils.getPng('web4/ret')" alt="" />
          <div class="rettit">返回</div>
        </div>
        <div class="registerBox">
          <div class="regtit">注册</div>
          <div class="regtit1">请设置用户信息</div>
          <el-input
            v-model="regBox.zhanghao"
            placeholder="请设置账号"
            :clearable="true"
            autocomplete="off"
            @blur="inputblur"
          >
          </el-input>
          <el-input
            style="margin-top: 16px"
            v-model="regBox.password"
            placeholder="请设置密码，最少设置 6 位数字/字母"
            :clearable="true"
            autocomplete="off"
            @blur="inputblur"
          >
          </el-input>
          <el-input
            style="margin-top: 16px"
            :class="{ 'is-error': showError == 4 }"
            v-model="regBox.passwordAgain"
            placeholder="请再次输入密码"
            :clearable="true"
            autocomplete="off"
            @blur="inputblur"
            @keyup.enter.native="goApp()"
          >
          </el-input>
          <el-button
            style="margin-top: 16px"
            :class="buttonDisabled_3 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_3"
            :loading="btnloading_3"
            @click="goApp()"
            >下一步</el-button
          >
          <el-input
            style="margin-top: 44px"
            v-model="regBox.yqcode"
            placeholder="邀请码（选填）"
            :clearable="true"
            autocomplete="off"
            @blur="inputblur"
          >
          </el-input>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="reg_ret" top="0" title="提示">
      <div class="quit">
        <img class="pop_icon" :src="$utils.getPng('web4/icon_zc')" />
        <div class="pop_tit">即将完成注册，确认是否退出</div>
      </div>
      <div class="footer">
        <div class="cancel pointer" @click="popConfirm()">确 认</div>
        <div class="confirm pointer" @click="reg_ret = false">取 消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  loadAPI,
  phoneLoginCodeAPI,
  phoneRegLoginAPI,
  getPhonePcodeAPI,
  registerAPI,
  loginNewAPI,
} from "@/utils/apiList/login";
import cty from "@/utils/config/cty2";
import OtherLogin from "@/components/Login/OtherLogin.vue";
import VerificationCode from "@/components/Login/VerificationCode";
import sfile from "@/utils/secretkey/sfile";
export default {
  components: { OtherLogin, VerificationCode },
  data() {
    return {
      cty: [],
      loginBox: {
        phoneNumber: "",
        zhanghao: "",
        pcode: "+86",
        pcode1: "账号",
        logintp: "username",
        password: "",
      },

      listType: [
        {
          id: "username",
          name: "账号",
        },
        {
          id: "email",
          name: "邮箱",
        },
        {
          id: "phone",
          name: "手机",
        },
      ],
      selected: "",
      showError: 0, //showError == 1短信注册，登录，showError ==5 账号，邮箱框登录，showError ==6账号登录密码框，showError == 4 注册页面再次输入密码框，showError == 7 找回密码手机框，showError == 8找回密码邮箱框，
      autoType: "off",
      checked: true,
      step: 1,
      time: 60,
      timerA: "",
      regBox: {
        zhanghao: "",
        passwordAgain: "",
        password: "",
        yqcode: "",
      },
      buttonDisabled_1: false, //buttonDisabled == 1即短信登录注册时候
      buttonDisabled_2: true, //buttonDisabled == 2短信登录获取验证码的时候
      buttonDisabled_3: true, //buttonDisabled == 3注册
      buttonDisabled_4: true, //buttonDisabled == 4账号，邮箱，手机号登录
      buttonDisabled_6: true, //buttonDisabled == 6 重设密码
      btnloading_1: false,
      btnloading_2: false,
      btnloading_3: false,
      btnloading_4: false,
      btnloading_6: false,
      findPassType: 0, //1是邮箱，0是手机
      nctype: "", //阿里云滑块验证类型
      ncdata: [], //阿里云滑块验证回调数据
      funName: "", //调用滑块的方法名 eg：login
      ncInfo: {}, //滑块弹窗数据,
      reload: false,
      phoneCode: "",
      fastId: "",
      fastType: "",
      reg_info: {}, //手机号登录，但是没有这个手机号，进入注册页带的信息
      path_sec: false,
      placeholder: "",
      reg_ret: false,
      imgBg: "",
    };
  },
  watch: {
    loginBox: {
      handler(newval) {
        //手机号登录
        if (this.step == 2) {
          if (newval.pcode == "+86" && newval.phoneNumber.length == 11) {
            this.buttonDisabled_1 = false;
          } else if (newval.pcode != "+86" && newval.phoneNumber) {
            this.buttonDisabled_1 = false;
          } else {
            this.buttonDisabled_1 = true;
          }
        }
        //账号，邮箱密码登录
        if (this.step == 4) {
          if (newval.zhanghao && newval.password.length >= 6) {
            this.buttonDisabled_4 = false;
          } else {
            this.buttonDisabled_4 = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    regBox: {
      handler(newval) {
        if (
          newval.zhanghao &&
          newval.password.length >= 6 &&
          newval.passwordAgain.length >= 6
        ) {
          this.buttonDisabled_3 = false;
        } else {
          this.buttonDisabled_3 = true;
        }
      },
      deep: true,
    },
    step: {
      handler(newval, oldval) {
        this.phoneCode = "";
        this.$refs?.ver?.reset(); //（将验证码重置方法）
        this.inputblur();
        if (newval == 2 && oldval != 3) {
          this.loginBox = {
            phoneNumber: "",
            zhanghao: "",
            pcode: "+86",
            pcode1: "账号",
            logintp: "username",
            password: "",
          };
        }
        if (newval == 4) this.selectChange("账号");
        if (newval == 5) {
          sessionStorage.getItem(this.loginBox.phoneNumber)
            ? (this.regBox = {
                ...JSON.parse(
                  sessionStorage.getItem(this.loginBox.phoneNumber)
                ),
              })
            : (this.regBox = {
                zhanghao: "",
                passwordAgain: "",
                password: "",
                yqcode: "",
              });
        }
      },
    },
  },
  created() {
    this.getPhonePcode();
    document.addEventListener("visibilitychange", this.handleVisiable);
    // 获取上一个页面的路径 ，登录成功后返回如果没得上个路径，跳转至home
    let path_url = this.$route.query.path ? this.$route.query.path : "home";
    //（分享）文件下载中途退出，或者更换浏览器，（获取取件码到登录页 登录成功后返回文件下载页并将参数带过去）
    let index = path_url && path_url.indexOf("/");
    let url = sessionStorage.getItem("obj");
    //判断是否带取件码
    var is_pud = url && url.indexOf("pucode");
    if (is_pud === -1) {
      this.ishave = false;
      var pucode2 = "";
      var pucode = url && url.slice(url.indexOf("#"));
    } else {
      this.ishave = true;
      var pucode2 = url && url.slice(url.indexOf("=") + 1, url.indexOf("#"));
      var pucode = url && url.slice(url.indexOf("=") + 1);
    }

    this.pucode = pucode;
    this.pucode2 = pucode2;

    const fasturl = window.location.search.substring(1);
    if (fasturl) {
      this.fastId = this.getQueryString(fasturl).id;
      this.fastType = this.getQueryString(fasturl).tp;
    }

    if (this.fastId && this.fastType) {
      this.$toast("微信/QQ未注册账号，请使用手机号登录绑定/注册", 2);
    }

    if (path_url.indexOf("/") == -1) {
      this.path = path_url;
    } else {
      this.path = path_url.slice(0, index);
      this.path_id = path_url.slice(index + 1);
      this.path_sec = true; //是否为二级页面的返回（文件下载页）
    }
    this.path =
      this.path == "ph" || this.path == "pubContent" ? "home" : this.path;

    // let logintp = this.$utils.getCookie("logintp");
    // let username = this.$utils.getCookie("username");
    // let password = "";
    // password = this.$utils.getCookie("password")
    //   ? window.atob(this.$utils.getCookie("password"))
    //   : "";
    let logintp = localStorage.getItem("logintp");
    let username = localStorage.getItem("username");
    let password = localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "";
    this.loginBox.logintp = logintp;
    this.loginBox.zhanghao = username;
    this.loginBox.password = password;
    this.getStatic();
  },
  beforeDestroy() {
    this.time = 60;
    this.timerA && clearInterval(this.timerA);
    document.querySelectorAll(".geetest_close").forEach((item) => {
      item.click();
    });
  },
  methods: {
    //获取区号
    getPhonePcode() {
      getPhonePcodeAPI({ type: 3 }).then((res) => {
        let plaintext = this.$utils.rd(res.data.ak, unescape(sfile));
        let obj = this.$utils.secret(res.data.ed, plaintext, true);
        let formObj = JSON.parse(obj);
        this.cty = formObj.list;
      });
    },
    toLogin() {
      this.$router.push({
        name: "login",
        params: {
          step: 7,
        },
      });
    },

    link2(url, data = {}) {
      let pucode = this.pucode;
      pucode
        ? this.ishave
          ? this.$router.replace({
              path: url,
              query: {
                pucode1: decodeURIComponent(pucode),
                pucode: this.pucode2,
              },
            }).catch(() =>{})
          : this.$router.replace({
              path: url,
              query: {
                pucode1: decodeURIComponent(pucode),
              },
            }).catch(() =>{})
        : this.$router.replace({ path: url, data }).catch(() =>{});
    },
    selectChange(value) {
      this.loginBox.logintp = "";
      this.loginBox.zhanghao = "";
      this.loginBox.password = "";
      // let logintp = this.$utils.getCookie("logintp");
      // let username = this.$utils.getCookie("username");
      // let password = "";
      // password = this.$utils.getCookie("password")
      //   ? window.atob(this.$utils.getCookie("password"))
      //   : "";
      let logintp = localStorage.getItem("logintp");
      let username = localStorage.getItem("username");
      let password = localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "";
      this.placeholder =
        value == "账号" ? "请输入" + value : "请输入" + value + "号";
      var value2;
      value == "手机"
        ? (value2 = "phone")
        : value == "邮箱"
        ? (value2 = "email")
        : (value2 = "username");

      this.loginBox.logintp = value2;

      if (value2 == logintp) {
        this.loginBox.logintp = logintp;
        this.loginBox.zhanghao = username;
        this.loginBox.password = password;
      }
    },
    inputblur() {
      this.showError = 0;
    },

    // 短信登录start
    loginclick2(event) {
      if (event) {
        event.target.blur();
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.loginBox.pcode == "+86" &&
        !reg_tel.test(this.loginBox.phoneNumber)
      ) {
        this.$toast("请正确输入手机号", 3);
        this.showError = 1;
        return;
      }
      this.showhk();
      this.btnloading_1 = true;
    },
    showhk() {
      this.nctype = "nc_login";
      this.funName = "sendLoginCode";
      if (this.$store.state.webMessageJYVerifySwitch_set) {
        this.$utils.initgt4(
          "92600b424aabaef6536b47a49ee8b649",
          this.geetcallback
        );
      } else {
        this.geetcallback();
      }
    },
    geetcallback(captchaObj, validate = {}) {
      if (validate == "close") {
        this.closehk();
        return;
      }
      this.captchaObj = captchaObj;
      this.validate = validate;
      if (this.step == 2) {
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.sendCodeNew();
      } else if (this.step == 4) {
        this.loginApp();
      } else if (this.step == 3) {
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.sendCodeNew();
      }
    },
    closehk() {
      this.btnloading_1 = false;
      this.btnloading_4 = false;
      this.showError = 0;
      this.buttonDisabled_1 = false;
      this.btnloading_5 = false;
      this.buttonDisabled_2 = false;
    },
    minute: function () {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    changeload(val) {
      if (this.step == 3) {
        if (val == true) {
          this.buttonDisabled_2 = true;
        } else {
          this.buttonDisabled_2 = false;
        }
      }
    },
    sendCodeNew(captcha_verify) {
      return new Promise((resolve) => {
        this.btnloading_1 = true; //btn上的loading
        this.reload = false;
        let params = {
          pcode: this.loginBox.pcode,
          phone: this.loginBox.phoneNumber,
          ver_type: 2,
          code_type: 0,
          lot_number: this.validate.lot_number,
          captcha_output: this.validate.captcha_output,
          pass_token: this.validate.pass_token,
          gen_time: this.validate.gen_time,
          captcha_verify:captcha_verify||'',
          is_newWEB: 2, //后端需要增加的标识，防止在其他地儿有相同接口调用
        };
        phoneLoginCodeAPI(params)
          .then((res) => {
            this.btnloading_1 = false; //btn上的loading

            if (res.status == 1) {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 1);
              let plaintext = this.$utils.rd(res?.data?.ak, unescape(sfile));
              let obj = this.$utils.secret(res?.data?.ed, plaintext, true);
              let formObj = obj && JSON.parse(obj);
              this.loginBox.msgid = formObj.msgid;
              if (this.time === 60) {
                this.timerA = setInterval(() => {
                  this.minute();
                }, 1000);
              }
              this.step = 3;
              this.phoneCode = "";
              this.$refs?.ver.reset(); //（将验证码重置方法）
            }
            if(res.status == 90001){
              resolve({
                  result: false,
                  validate: false
                })
              this.$toast(res.msg, 2);
                this.$AliCode.handleOpen(this.sendCodeNew)
              }
            if (res.status == 0) {
              this.$toast(res.msg, 2);
              resolve({
                result: true,
                validate: false,
              });
            }
            if (res.status == 4000) {
              
               resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      });
    },
    goNext() {
      this.path_sec
        ? this.link2("/" + this.path + "/" + this.path_id)
        : this.$router.replace({ name: this.path }).catch(() =>{});
    },
    next(val) {
      this.btnloading_2 = true;

      const str = val && val.toString().replace(/,/g, "");
      this.phoneCode = str;
      this.nextApi();
    },
    nextApi() {
      let params = {
        pcode: this.loginBox.pcode,
        phone: this.loginBox.phoneNumber,
        msgid: this.loginBox.msgid,
        ucode: this.phoneCode,
        tp: this.fastType,
        id: this.fastId,
      };
      phoneRegLoginAPI(params)
        .then((res) => {
          this.btnloading_2 = false;
          if (res.status == 1) {
            //  取消登录成功的提示
            this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
            this.goNext();
          } else if (res.status == 2) {
            //注册
            this.$utils.logWayRecord(140, "phone_" + this.loginBox.phoneNumber);
            this.step = 5;
            let reg_info = {
              pcode: this.loginBox.pcode,
              phone: this.loginBox.phoneNumber,
              code: this.phoneCode,
              msgid: this.loginBox.msgid,
            };
            this.reg_info = reg_info;
          } else {
            this.reload = true; // 需要重置滑块 代表验证未通过给验证码输入框置红
            if (res.msg === "请进行重新验证") {
              this.step = 1;
            }
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    goApp() {
      if (this.regBox.password !== this.regBox.passwordAgain) {
        this.$toast("密码不一致，请重试", 2);
        this.showError = 4;
        return;
      }
      this.register();
      //this.showhk();
    },
    //注册
    register() {
      let data = this.reg_info;
      if (!this.regBox.password) {
        this.$toast("请输入密码", 2);
        return;
      }
      this.btnloading_3 = true;
      let params = {
        invitecode: this.regBox.yqcode ? this.regBox.yqcode : "",
        ...data,

        username: this.regBox.zhanghao,
        password: this.regBox.password,
        cpassword: this.regBox.passwordAgain,
        ver_type: 2,
        lot_number: this.validate.lot_number,
        captcha_output: this.validate.captcha_output,
        pass_token: this.validate.pass_token,
        gen_time: this.validate.gen_time,
        tp: this.fastType,
        id: this.fastId,
      };

      registerAPI(params)
        .then((res) => {
          this.btnloading_3 = false;
          if (res.status == 1) {
            this.$utils.logWayRecord(142, "phone_" + this.loginBox.phoneNumber);
            //注册成功status==1
            sessionStorage.removeItem(this.loginBox.phoneNumber);
            this.goNext();
            this.$toast(res.msg, 1);
          } else {
            this.reload = true; // 需要重置滑块
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    loginclick(event) {
      if (event) {
        event.target.blur();
      }
      this.autoType = "new-password";
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var pattern =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      //取消手机号的判断正则，国外手机号无法判断
      // if (this.login.pcode == "手机号" && this.login.pcode == "+86" && !reg_tel.test(this.login.zhanghao)) {
      //   this.toastTit = "请正确输入手机号";
      //   this.toastSuc = 2;
      //   this.showtoast = true;
      //   this.toastType = 1;
      //   return;
      // }
      if (
        this.loginBox.pcode1 == "邮箱" &&
        !pattern.test(this.loginBox.zhanghao)
      ) {
        this.$toast("请正确输入邮箱号", 2);
        return;
      }
      this.showhk();
      this.btnloading_4 = true;
    },
    //手机号 邮箱 账号登录start
    loginApp() {
      let params = {
        ver_type: 2,
        // seid: this.ncdata.sessionId,
        // to: this.ncdata.token,
        // sig: this.ncdata.sig,
        // sence: this.ncdata.scene,
        lot_number: this.validate.lot_number,
        captcha_output: this.validate.captcha_output,
        pass_token: this.validate.pass_token,
        gen_time: this.validate.gen_time,
        username: this.loginBox.zhanghao,
        password: this.loginBox.password,
        logintp: this.loginBox.logintp,
        // checked true-1记住密码，false-0不记录
        ifauto: this.checked === true ? 1 : 0,
        tp: this.fastType,
        id: this.fastId,
      };
      var ak = this.$utils.genak();
      var ed = this.$utils.re(ak, unescape(this.$pfile)); //秘钥
      var jt = this.$utils.secret(params, ak); //参数

      loginNewAPI(params)
        .then((res) => {
          this.btnloading_4 = false;

          if (res.status) {
            let plaintext = this.$utils.rd(res.data.ak, unescape(this.$sfile));
            let obj = this.$utils.secret(res.data.ed, plaintext, true);
            let formObj = JSON.parse(obj);

            if (formObj && formObj.length > 0) {
              formObj.forEach((item) => {
                this.$api.get2(item.url, { token: item.token }, (res) => {});
              });
            }

            if (this.checked) {
              localStorage.setItem("username", this.loginBox.zhanghao);
              localStorage.setItem("logintp", this.loginBox.logintp);
              localStorage.setItem("password", this.loginBox.password);
            } else {
              // this.$utils.setCookie("username", "");
              // this.$utils.setCookie("password", "");
              localStorage.setItem("username", "");
              localStorage.setItem("password", "");
            }
            this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
            setTimeout(() => {
              this.goNext();
            }, 1000);
          } else {
            this.reload = true; // 需要重置滑块
            if (res.msg === "请进行重新验证") {
              this.step = 1;
            }
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    gozuce() {
      this.loginBox.phoneNumber = "18890056789";
      if (sessionStorage.getItem(this.loginBox.phoneNumber)) {
        let info = JSON.parse(
          sessionStorage.getItem(this.loginBox.phoneNumber)
        );
        this.regBox = { ...info };
      }
    },
    popConfirm() {
      sessionStorage.setItem(
        this.loginBox.phoneNumber,
        JSON.stringify(this.regBox)
      );
      this.step = 1;
      this.reg_ret = false;
    },
    cancelReg() {
      this.reg_ret = true;
    },
    // 获取url值
    getQueryString(query) {
      // 多个值
      if (query.indexOf("&") !== -1) {
        var code = query.split("&");
        var obj = {};
        for (let v of code) {
          let v2 = v.split("=");
          obj[v2[0]] = v2[1];
        }
        return obj;
      } else {
        // 一个值
        const code = query.split("=");
        let obj = {};
        obj[code[0]] = code[1];
        return obj;
      }
    },
    toHome() {
      window.open("/", "_blank");
    },
    tovip() {
      window.open("/vip", "_blank");
    },
    tollz() {
      window.open(`${this.$glb.goLLZ}`, "_blank");
    },
    getStatic() {
      loadAPI()
        .then((res) => {
          this.imgBg = res.data.list[res.data.list.length - 1].imgUrl;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
::v-deep .selectStyle {
  left: 0px !important;
  .popper__arrow{
    left: 35px !important;
  }
}
::v-deep .el-select-dropdown {
  background: #ffffff !important;
  .popper__arrow{
    border-bottom-color: #EBEEF5 !important;
    &::after{
      border-bottom-color: #FFF !important;
    }
  }
}
.button_icon {
  height: 29px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_icon:hover {
  padding: 0 16px;
  background: #f7f7f7;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
}
.button_icon:active {
  padding: 0 16px;
  background: #f5f5f5;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
}
.el-select-dropdown__item {
  margin: 0 6px;
  padding: 0 14px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}
.el-select-dropdown__item.selected {
  color: #f67942 !important;
  // background: #f7f7f7 !important;
  border-radius: 5px;
}

.el-select-dropdown__item:hover {
  background: #f7f7f7 !important;
  border-radius: 5px;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #2c2c2c !important;
  font-weight: 600;
  font-size: 15px;
  width: 26px;
}
@mixin flex_layout($direction, $justConten, $alignItems) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justConten;
  align-items: $alignItems;
}
@mixin font_style($fontSize, $fontWeight, $color) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color !important;
}

.down-button {
  width: 382px;
  height: 56px;
  background: #2c2c2c;
  border-radius: 28px;
  @include flex_layout(row, center, center);
  @include font_style(18px, 500, #fff);
  border: none;
}

.down-button-disabled {
  opacity: 0.3;
  width: 382px;
  height: 56px;
  background: #2c2c2c;
  border-radius: 28px;
  @include flex_layout(row, center, center);
  @include font_style(18px, 500, #fff);
  border: none;
}
.down-button-disabled:hover {
  background: #2c2c2c;
  opacity: 0.3;
}
.down-button:hover {
  background: #808080;
}
.down-button:active {
  background: #141110;
}
::v-deep .el-button.is-loading {
  background: #2c2c2c;
  opacity: 0.3;
}
.down-button-bb {
  width: 382px;
  height: 56px;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
  background: #fff;
  cursor: pointer;
  @include flex_layout(row, center, center);
  @include font_style(18px, 500, #2c2c2c);
}
.down-button-bb:hover {
  width: 382px;
  height: 56px;
  border-radius: 28px;
  background: #f9f9f9;
  // @include font_style(18px, 500, #fff);
}

.login {
  height: 100vh;
  box-sizing: border-box;
  // background: url("https://webimgfmtest2021.vip.cpolar.top/Public/web/img/webapp/fm2023/main_bg_05.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  .navBar {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px 0px #f5f5f5;
    position: fixed;
    top: 0;
    left: 0;

    .navContent {
      width: 1200px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fmInfo {
        display: flex;
        align-items: center;

        .logo {
          width: 36px;
          height: 36px;
        }
        .logo1 {
          width: 70px;
          height: 30px;
          margin-left:11px;
          color: #2c2c2c;
        }
      }
      .fmInfoRight {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #2c2c2c;
        .item {
          margin-left: 64px;
          cursor: pointer;
        }
      }
    }
  }
  .downContent {
    margin-top: 80px;
    height: calc(100vh - 80px);
    position: relative;
    .topret {
      display: flex;
      align-items: center;
      height: 22px;
      line-height: 22px;
      cursor: pointer;
      width: 50px;
      .imgret {
        width: 8px;
        height: 14px;
      }
      .rettit {
        font-size: 14px;
        font-weight: 400;
        color: #8a8b92;
        margin-left: 4px;
      }
    }
    .boxContent {
      width: 815px;
      height: 550px;
      background: #ffffff;
      box-shadow: 0px 6px 20px 0px rgba(100, 63, 53, 0.06);
      border-radius: 20px;
      border: 1px solid #e1e1e1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      padding: 24px 32px;
      flex-direction: column;
      .havePhone {
        @include flex_layout(row, left, left);
        @include font_style(16px, 400, #8a8b92);
        width: 100%;
        height: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #8a8b92;
        line-height: 24px;
        margin-top: 12px;
        .loginbyzh {
          @include font_style(16px, 400, #f67942);
          height: 24px;
          line-height: 24px;
          cursor: pointer;
        }
      }
      .bindBox {
        width: 100%;
        margin-top: 58px;
        @include flex_layout(column, center, center);
        .bindtit {
          @include font_style(24px, 600, #2c2c2c);
          height: 36px;
          line-height: 36px;
        }
        .bindtit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin-top: 20px;
        }
      }
      .bindBox2 {
        width: 382px;
        margin: 8px auto;
        @include flex_layout(column, center, left);
        .bindphoneTit {
          @include font_style(22px, 600, #2c2c2c);
          height: 36px;
          line-height: 36px;
        }
        .bindphoneTit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin-top: 8px;
        }
        .phoneBoxeFirst {
          position: relative;
          ::v-deep .el-input {
            .el-input__inner {
              padding-left: 78px;
            }
          }

          .elSelect {
            width: 76px;
            height: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            margin-left: 2px;
            ::v-deep .el-input {
              .el-input__inner {
                width: 76px;
                height: 50px;
                border-radius: 10px;
                border: none;
                padding-left: 0;
                text-align: right;
              }
            }
          }
        }
      }
    }
    .step3 {
      padding: 24px 32px;
      @include flex_layout(column, left, left);

      .registerBox {
        @include flex_layout(column, left, left);
        width: 382px;
        .regtit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }
        .regtit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin: 8px 0 32px 0;
        }
      }
      .yzBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 8px auto;
        .yzBoxTit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }
        .yzBoxTit1 {
          height: 24px;
          padding-right: 8px;
          line-height: 24px;
          @include font_style(16px, 400, #8a8b92);
          margin: 8px 0 32px 0;
        }
        .sendAgain1 {
          @include font_style(16px, 400, #f67942);
          height: 24px;
          line-height: 24px;
          margin-top: 12px;
        }
        .sendAgain {
          @include font_style(16px, 400, #f67942);
          height: 24px;
          line-height: 24px;
          margin-top: 12px;
        }
      }
      .userBoxSec {
        // margin-top: 20px;
        //只给前面有select 框的加paddingleft：130px
        position: relative;
        .phoneBoxe {
          position: relative;
          ::v-deep .el-input {
            .el-input__inner {
              padding-left: 78px;
            }
          }

          .elSelect {
            width: 76px;
            height: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            left: -375px;
            ::v-deep .el-input {
              .el-input__inner {
                width: 76px;
                height: 50px;
                border-radius: 10px;
                border: none;
                padding-left: 0px;
                text-align: right;
              }
            }
          }
        }
        .password {
          margin-top: 16px;
        }
      }
    }
    .step5 {
      padding: 24px 32px;
      @include flex_layout(column, left, left);

      .registerBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 8px auto;
        .regtit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }
        .regtit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin: 8px 0 32px 0;
        }
      }
    }
  }
  //input框的公共样式宽高还有圆角
  ::v-deep .el-input {
    .el-input__inner {
      width: 382px;
      height: 56px;
      border-radius: 10px;
      @include font_style(16px, 400, #2c2c2c);
      border: solid 1px #e5e5e5;
    }
    .el-input__inner:hover {
      border-color: #f67942;
    }
    .el-input.is-active,
    .el-input__inner:focus {
      border-color: #f67942;
    }
    input:-webkit-autofill {
      box-shadow: 0 0 0px 1000px white inset !important;
    }
  }

  ::v-deep .el-dialog {
    width: 480px;
    height: 188px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  ::v-deep .el-input.is-error .el-input__inner {
    border-color: #f53f3f !important;
  }
  ::v-deep .el-dialog__header {
    padding: 20px 24px 10px 24px;
  }
  ::v-deep .el-dialog__title {
    font-size: 16px;
    font-weight: 500;
    color: #2c2c2c;
  }
  ::v-deep .el-dialog__body {
    padding: 18px 24px 24px 24px;
  }
  .quit {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .pop_icon {
      width: 24px;
      height: 24px;
    }
    .pop_tit {
      width: 234px;
      height: 28px;
      line-height: 28px;
      @include font_style(18px, 500, #2c2c2c);
      margin-left: 8px;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 30px;
    .cancel {
      width: 64px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_style(14px, 500, #2c2c2c);
      margin-right: 16px;
    }
    .confirm {
      width: 64px;
      height: 36px;
      background: #f67942;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_style(14px, 500, #fff);
    }
  }
}
::v-deep .el-input {
  .el-input__inner {
    background: #ffffff !important;
    border-radius: 8px;
    border: solid 1px #e5e5e5;
    &:hover {
      border: 1px solid #f67942;
    }
    &:focus {
      border: 1px solid #f67942;
      background: #ffffff;
    }
  }
  .el-input__suffix {
    display: flex;
    align-items: center;
    .el-icon-error {
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
