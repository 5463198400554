<template>
  <!-- 解绑qq，微信 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="unBindFlag"
    :append-to-body="true"
    class="changeName"
    top="0vh"
  >
    <div class="diaBox1">
      <div class="dialogTop">
        <div class="left">
          <img
            class="icon"
            :src="$utils.getPng('web4/icon-circle-fill')"
            alt=""
            srcset=""
          />
          <div class="tit1">确认解绑</div>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="dialogCenter">
        <div class="typeTit">
          {{
            !isBindPhone
              ? `该${type}账号是您目前登录飞猫盘的主要方式，设置手机号后可以进行解绑操作`
              : `解绑${type}账号后将无法继续使用它登录该飞猫盘账号`
          }}
        </div>
        <div class="bottom">
          <fm-button
            type="primary"
            size="medium"
            style="margin-right: 12px"
            @click="confirm(isBindPhone)"
            >{{ !isBindPhone ? "去绑定" : "确 认" }}</fm-button
          >
          <fm-button
            type="info"
            size="medium"
            @click="handleClose"
            >取 消</fm-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { unBindQQAPI, unBindWxAPI } from "@/utils/apiList/account";
export default {
  props: {
    unBindFlag: {
      type: Boolean,
      default: false,
    },
    isBindPhone: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "QQ",
    },
  },
  data() {
    return {};
  },
  methods: {
    confirm(isBindPhone) {
      if (isBindPhone) {
        this.type == "QQ"
          ? unBindQQAPI()
              .then((res) => {
                if (res.status == 1) {
                  this.handleClose();
                  this.$toast(res.msg, 1);
                  this.$parent.getUserInfo();
                }else{
                  this.handleClose();
                  this.$toast(res.msg, 2);
                }
              })
              .catch((err) => {})
          : unBindWxAPI()
              .then((res) => {
                if (res.status == 1) {
                  this.handleClose();
                  this.$toast(res.msg, 1);
                  this.$parent.getUserInfo();
                }else{
                  this.handleClose();
                  this.$toast(res.msg, 2);
                }
              })
              .catch((err) => {});
      } else {
        this.$parent.changePhone();
      }
    },
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.changeName {
  ::v-deep .el-dialog {
    width: 480px;
    height: auto;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    @include useTheme {
      background: getVar("text-color3-ffffff");
      color: getVar("text-color1-2c2c2c");
    }
    border-radius: 16px;
    .el-dialog__body {
      padding: 0;
    }
  }

  .diaBox1 {
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 10px 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
      }
    }
    .dialogCenter {
      padding: 20px 24px;
      .typeTit {
        // height: 24px;
        font-size: 16px;
        font-weight: 400;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
        line-height: 24px;
        // text-align: center;
      }

      .bottom {
        width: 100%;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
          width: 110px;
          height: 36px;
          margin: 0 8px;
        }
        .btn1 {
          background: rgba(246, 121, 66, 0.1);
          color: #f67942;
          &:hover {
            background: #f67942;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
