<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="baseDialog"  :append-to-body="true"  :close-on-click-modal="false">
    <div class="dialog-body">
        <div class="d-header">
            <div class="left">
                <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                <span>{{title}}</span>
            </div>
            <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="d-content">
            <span>
                {{text}}
            </span>
        </div>
        <div class="d-footer">
            <fm-button @click="confirm" type="primary" size="small"><slot></slot></fm-button>
            <fm-button @click="handleClose" type="info" plain size="small" v-show="showCancel">取 消</fm-button>
        </div>
    </div>
    </el-dialog>
</template>

<script>
export default {
    name:'base',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        text:{
            type:String,
            default:''
        },
        showCancel:{
            type: Boolean,
            default: false
        },
        title:{
            type:String,
            default:''
        }
    },  
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss">
    @import "./dialog.scss";
</style>