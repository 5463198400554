<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/PreviewPublic/index.vue
 * @Description: 预览组件
-->
<template>
  <div
    class="preview_wrapper"
    :class="dialogshow ? 'wrapp' : ''"
    v-show="dialogVisible"
    @click="showHandleBox = false"
  >
    <div class="previewDialog">
      <div class="PreviewPublic" @click="isShowVolume = false">
        <div class="top">
          <div class="close"></div>
          <el-tooltip
            popper-class="item preview-tooltip"
            effect="dark"
            placement="bottom-end"
          >
            <div class="tit">
              {{ handleItem.name }}{{ handleItem.extension }}
            </div>
            <div slot="content" class="content">
              {{ handleItem.name }}{{ handleItem.extension }}
            </div>
          </el-tooltip>
          <fm-button
            @click="handleClose"
            type="info"
            size="small"
            text
            icon="icon-model-close"
          ></fm-button>
        </div>
        <div
            id="preview-center"
          class="center"
          :class="previewCls"
        >
          <!--zip -->
          <div class="zipBox" v-show="fileType == 1">
            <div class="step1" v-show="step == 1">
              <img class="imga" :src="handleItem.ext_icon" alt="" />
              <div class="name">
                {{ handleItem.name }}{{ handleItem.extension }}
              </div>
              <div class="size">{{ handleItem.size }}</div>
              <fm-button
                v-loading="upLoading"
                type="primary"
                round
                size="medium"
                style="width: 96px"
                @click="upLoading ? '' : queryZipInfoTask()"
                >打 开</fm-button
              >
            </div>
            <div class="step2" v-show="step == 2">
              <div class="file-content">
                <div class="router-box">
                  <div class="file-directory">
                    <el-tooltip
                      class="item r-tooltip"
                      effect="dark"
                      content="全部文件"
                      placement="top"
                    >
                      <span @click="resetRouter" class="pointer" :class="{'allFile-span':path.length > 0}"
                        >全部文件</span
                      >
                    </el-tooltip>
                    <svg-btn
                      icon-class="icon-file-direction"
                      class="allFile-icon"
                      v-show="path.length > 0"
                    />
                    <div class="lessthen4" v-if="path.length < 4">
                      <div
                        class="one"
                        v-for="item in path"
                        :key="item[0]"
                        @click="setFolderRouter(item)"
                      >
                        <el-tooltip :content="item" placement="top">
                          <span class="el-dropdown-link name"> {{ item }}</span>
                        </el-tooltip>
                        <svg-btn icon-class="icon-file-direction" />
                      </div>
                    </div>
                    <div class="lessthen4 morethen4" v-else>
                      <div class="one" @mousemove="showboxBreadMove = true">
                        ...
                        <svg-btn icon-class="icon-file-direction" />
                      </div>
                      <div
                        class="one"
                        v-for="(item, index) in path.slice(
                          path.length - 2,
                          path.length
                        )"
                        :key="index"
                        @click="setFolderRouter(item)"
                      >
                        <el-tooltip :content="item" placement="top">
                          <span class="el-dropdown-link name">
                            {{ item }}
                          </span>
                        </el-tooltip>
                        <svg-btn icon-class="icon-file-direction" />
                      </div>
                    </div>
                    <div
                      class="boxBreadMove"
                      v-if="
                        showboxBreadMove &&
                        path.slice(0, path.length - 2).length > 0
                      "
                      @mouseleave="showboxBreadMove = false"
                    >
                      <div
                        class="boxOne pointer"
                        v-for="(item, index) in path.slice(0, path.length - 2)"
                        :key="index"
                        @click="setFolderRouter(item)"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                  <div class="btns">
                    <fm-button
                      :disabled="allCheck == 0"
                      @click="allCheck == 0 ? '' : doPress(1)"
                      type="info"
                      round
                      size="medium"
                      plain
                      >解压所选文件</fm-button
                    >
                    <fm-button @click="doPress(2)" round size="medium"
                      >全部解压</fm-button
                    >
                  </div>
                </div>
                <div class="file-type">
                  <div class="selectAll">
                    <div
                      @click="
                        allCheck == 2 ? cancelCheckedVal() : setCheckedVal()
                      "
                    >
                      <svg-btn
                        :icon-class="
                          allCheck == 2
                            ? 'icon-file-check'
                            : allCheck == 0
                            ? 'icon-file-uncheck-'+$store.state.theme
                            : 'icon-file-checks'
                        "
                      >
                      </svg-btn>
                    </div>
                    <span v-if="checkedZipFiles.length > 0"
                      >已选 {{ checkedZipFiles.length }} 项文件</span
                    >
                    <span v-else>共 {{ zipFileList.length }} 项文件</span>
                  </div>
                </div>
                <div class="file-list" v-if="zipFileList.length > 0">
                  <div
                    class="file-item-hover"
                    v-for="(item, index) in zipFileList"
                    :key="index"
                    :class="{ selItem: checkedZipFiles.indexOf(index) > -1 }"
                    @click="item.is_dir == '1' ? setFolder(item) : ''"
                  >
                    <div class="file-item">
                        <svg-btn @click.stop="chooseZipFile(index)"
                        v-show="item.is_unzip == '1'"
                          class="icon_checks"
                          :icon-class="
                            checkedZipFiles.indexOf(index) > -1
                              ? 'icon-file-check'
                              : 'icon-file-uncheck-'+$store.state.theme
                          "
                        ></svg-btn>
                      <div
                        v-show="item.is_unzip == '0'"
                        class="checkbox-disable"
                      >
                        <svg-btn
                          class="icon_checks"
                          :icon-class="'icon-checkbox-disable-'+$store.state.theme"
                        ></svg-btn>
                      </div>
                      <img class="icon" :src="item.ext_icon" />
                      <div class="desc">
                        <span class="name">{{ item.name }}</span>
                        <span class="info" v-show="item.is_dir == '0'">
                          <font class="size">大小：{{ item.size }}</font>
                        </span>
                        <span class="info" v-show="item.is_dir == '1'">
                          <font class="size">文件：{{ item.count }}项</font>
                        </span>
                      </div>
                      <span class="tips" v-show="item.is_unzip == '0'"
                        >该文件不支持添加</span
                      >
                    </div>
                  </div>
                  <div class="isOver" style="margin-bottom: 72px">没有更多了</div>
                </div>
                <div class="file-default" v-else>
                  <img :src="$utils.getPng('web4/file_default')" />
                  <div class="search"><span>暂无文件</span></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 图片 -->
          <div class="imgBox" v-show="fileType == 2">
            <div class="box" v-loading="loadingImg">
              <div
                class="changeBtn prev"
                @click="previewImgData.prev == 0 ? '' : getImgUrl(1)"
                :class="{ notallow: previewImgData.prev == 0 }"
              >
                <svg-btn icon-class="icon-prev" />
              </div>
              <div class="errorImg-box" v-show="showErrorImg">
                <svg-btn icon-class="icon-pic-break" />
                <span>已损坏，无法查看</span>
              </div>
              <ElementDrag ref="elementDrag" @updateFlag="updateFlag" v-show="!showErrorImg">
                <img @load="handleImageLoad"
                  ref="maskBox" draggable="false"
                  :class="['img', isFlag ? 'img1' : 'img2']"
                  :src="previewImgData.url"
                  alt=""  @error="imgError"
                />
              </ElementDrag>
              <div
                class="changeBtn next"
                @click="previewImgData.next == 0 ? '' : getImgUrl(2)"
                :class="{ notallow: previewImgData.next == 0 }"
              >
                <svg-btn icon-class="icon-next" />
              </div>
            </div>
            <div class="bottom">
              <div
                v-for="item in picOperate"
                :key="item.id"
                @click="imgHandle(item.id)"
              >
                <svg-btn
                  :icon-class="
                    isFlag && item.id == 3 ? 'icon-pic-operate6' : item.icon
                  "
                ></svg-btn>
                <span :class="['tit', 'tit' + item.id]">{{
                  isFlag && item.id == 3 ? "适应尺寸" : item.name
                }}</span>
              </div>
            </div>
          </div>
          <!-- 音乐 -->
          <div class="musicBox" v-show="fileType == 3">
            <div class="content">
              <div class="imgbox">
                <img
                  class="img"
                  ref="rotate"
                  :src="$utils.getPng('web4/icon_music_'+$store.state.theme)"
                />
              </div>
              <span class="name"
                >{{ handleItem.name }}{{ handleItem.extension }}</span
              >
              <span class="size">{{ handleItem.size }}</span>
            </div>
            <div class="audio-box">
              <div class="a-top">
                <span class="time">{{ audio.currentTime | formatSecond }}</span>
                <div class="progress">
                  <audio
                    ref="audio"
                    id="audio"
                    :src="previewUrl1"
                    @pause="onPause"
                    @play="onPlay"
                    @timeupdate="onTimeupdate"
                    @loadedmetadata="onLoadedmetadata"
                    @error="onError"
                  ></audio>
                  <el-slider
                    @change="progressChange"
                    v-model="audio.currentTime"
                    :format-tooltip="realFormatSecond"
                    :max="audio.maxTime"
                  >
                  </el-slider>
                </div>
                <span class="time">{{ audio.maxTime | formatSecond }}</span>
              </div>
              <div class="a-bottom">
                <img
                  @click="audio.playing ? pause() : play()"
                  class="icon"
                  :src="
                    audio.playing
                      ? $utils.getPng('web4/icon_music_play')
                      : $utils.getPng('web4/icon_music_pause')
                  "
                />
                <img
                  @click.stop="isShowVolume = !isShowVolume"
                  class="icon"
                  :src="
                    audio.volume == 0
                      ? $utils.getPng('web4/icon_music_mute')
                      : $utils.getPng('web4/icon_music_volume')
                  "
                />
              </div>
              <div
                class="volume-box"
                v-show="isShowVolume"
                @click.stop="isShowVolume = true"
              >
                <span class="value">{{ audio.volume }}</span>
                <el-slider
                  :show-tooltip="false"
                  v-model="audio.volume"
                  vertical
                  @input="changeVolume"
                  height="96px"
                >
                </el-slider>
              </div>
            </div>
          </div>
          <!-- 视频 -->
          <div class="videoBox" v-loading="loadingVideo" v-show="fileType == 4">
            <div class="video-container" ref="artVideo"></div>
          </div>
          <!-- 文档 -->
          <div class="pptBox" v-loading="loadDoc" v-show="fileType == 5">
            <div class="content" ref="fullPage">
              <div class="p-left">
                <div
                  class="thumbnail"
                  :class="{ cur_thum: fullpage.current == item.sort }"
                  @click="scrollToPPt(item.sort)"
                  v-for="item in docData"
                  :key="item.sort"
                >
                  <span>{{ parseInt(item.sort) + 1 }}</span>
                  <img :src="item.preview_img" />
                </div>
              </div>
              <div
                class="p-right"
                ref="fullPageContainer"
                @mousewheel="mouseWheelHandle"
                @DOMMouseScroll="mouseWheelHandle"
              >
                <div
                  class="img-box"
                  :ref="'refName' + item.id"
                  v-for="(item, index) in docData"
                  :key="index"
                >
                  <img
                    :src="item.preview_img"
                    @click="fullScreen(item, index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pdfBox" v-loading="loadDoc" v-show="fileType == 6">
            <div class="content">
              <img
                v-for="item in docData"
                :key="item.id"
                :src="item.preview_img"
              />
            </div>
          </div>
          <div class="docBox" v-loading="loadDoc" v-show="fileType == 7">
            <div class="content" :style="{ background: previewUrl2 ? 'unset' : '#fff' }">
              <template v-if="previewUrl2">
                <div id="docView"></div>
              </template>
              <template v-else>
                <img
                    v-for="item in docData"
                    :key="item.id"
                    :src="item.preview_img"
                />
              </template>
            </div>
          </div>
          <!-- txt -->
          <div class="txtBox" v-show="fileType == 8">
            <div class="txt-body" v-loading="loadingTxt">
              <div class="txt-content" @scroll="scrollGetTxt($event)">
                <div class="textData">{{txtData}}</div> 
              </div>
            </div>
          </div>
          <!-- execl -->
          <div class="execlBox" v-show="fileType == 9">
            <div v-loading="loading" class="file-view-content">
              <iframe
                v-if="!['.csv', '.xlsx', '.xls'].includes(handleItem.extension)"
                :src="previewUrl"
                width="100%"
                height="100%"
                allowfullscreen
                frameborder="0"
              ></iframe>
              <div v-else ref="csvTable" class="csv-table">
                <div id="excelCanvasBox"></div>
                <div v-show="msg" class="loadErr">{{ msg }}</div>
              </div>
            </div>
          </div>
          <div v-show="fileLoadMsg" class="loadErr">{{ fileLoadMsg }}</div>
          <!-- 不支持预览 -->
          <div class="notSupportBox" v-show="fileType == 10">
            <img class="icon" :src="handleItem.ext_icon" />
            <span class="name"
              >{{ handleItem.name }}{{ handleItem.extension }}</span
            >
            <span class="desc">暂不支持预览此类文件，请下载至本地打开</span>
            <fm-button round size="medium" @click="goDownload($event)"
              >下载 {{ handleItem.size }}</fm-button
            >
          </div>

          <div class="rightBox" v-show="step != 2">
            <div class="item pointer item-tooltip" @click="goDownload($event)">
              <svg-btn icon-class="icon-down" />
              <span>下载</span>
            </div>
            <div class="item pointer item-tooltip" @click="goShare">
              <svg-btn icon-class="icon-forward" />
              <span>分享</span>
            </div>
            <div class="item pointer" @click.stop="showHandleBox = !showHandleBox">
              <svg-btn
                icon-class="icon-more"
                
              />
              <div class="handleBox" v-show="showHandleBox">
                <div class="handleList">
                  <span
                    v-for="item in handleList"
                    :key="item.id"
                    @click="handleFile(item)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 密码 -->
      <PressPwd
        ref="pressPwd"
        :isShow="showPressPwd"
        @handleClose="closePwdBox"
        @confirm="confirmPwd"
      ></PressPwd>
      <!-- 解压到 -->
      <FileDirDialog
        :isShow="showFileDirDialog"
        :type="dirHandleType"
        :list="folderList"
        :upconfirmLoading="upconfirmLoading"
        :moveNav="moveNav"
        @handleClose="showFileDirDialog = false"
        @changeNav="changeNav"
        @getFolder="getFolderMoveList"
        @doFileDir="doFileDir"
      ></FileDirDialog>
      <!-- 空间已满 -->
      <PromoteDialog
        @handleClose="isShowPromote = false"
        :isShow="isShowPromote"
        :msg="promoteMsg"
        :vip_update="vip_update"
        :type="promoteType"
        :icon="icon"
      >
      </PromoteDialog>
    </div>
  </div>
</template>

<script>
function realFormatSecond(second) {
  var secondType = typeof second;
  if (secondType === "number" || secondType === "string") {
    second = parseInt(second);
    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    return ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
  } else {
    return "00:00";
  }
}
import Artplayer from "artplayer";
import PressPwd from "@/components/Cloud/PressPwd";
import FileDirDialog from "../Dialog/FileDirDialog";
import PromoteDialog from "@/components/Dialog/PromoteDialog";
import ExcelCanvas from "./excelCanvas";
import ElementDrag from "./ElementDrag.vue";
import { formatXlsx, clearUrl } from "./workers/formatXlsx.worker";
import XlsxWorker from "./useXlsxWorker";
import "./excelCanvas.css";
import {
  queryZipInfoTask,
  createUnZipAsyncTask,
  folderMoveList,
  previewAudiovisual,
  previewTxt,
} from "@/utils/apiList/cloud";
export default {
  components: {
    PressPwd,
    FileDirDialog,
    PromoteDialog,
    ElementDrag
  },
  props: {
    handleItem: Object,
    previewImgData: Object,
    loadDoc: Boolean,
    fileType: {
      type: Number,
      default: 1,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    docData: {
      type: Array,
      default: [],
    },
    previewUrl: {
      type: String,
      default: "",
    },
    previewUrl1: {
      type: String,
      default: "",
    },
    loadingVideo:{
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingImg:true,
      videoStyle:{},
      operlist: [
        { id: 1, name: "下载", icon: "icon-down" },
        { id: 2, name: "转发", icon: "icon-forward" },
        { id: 3, name: "更多", icon: "icon-more" },
      ],
      showHandleBox: false,
      handleList: [
        { id: 1, name: "移动" },
        { id: 2, name: "重命名" },
        { id: 3, name: "删除" },
      ],
      picOperate: [
        { id: 1, name: "缩小", icon: "icon-pic-operate1" },
        { id: 2, name: "放大", icon: "icon-pic-operate2" },
        { id: 3, name: "原始尺寸", icon: "icon-pic-operate3" },
        { id: 4, name: "左旋转", icon: "icon-pic-operate4" },
        { id: 5, name: "右旋转", icon: "icon-pic-operate5" },
      ],
      showErrorImg:false,
      isFlag: false,
      deg: 0,
      size: 0,
      scale: "scale(1)",
      scale1:1,
      isMousedown:false,//鼠标是否按下
      moveStart:{x:0,y:0},//拖拽元素初始位置
      translate:{x:0,y:0},
      audio: {
        // 播放状态
        playing: false,
        // 静音状态
        muted: false,
        // 音频当前播放时长
        currentTime: 0,
        // 音量
        volume: 30,
        // 音频最大播放时长
        maxTime: 0,
      },
      cacheCurrent: 0,
      cacheVoice: 1,
      isShowVolume: false,
      instance: null,
      folderList: [],
      fullpage: {
        current: 0, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0, // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      currentFullIndex: 0,
      txtPage: 0,
      showPressPwd: false,
      step: 1,
      zipPwd: "", //解压密码
      zipFileList: [], //压缩包列表
      checkedZipFiles: [], //选中
      allCheck: 0,
      upZipType: 1,
      showboxBreadMove: false,
      path: [], //压缩包列表查询参数 文件加名称路径数组
      routerName: [], //文件夹展示路径
      folderIds: [], //选中文件夹名称数组
      fileIds: [], //选中文件名称数组
      showFileDirDialog: false,
      dirHandleType: 1,
      moveNav: [],
      isShowInputDialog: false, //重命名弹框
      rename: "",
      reextension: "",
      //execl
      loading: false,
      csvData: [],
      csvHeader: [],
      myECanvas: null,
      msg: "",
      fileLoadMsg: "",
      previewUrl2: "",
      xWorker: null,
      upLoading: false,
      upconfirmLoading: false,
      isShowPromote: false,
      vip_update: "",
      promoteMsg: "",
      promoteType: 3,
      boxHeight: 0,
      dialogshow: false,
      currentTime:0,
      txtData: "",
      loadingTxt: true,
      isDetailOver: false,
      videoTime:null,
      isPause:false,
      retryUrl:'',
      previewImgDataList: [],
      icon:''
    };
  },
  computed: {
    previewCls: function () {
      if (this.fileType == 7) {
        return 'preview-doc-bg'
      } else if (this.fileType == 5 || this.fileType == 6) {
        return 'preview-bg'
      }

      return null
    }
  },
  mounted() {
    window.addEventListener("message", this.onIframeMsg);
    this.mousewheelevt = /Firefox/i.test(navigator.userAgent)
      ? "DOMMouseScroll"
      : "mousewheel";
    // 为空间区域绑定鼠标滚轮事件 =》 处理函数是wheelHandle
    // 如果你监听了window的scroll或者touchmove事件，你应该把passive设置为true，这样滚动就会流畅很多
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("message", this.onIframeMsg);
    if (this.myECanvas) {
      this.myECanvas.destroy();
      this.myECanvas = null;
    }
    if (this.xWorker) {
      this.xWorker.destroy();
      this.xWorker = null;
    }
  },
  filters: {
    // 将整数转化成时分秒
    formatSecond(second = 0) {
      return realFormatSecond(second);
    },
  },
  created() {},
  watch: {
    checkedZipFiles: {
      handler(newval, oldVal) {
        let zipFileList = this.zipFileList.filter((item) => item.is_unzip == "1")
        if (newval.length > 0 && newval.length < zipFileList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == zipFileList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    dialogVisible: {
      handler(newval, oldVal) {
        this.fileLoadMsg = ''
        this.previewImgDataList = []
        if (!newval) {
          this.handleClose();
          this.$refs.elementDrag.isHover = false
        }
        if (newval) {
          this.$nextTick(() => {
            this.boxHeight = this.$refs["fullPage"].clientHeight;
            this.dialogshow = true;
          });
          if (this.fileType == 9) {
            this.loading = true;
            this.getFileData();
          }else if(this.fileType == 2){
            this.$refs.elementDrag.isHover = true
          } else {
            if (this.xWorker) {
              this.xWorker.destroy();
              this.xWorker = null;
            }
          }
        }
      },
    },
    previewImgData: {
      handler(newval, oldVal) {
        try {
          let DataList = this.previewImgDataList.some(item => item.file.id == newval.file.id)
          if(!DataList){
            this.previewImgDataList.push(newval)
          }
        } catch (error) {
          
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.dialogshow = false;
      setTimeout(()=>{
        if (this.instance && this.instance.destroy) {
          this.instance.destroy(false);
          this.instance.on('destroy', () => {
            if(this.instance.pip){
              this.instance.pip=false
            }
          })
        }
        this.loadingTxt = true;
        this.isDetailOver = false;
        this.txtData = "";
        this.step = 1;
        this.$refs.pressPwd.value = "";
        this.zipPwd = "";
        this.previewUrl2 = ''
        this.checkedZipFiles=[];
        this.path = [];
        this.upLoading=false;
        this.showHandleBox = false;
        this.showErrorImg=false;
        this.isFlag=false;
        this.txtPage=0;
        this.$refs.elementDrag.resetData();
        this.isPause=false
        this.currentTime=0
        // clearInterval(this.videoTime)
        this.$emit("handleClose");
      },350)

    },
    isFlagChange() {
      this.size = 0;
      this.scale = `scale(1) rotateZ(0deg)`;
      this.isFlag = !this.isFlag;
    },
    handleRotate(type) {
      if (type == 1) {
        this.deg += 90;
      } else {
        this.deg -= 90;
      }
      if (this.deg >= 360) {
        this.deg = 0;
      }
      this.scale = `scale(1) rotateZ(${this.deg}deg)`;
    },
    wheelHandle(e) {
      const ev = e || window.event; // 兼容性处理 => 火狐浏览器判断滚轮的方向是属性 detail，谷歌和ie浏览器判断滚轮滚动的方向是属性 wheelDelta
      // dir = -dir; // dir > 0 => 表示的滚轮是向上滚动，否则是向下滚动 => 范围 (-120 ~ 120)
      const dir = ev.detail ? ev.detail * -120 : ev.wheelDelta;
      //滚动的数值 / 2000 => 表示滚动的比例，用此比例作为图片缩放的比例
      this.imgScaleHandle(dir / 2000);
    },
    imgScaleHandle(zoom) {
      this.size += zoom;
      if (this.size < -0.8) {
        this.size = -0.8;
      }
      this.scale = `scale(${1 + this.size}) rotateZ(${this.deg}deg)`;
    },
    dragMousedown(event) {
      this.moveStart.x = event.clientX
      this.moveStart.y = event.clientY
      this.isMousedown = true
    },
    dragMousemove(event) {
      if(this.isMousedown) {
          this.translate.x += (event.clientX - this.moveStart.x) / this.scale1
          this.translate.y += (event.clientY - this.moveStart.y) / this.scale1
          this.scale = `scale(${this.scale1}) translate(${this.translate.x}px, ${this.translate.y}px)`
          this.moveStart.x = event.clientX
          this.moveStart.y = event.clientY
      }
    },
    imgError(e){
      if(e.type=='error'){
        this.showErrorImg=true
        this.loadingImg=false
      }
    },
    handleImageLoad(e){
      this.$refs.elementDrag.resetData()
      this.isFlag=false
      this.loadingImg=false
    },
    // 视频相关方法 定时器版本
    loadVideo2(option) {
      Artplayer.CONTEXTMENU = false;
      Artplayer.RECONNECT_TIME_MAX = 0;
      this.instance = new Artplayer({
          ...option,
          container: this.$refs.artVideo,
      });
      // 当播放器首次可以播放时触发
      this.instance.on('ready', () => {
        this.loadingVideo=false;
        this.instance.currentTime = this.currentTime;
        if(this.isPause){
          this.instance.pause()
        }else{
          this.instance.play();
        }
        this.videoTime = setInterval(()=>{
          option.expire--
          if(option.expire < 1){
            this.videoTime && clearInterval(this.videoTime);
            this.videoTime = null
            this.currentTime = this.instance.currentTime
            this.getVideoUrl()
          }
        },1000)
      });
      this.instance.on('video:pause',()=>{
        this.isPause=true
        this.instance.on('video:play',()=>{
          this.isPause=false
        })
      })
    },
    // 视频相关方法 监听链接错误版本
    loadVideo(option) {
      Artplayer.CONTEXTMENU = false;
      Artplayer.RECONNECT_TIME_MAX = 1;
      this.instance = new Artplayer({
          ...option,
          container: this.$refs.artVideo,
      });
      // 当播放器首次可以播放时触发
      this.instance.on('ready', () => {
        this.loadingVideo=false;
        this.instance.play();
        this.instance.currentTime = this.currentTime;
      });
      this.instance.on('video:stalled',async (e)=>{
        var isSafari =
          userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 1;
        if (isSafari) {
          return;
        }
        this.retryUrl =  await this.getVideoUrl2();
        this.$nextTick(()=>{
          this.instance.switchQuality(this.retryUrl);
          this.instance.play();
        })
      })
      this.instance.on('video:suspend',async (e)=>{
        var isSafari =
          userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 1;
        if (isSafari) {
          this.retryUrl = await this.getVideoUrl2();
            this.$nextTick(()=>{
              this.instance.switchQuality(this.retryUrl);
              this.instance.play();
            })
          }
      })
      this.instance.on('video:waiting',async (e)=>{
          var isSafari =
          userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 1;
          if (isSafari) {
             this.$nextTick(()=>{
                if(this.instance.currentTime==0){
                  return
                }
                this.currentTime =  this.instance.currentTime
                this.getVideoUrl();
              })
          }
      }),
      this.instance.on('video:error',async(e)=>{
        this.$nextTick(()=>{
          if(this.instance.currentTime==0){
            return
          }
          this.currentTime =  this.instance.currentTime
          this.getVideoUrl();
        })
      })
    },
    getVideoUrl2() {
      return new Promise((resolve) => {
        previewAudiovisual({ file_id: this.handleItem.id }).then( (res) => {
          if (res.status == "1") {
            resolve(res.data.url)
          } else {
            this.$toast(res.msg, 2);
          }
        });
      })
    },
    //获取链接403
    getStatus(videoUrl){
      axios({
          method: 'get',
          url: videoUrl,
      }).then(function(response) {
      }).catch(error => {
        if (error.response) {
          // 请求已经发出，服务器也返回了状态码
          // 可以在这里处理错误状态码和响应数据
          if(error.response.status==403){
            this.getVideoUrl()
          }
        } else if (error.request) {  
          this.getVideoUrl()
          // 请求已经发出，但是没有收到响应  
          // 可以在这里处理请求错误  
          console.log(error.request);  
        } else {  
          // 其他错误，例如网络问题等  
          console.log('Error', error.message);  
        } 
      });
    },
    //重试链接
    getVideoUrl() {
      previewAudiovisual({ file_id: this.handleItem.id }).then((res) => {
        if (res.status == "1") {
          if(this.fileType==4){
            if (this.instance && this.instance.destroy) {
              this.instance.destroy(false);
              this.instance.on('destroy', () => {
              })
            }
            var option = {
              url: res.data.url,
              theme: "#f67942",
              playbackRate: true,
              setting: true,
              pip: true,
              fullscreen: true,
              expire:parseInt(res.data.expire),
            };
            this.$nextTick(() => {
              this.loadVideo(option);
            });
          }
          if(this.fileType==3){
            this.previewUrl1=res.data.url
            this.play()
          }
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    // 音频相关方法
    onError(e){
      if(e.type=='error' && e.srcElement.currentSrc){
        this.getStatus(e.srcElement.currentSrc)
      }
    },
    // 播放音频
    play() {
      this.$refs.audio.play();
      this.$refs.rotate.style.animationPlayState = "running";
    },
    // 暂停音频
    pause() {
      this.$refs.audio.pause();
      this.$refs.rotate.style.animationPlayState = "paused";
    },
    // 当音频播放
    onPlay() {
      this.audio.playing = true;
      this.$refs.rotate.style.animationPlayState = "running";
    },
    // 当音频暂停
    onPause() {
      this.audio.playing = false;
      this.$refs.rotate.style.animationPlayState = "paused";
    },
    // 音量调节
    changeVolume(value) {
      this.audio.volume = value;
      this.$refs.audio.volume = value / 100;
    },
    // 拖动进度滚动条
    progressChange() {
      this.$refs.audio.currentTime = this.cacheCurrent;
      this.audio.currentTime = this.cacheCurrent;
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    onTimeupdate(res) {
      // 0,0.1,0.2,...1,1.1,...2,...10
      this.audio.currentTime = res.target.currentTime;
    },
    // 获取音频长度
    onLoadedmetadata(res) {
      this.audio.maxTime = parseInt(res.target.duration);
    },
    realFormatSecond(second) {
      this.cacheCurrent = second;
      return realFormatSecond(second);
    },
    //切换ppt
    scrollToPPt(id) {
      this.fullpage.current = id;
      this.move(this.fullpage.current);
    },
    // 监听鼠标监听
    mouseWheelHandle(event) {
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
    // 往下切换
    next() {
      let len = this.docData.length; // 页面的个数
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    // 往上切换
    pre() {
      if (this.fullpage.current - 1 >= 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    // 滚动事件
    move(index) {
      if (index == this.docData.length || index < 0) {
        return;
      }
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    // 执行滚动
    directToMove(index) {
      let height = this.boxHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"]; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -index * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    handleKeyDown(event) {
      if (event.key == "ArrowUp") {
        this.pre();
      }
      if (event.key == "ArrowDown") {
        this.next();
      }
    },
    //全屏
    fullScreen(item, index) {
      if (document.getElementById("enlarged-image") != null) {
        document.body.removeChild(document.getElementById("enlarged-image"));
      }
      this.fullpage.current = item.sort;
      this.currentFullIndex = index;
      // 创建一个新的div元素，并设置样式属性
      window.addEventListener("keydown", this.handleFullScreen, item.id);
      const enlargedDiv = document.createElement("div");
      enlargedDiv.id = "enlarged-image";
      enlargedDiv.style.backgroundImage = `url(${item.preview_img})`;
      const el = document.body.appendChild(enlargedDiv);
      const rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    //全屏上下切换
    handleFullScreen(event) {
      var enlargedDiv = document.getElementById("enlarged-image");
      if (event.key == "ArrowUp") {
        if (this.currentFullIndex > 0) {
          this.currentFullIndex -= 1;
          this.fullpage.current = this.docData[this.currentFullIndex].sort;
          var url = this.docData[this.currentFullIndex].preview_img;
          enlargedDiv.style.backgroundImage = `url(${url})`;
          this.move(this.fullpage.current);
        }
      }
      if (event.key == "ArrowDown") {
        let len = this.docData.length;
        if (this.currentFullIndex + 1 < len) {
          this.currentFullIndex += 1;
          this.fullpage.current = this.docData[this.currentFullIndex].sort;
          var url = this.docData[this.currentFullIndex].preview_img;
          enlargedDiv.style.backgroundImage = `url(${url})`;
          this.move(this.fullpage.current);
        }
      }
    },
    //滚动 txt
    scrollGetTxt(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight + 1 >=
        e.srcElement.scrollHeight
      ) {
        if (!this.isDetailOver) {
          if (!this.loadingTxt) {
            // 请求的数据未加载完成时
            this.previewTxt(this.handleItem.id)
          }
        }
      }
    },
    previewTxt: _.debounce(
      function (id) {
        this.txtPage+=1
        if (this.isDetailOver) return;
        previewTxt({ file_id: id, page: this.txtPage, size: 6000 }).then(
          (res) => {
            if (res.status == "1") {
              if (res.data.info === "") {
                this.isDetailOver = true;
              }
              this.loadingTxt = false;
              this.txtData = this.txtData.concat(res.data.info || []);
            } else {
              this.isDetailOver = true;
            }
        });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    confirmPwd(pwd) {
      if(pwd==''){
        this.$toast('解压密码不能为空', 2);
        return
      }
      this.zipPwd = pwd;
      this.queryZipInfoTask();
    },
    closePwdBox(){
      this.zipPwd=''
      this.showPressPwd=false
    },
    //压缩包解压预览
    queryZipInfoTask() {
      let params = {
        file_id: this.handleItem.id,
        path: this.path,
        password: this.zipPwd,
      };
      this.upLoading = true;
      queryZipInfoTask(params).then((res) => {
        this.upLoading = false;
        if (res.status == "1") {
          this.zipFileList = res.data.list;
          this.showPressPwd = false;
          this.step = 2;
        } else if (res.status == "30001") {
          this.showPressPwd = true;
        }else if(res.status == "30002"){//解压密码错误
          this.$toast(res.msg, 2);
        } else if (res.status == "5000") {
          this.$toast(res.msg, 2);
        } else if (res.status == "82000") {//解压非会员提示
          this.icon = "icon_diamond";
          this.promoteType = 3;
          this.promoteMsg = res.msg
          this.isShowPromote = true;
          this.vip_update = '2';
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //选择压缩包的文件
    chooseZipFile(index) {
      // 点击文件选中
      var exist = this.checkedZipFiles.indexOf(index);
      if (exist == -1) {
        this.checkedZipFiles.push(index);
      } else {
        this.checkedZipFiles.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedZipFiles = [];
      var tmpfiles = this.zipFileList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        if (this.zipFileList[i].is_unzip == "1") {
          this.checkedZipFiles.push(i);
        }
      }
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedZipFiles = [];
    },
    setFolder: _.debounce(
        function (item) {
          this.allCheck = 0;
          this.checkedZipFiles = [];
          this.path.push(item.name)
          this.queryZipInfoTask()
        },
        500,
        {
            leading: true, //指定在延迟开始前调用
            trailing: false, //指定在延迟结束后调用
        }
    ),
    //文件夹点击顶部路径
    setFolderRouter(name) {
      this.allCheck = 0;
      this.checkedZipFiles = [];
      var index = this.path.indexOf(name) + 1;
      this.path.splice(index, this.path.length - index);
      this.queryZipInfoTask();
    },
    //重置到根目录
    resetRouter() {
      this.path = [];
      this.checkedZipFiles = [];
      this.queryZipInfoTask();
    },
    //打开文件目录弹框 解压
    doPress(type) {
      this.dirHandleType = 2;
      this.upZipType = type; //2 全部解压 1 选中解压
      this.getFolderMoveList();
    },
    doFileDir() {
      var folder_id =
        this.moveNav.length > 0 ? this.moveNav[this.moveNav.length - 1].id : 0;
      if (this.dirHandleType == 2) {
        //解压
        this.upconfirmLoading = true;
        this.setPress(folder_id);
      }
    },
    //解压
    setPress(pid) {
      this.folderIds = [];
      this.fileIds = [];
      this.getSelFileIds();
      let dirsList = [];
      for (var i = 0; i < this.folderIds.length; i++) {
        dirsList.push([...this.path, this.folderIds[i]]);
      }
      let filesList = [];
      for (var i = 0; i < this.fileIds.length; i++) {
        filesList.push([...this.path, this.fileIds[i]]);
      }
      let params = {
        file_id: this.handleItem.id,
        files: this.upZipType == 2 ? [] : filesList,
        dirs: this.upZipType == 2 ? [] : dirsList,
        password: this.zipPwd,
        pid: pid, //解压到哪个文件夹
      };
      createUnZipAsyncTask(params).then((res) => {
        this.upconfirmLoading = false;
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.showFileDirDialog = false;
          this.checkedZipFiles = [];
          this.allCheck = 0;
          this.$store.commit("getProgressTaskcallback");
          this.$store.commit("setAttr", {
            name: "showUpDownBox",
            val: 1,
          });
          this.$store.commit("setAttr", {
            name: "navAct",
            val: 2,
          });
          this.step = 1;
        } else if (res.status == 30001) {
          //解压密码不能为空
          this.showPressPwd = true;
        } else if (res.status == "52001") {
          this.vip_update = res.data.vip_update;
          this.promoteType = 3;
          this.promoteMsg =
            res.data.vip_update == "1"
              ? "您的云盘空间已满，请升级会员继续使用"
              : "您的云盘空间已满";
          this.icon='icon_space'
          this.isShowPromote = true;
        }else if (res.status == "82000") {//解压非会员提示
          this.icon = "icon_diamond";
          this.promoteType = 3;
          this.promoteMsg = res.msg
          this.isShowPromote = true;
          this.vip_update = '2';
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    // 获取选中的文件的id
    getSelFileIds() {
      this.checkedZipFiles.map((item) => {
        if (this.zipFileList[item].is_dir == "1") {
          this.folderIds.push(this.zipFileList[item].name);
        } else {
          this.fileIds.push(this.zipFileList[item].name);
        }
      });
    },
    //移动文件夹目录列表  移动弹窗-点击文件夹-进入里层文件夹
    getFolderMoveList: _.debounce(
      function (folder) {
        if (folder) {
          this.moveNav.push(folder);
        } else {
          this.moveNav = [{ name: "全部文件", id: "0" }];
        }
        let params = {
          id: folder ? folder.id : 0,
        };
        folderMoveList(params)
          .then((res) => {
            if (res.status == "1") {
              this.folderList = res.data.list;
              this.showFileDirDialog = true;
            } else {
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 移动弹窗-点击顶部文件夹路径-跳出当层文件夹
    changeNav(item, index) {
      let arr = this.moveNav.slice(0, index + 1);
      this.moveNav = arr;
      folderMoveList({ id: item.id })
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    //文件操作
    handleFile(item) {
      if (item.id == 1) {
        //移动
        this.$emit("handleCommand", "a", this.handleItem);
      } else if (item.id == 2) {
        //重命名
        this.$emit("openInputDialog", 3, this.handleItem);
      } else {
        this.showErrorImg=false;
        this.$emit("openDelTipDialog", 1);
      }
    },
    deleteArr(){
      return new Promise((resolve) => {
        let filteredArray = this.previewImgDataList.filter((item) => {
            return item.expiry_time > Math.floor(new Date().getTime() / 1000);
        });
        this.previewImgDataList = filteredArray
        resolve(filteredArray)
    });
    },
   async getImgUrl(type) {
      let blist = await this.deleteArr()
      const list = blist.find(item => item.file.id == (type == 1 ? this.previewImgData.prev : this.previewImgData.next))
      if(list){
        this.showErrorImg=false;
        this.$parent.previewImgData = list
        this.$parent.handleItem = list.file
        return
      }
      this.showErrorImg=false;
      this.loadingImg=true;
      if (type == 1) {
        //上一项
        this.$emit("previewImg", this.previewImgData.prev);
      } else {
        //下一张
        this.$emit("previewImg", this.previewImgData.next);
      }
    },
    updateFlag(){
      this.isFlag = !this.isFlag;
    },
    imgHandle(id) {
      this.$refs.elementDrag.imgHandle(id)
    },
    //
    async getXlsxData() {
      let ext = this.$utils.formatExt(this.handleItem.extension);
      if (!this.xWorker) {
        this.xWorker = new XlsxWorker({
          src: this.previewUrl,
          ext: ext,
          callback: this.onWorkerCallback,
        });
      } else {
        this.xWorker.reload({
          src: this.previewUrl,
          ext: ext,
        });
      }
    },
    onWorkerCallback(ev) {
      this.loading = false;
      let resJson;
      this.fileLoadMsg = ''
      this.msg = ''
      if (ev.code === 1) {
        if (ev.action === "msg") {
          resJson = JSON.parse(
            new TextDecoder("utf-8").decode(new Uint8Array(ev.data))
          );
        } else {
          resJson = {
            action: "success",
            data: ev.data,
            workData: ev.workData,
          };
        }
        if (resJson.action === "success") {
          if (!this.myECanvas) {
            this.myECanvas = new ExcelCanvas(
              resJson.data,
              "excelCanvasBox",
              resJson.workData
            );
          } else {
            this.myECanvas.reload(
              resJson.data,
              "excelCanvasBox",
              resJson.workData
            );
          }
        } else if (resJson.action === 'fail') {
            let __msg = ''
            if (typeof resJson.data === 'string') {
                __msg = resJson.data
            }
          this.msg = "加载失败~" + __msg;
        }
      } else {
          let __msg = ''
          if (typeof ev.msg === 'string') {
              __msg = ev.msg
          }
        this.msg = "加载失败~" + __msg;
      }
    },
    viewDocx(docx_url) {
      this.fileLoadMsg = ''
      this.previewUrl2 = docx_url
      fetch(docx_url)
          .then((response) => response.arrayBuffer())
          .then((response) => {
            let docView = document.getElementById('docView')
            window.docx.renderAsync(response, docView).then(() => {
              this.loading = false
              let allA = docView.querySelectorAll('a')
              if (allA && allA.length > 0) {
                for (let i = 0; i < allA.length; i++) {
                  allA[i].setAttribute('href', 'javascript:;')
                  allA[i].style.cursor = 'default'
                }
              }
            }).catch((err) => {
              this.loading = false
              this.fileLoadMsg = err.toString()
            })
          })
          .catch((err) => {
            this.loading = false
            this.fileLoadMsg = err.toString()
          })
    },
    getFileData() {
      this.$nextTick(() => {
        if (window.Worker) {
          this.getXlsxData()
        } else {
          this.getFileBySrc()
        }
        // this.getFileBySrc();
      });
    },
    getFileBySrc() {
      clearUrl();
      let ext = this.$utils.formatExt(this.handleItem.extension);
      formatXlsx(this.previewUrl, ext)
        .then((_res) => {
          this.onWorkerCallback({
            action: "format",
            code: 1,
            data: _res.sheets,
            workData: _res.workData,
          });
        })
        .catch((err) => {
          let _msg = err || "";
          if (_msg.toString) {
            _msg = _msg.toString();
          }
          this.onWorkerCallback({
            action: "format",
            code: 0,
            msg: _msg,
          });
        });
    },
    onIframeMsg(e) {
      if (e.isTrusted && e.data.action === "load") {
        this.loading = false;
      }
    },
    goDownload(e) {
      this.$emit("goDownload",e,this.handleItem);
    },
    goShare() {
      this.$emit("goShare", this.handleItem);
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
::v-deep .el-tooltip {
  margin: 10px !important;
}
#docView {
  margin: 0 auto;
  width: max-content;
  height: 100%;
  overflow: auto;
  .docx-wrapper {
    background: #F2F4F7;
    padding: 0;
  }
  .docx-wrapper>section.docx {
    max-width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
    margin-bottom: 0;
  }
  .docx-wrapper>section.docx + .docx-wrapper>section.docx {
    margin-top: 20px;
  }
}
@media screen and (max-width: 720px) {
  .docx-wrapper>section.docx {
    padding: 16pt 20pt !important;
  }
}
.preview-bg {
  background-color: #f2f4f7;
}
.preview-doc-bg {
  @include useTheme{
    background-color: getVar("background-color-f2f4f7");
  }
}
</style>
