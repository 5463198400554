<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="bindPhoneDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body" style="height:136px" >
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('web4/icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content" style="margin-top:32px">
        <div
          class="tit"
          style="margin-bottom: 16px; text-align: center; font-size: 16px"
        >
          {{tit}}
        </div>
      </div>

      <div class="d-footer" style="margin-top: 24px">
        <fm-button @click="confirm()" type="info" plain size="small">确 定</fm-button>
        <fm-button @click="handleClose()" plain type="info" size="small"
          >取 消</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tit:'确定要取消该任务？'
    };
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },

    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style></style>
