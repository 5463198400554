import TheSpeed from "./speedometer";
import _ from "lodash";
import store from "../../store/index";

export default class OneUpload {
  constructor(opt, callback, callbackType) {
    this.callbackType = callbackType
    this.currentSpeed = 0
    this.loaded = 0
    this._loaded = 0
    this.theSpeed = new TheSpeed()
    this.part_number = 1
    this.sign_url = ''
    this.msgToast = ''
    this.isFileComplete = false
    this.xmlHttp = null
    for (let optKey in opt) {
      this[optKey] = opt[optKey]
    }

    if (!this.sizeM1) {
      this.sizeM1 = 2 * 1024 * 1024
    }
    this.part_count = Math.ceil(this.size / this.sizeM1)

    if (callback) {
      this.upCallback = callback
    }
  }

  getExtension() {
    let _name = this.name || ''
    return _name.substring(_name.lastIndexOf(".")+1)
  }

  sizeUploaded() {
    if (this.size < this.loaded) return this.size
    return this.loaded
  }

  timeRemaining() {
    if (this.size < this.loaded) return 0
    return this.currentSpeed==0 ? 0 : Math.floor((this.size - this.loaded) / this.currentSpeed)
  }

  progress() {
    return this.size==0 ? 0 : Number((this.loaded / this.size).toFixed(4))
  }

  cancelTask() {
    if (this.task_id) {
      let _form = new FormData()
      _form.append('task_id', this.task_id)
      fetch(this.cancel_url, {
        method: 'post',
        body: _form
      })
        .then((response) => response.json())
        .then(() => {}).catch(() => {})
    }
  }

  getSignUrl() {
    let _form = new FormData()
    _form.append('task_id', this.task_id)
    _form.append('part_number', this.part_number)
    fetch(this.upload_part_url, {
      method: 'post',
      body: _form
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == 1) {
          this.sign_url = res.data.sign_url
          this.uploadRequest()
        } else {
          this.fail(res.msg)
        }
      }).catch(() => {
        this.fail()
      })
  }

  completeUpload() {
    let _form = new FormData()
    _form.append('task_id', this.task_id)
    fetch(this.merge_part_url, {
      method: 'post',
      body: _form
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == 1) {
          this.success(res)
        } else {
          this.fail(res.msg)
        }
      }).catch(() => {
      this.fail()
    })
  }

  uploadRequest() {
    let _xmlHttp
    if (window.ActiveXObject) {
      _xmlHttp = new ActiveXObject("Microsoft.XMLHTTP")
    } else if (window.XMLHttpRequest) {
      _xmlHttp = new XMLHttpRequest()
    }
    this.xmlHttp = _xmlHttp
    if (_xmlHttp != null) {
      _xmlHttp.open("put", this.sign_url, true);
      _xmlHttp.responseType = 'document'; //关键
      _xmlHttp.setRequestHeader("content-type", "text/plain")
      let _blob
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      if (this.part_number === this.part_count) {
        _blob = blobSlice.call(this.file, (this.part_number - 1) * this.sizeM1, this.size)
      } else {
        _blob = blobSlice.call(this.file, (this.part_number - 1) * this.sizeM1, this.part_number * this.sizeM1)
      }
      let _start = 0
      _xmlHttp.upload.onprogress = (ev) => {
        let transfer = ev.loaded - _start
        this.theSpeed.spLength += transfer
        this._loaded += transfer
        _start = ev.loaded
      }
      _xmlHttp.onreadystatechange = () => {
        if (_xmlHttp.readyState == 4) {
          if (_xmlHttp.status == 200) {
            this.part_number++
            if (this.part_number > this.part_count) {
              this.completeUpload()
            } else if (this.fileStatus == 1) {
              this.getSignUrl()
            }
          } else {
            if (_xmlHttp.status !== 0 || this.fileStatus !== 2) {
              this.fail()
            }
          }
        }
      }
      _xmlHttp.onerror = (err) => {
        console.log('http error:', err)
        this.fail(err.toString())
      }
      _xmlHttp.send(_blob);
    }
  }

  resume() {
    this.fileStatus = 1
    this._loaded = this.loaded
    this.theSpeed.initSpeed((_speed) => {
      this.currentSpeed = _speed
      this.loaded = this._loaded
      if (this.upCallback && this.callbackType !== 'async') {
        this._callback('progress', { progress: this.progress() })
      }
    })
    this.getSignUrl()
  }

  pause() {
    if (this.xmlHttp) {
      this.xmlHttp.abort()
      this.xmlHttp = null
    }
    this.theSpeed.clearSpeed()
    this.fileStatus = 2
  }

  cancel() {
    if (this.xmlHttp) {
      this.xmlHttp.abort()
      this.xmlHttp = null
    }
    this.theSpeed.clearSpeed()
    if (this.fileStatus === 1 || this.fileStatus === 2) {
      this.cancelTask()
    }
  }

  retry() {
    this.resume()
  }

  fail(msg) {
    this.theSpeed.clearSpeed()
    this.fileStatus = 3
    this.msgToast = msg || ''
    this._callback('fail', { msg })
  }
  success(res) {
    this.theSpeed.clearSpeed()
    this.fileStatus = 4
    this.isFileComplete = true
    if (this.$store) {
        getfiles()
    }
    this._callback('success', res)
  }

  _callback(act, args) {
    if (this.upCallback) {
      let msg = args && args.msg || ''
      this.upCallback({ action: act, res: args, msg, id: this.id })
    }
  }
}
const getfiles = _.throttle(
  () => {
    store.commit("getfiles");
  },
  3000,
  {
    leading: true,
    trailing: false,
  }
);

