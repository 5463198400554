<template>
  <el-dialog
    width="480px"
    :append-to-body="true"
    :close-on-click-modal="false"
    top="0vh"
    custom-class="suceessDialog"
    :before-close="successRefresh"
    :visible.sync="isShowModel"
  >
    <div
      id="lottie"
      :class="payToast.vlist.length > 4 ? 'animation' : 'animation2'"
    ></div>
    <div
      class="payContent"
      :style="
        $store.state.theme == 'light'
          ? 'backgroundImage:url(' + $utils.getPng('web4/icon-vip-light') + ')'
          : 'backgroundImage:url(' + $utils.getPng('web4/icon-vip-dark') + ')'
      "
    >
      <div class="d-header">
        <fm-button
          @click="successRefresh"
          style="z-index:1000"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="s-content">
        <div class="s-head">
          <img class="s-logo" :src="payToast.logo" />
          <img
            class="s-frame"
            v-if="payToast.frame_url"
            :src="payToast.frame_url"
          />
        </div>

        <div class="s-title">
          {{ payToast.text }}
          <img class="s-flower" :src="$utils.getPng('web4/icon-flower')" />
        </div>
        <div class="s-title1">{{ payToast.desc }}</div>
        <div class="s-list">
          <div
            class="s-one"
            v-for="(item, index) in payToast.vlist"
            :key="index"
          >
            <img :src="`${item.icon}-${$store.state.theme}.png`" />
            <span v-html="item.vhtml"></span>
          </div>
        </div>
        <fm-button @click="successRefresh" class="confrim pointer" round size="medium"
          >完成</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowModel: Boolean,
    payToast: Object,
  },

  data() {
    return {};
  },
  methods: {
    successRefresh() {
      this.$emit("successRefresh");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.suceessDialog {
  .el-dialog {
    width: 480px;
    height: 454px;
  }
  .el-dialog__header {
    padding: 0 !important;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #f15f00 !important;
  }
  .payContent {
    width: 480px;
    height: 454px;
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
    .s-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 47px;
      .s-head {
        position: relative;
        .s-logo {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          flex-shrink: 0;
          object-fit: cover;
        }
        .s-frame {
          height: 100px;
          width: 100px;
          position: absolute;
          top: -15px;
          left: -15px;
        }
      }
      .s-title {
        display: flex;
        align-items: center;
        height: 26px;
        font-size: 18px;
        font-weight: 500;
        @include useTheme {
          color: getVar("text-color2-2c2c2c");
        }
        line-height: 26px;
        margin-top: 27px;
        .s-flower {
          width: 30px;
          height: 30px;
        }
      }
      .s-title1 {
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
        line-height: 22px;
        margin-top: 4px;
      }
      .s-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 26px;
        .s-one {
          width: 186px;
          height: 48px;
          @include useTheme {
            background: getVar("text-bg-ffffff");
          }
          border-radius: 10px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            margin: 0 12px;
          }
          span {
            font-size: 12px;
          }
        }
        .s-one:nth-child(2n) {
          margin-left: 12px;
        }
      }

      .confrim {
        width: 202px;
        height: 48px;
        background: #f67942;
        border-radius: 24px;
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }
  .animation {
    height: 514px;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  .animation2 {
    height: 454px;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  .confrim{
    z-index: 1000;
  }
}
</style>
