<template>
    <el-dialog
        :visible.sync="isShow"
        width="480px"
        :before-close="handleClose"
        custom-class="PromoteDialog" :append-to-body="true" :close-on-click-modal="false">
        <div class="dialog-body">
            <div class="d-header">
                <div class="left"></div>
                <fm-button type="info" size="mini" text icon="icon-model-close" @click="handleClose"></fm-button>
            </div>
            <div class="d-content">
                  <img class="icon" :src="$utils.getPng('web4/icon_success')">
                  <span>{{successBoxMsg}}</span>
            </div>
            <div class="d-footer">
                <fm-button type="primary" round @click="goCloud">去查看</fm-button>
            </div>
        </div>
        </el-dialog>
  </template>
  
  <script>
  export default {
      props:{
          isShow:Boolean,
          successBoxMsg:String
      },
      methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        goCloud(){
            this.$emit('handleClose')
            window.open('/drive')
        }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .PromoteDialog{
    .d-content{
        .icon{
            width: 88px;
            height: 88px;
            margin-bottom: 16px;
        }
    }
    .d-footer{
        .fm-button{
            margin-left: 0;
        }
   } 
  }
  
  </style>