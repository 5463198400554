<template>
  <div class="askdetail app-right-box">
    <div class="askBox">
      <div class="top">
        <fm-button
          @click="goBack"
          type="info"
          size="medium"
          round
          icon="icon-return"
          >返回</fm-button
        >
        <div class="tit">
          {{ title }}
        </div>
        <div class="right" style="width: 144px"></div>
      </div>
      <div
        style="font-size: 16px; font-weight: 400"
        class="answer"
        v-html="answer"
      ></div>
      <div class="center">
        <div class="bottom">
          <div
            class="btn pointer"
            :class="{ mr: after.id }"
            @click="goAskdetail(before.id)"
            v-show="before.id"
          >
            <fm-button icon="icon-prev" type="info" text></fm-button>
            <div class="name">
              {{ before.title }}
            </div>
          </div>
          <div
            class="btn btn1 pointer"
            @click="goAskdetail(after.id)"
            v-show="after.id"
          >
            <div class="name">
              {{ after.title }}
            </div>
            <fm-button icon="icon-next" type="info" text></fm-button>
          </div>
        </div>
        <fm-button class="noFind" text round @click="routerTo({ path: '/submitQuestions' })">没找到答案，点此提交问题 ></fm-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionsDetail } from "@/utils/apiList/help";
export default {
  data() {
    return {
      id: "",
      title: "",
      answer: "",
      before: {},
      after: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getQuestionsDetail();
  },
  methods: {
    routerTo(args) {
      this.$router.push(args);
    },
    getQuestionsDetail() {
      getQuestionsDetail({ id: this.id }).then((res) => {
        if (res.status == "1") {
          this.title = res.data.title;
          this.answer = res.data.answer;
          this.before = res.data.before;
          this.after = res.data.after;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    goBack() {
      this.$router.push({
        path: "/help",
      });
    },
    goAskdetail(id) {
      this.id = id;
      this.getQuestionsDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
